<template>
    <div class="widget" v-if="employee">
        <div class="employee">
            <img height="300" width="300" :src="employee.image.src" :srcset="employee.image.srcset" :alt="`${employee.firstname} ${employee.lastname}`" class="employee__image">

            <div class="employee__box">
                <h4 class="employee__title">{{ $t('employee.title') }}</h4>

                <p>
                    <strong>{{ employee.firstname }} {{ employee.lastname }}</strong><br>
                    {{ employee.position }}
                </p>

                <p>
                    {{ employee.phone }}<br>
                    <a :href="`mailto:${employee.email}`">{{ employee.email }}</a>
                </p>
            </div>

        </div>
    </div>
</template>

<script>
import axios from '@/config/mary';

export default {
    name: 'EmployeeWidget',

    data: () => ({
        employee: null,
    }),

    mounted() {
        this.initializeEmployee();
    },

    watch: {
        '$i18n.locale': function localeWatcher() {
            this.initializeEmployee();
        },
    },

    methods: {
        initializeEmployee() {
            axios({
                url: `/${this.$i18n.locale}/api/team`,
                method: 'GET',
            }).then((response) => {
                const { data } = response;

                this.employee = data[Math.floor(Math.random() * data.length)];
            });
        },
    },
};
</script>
