<template>
    <div class="archive">
        <div class="card">
            <div class="card__header">
                <h1 class="card__title">{{ $t('active.title') }}</h1>

                <ShipmentFilter @filter="filter = $event" :show-reference="false" :show-state="true" :initial-state="$route.query.state"></ShipmentFilter>
            </div>

            <div v-if="!initialized">{{ $t('global.loading') }}</div>

            <div v-if="initialized && shipments.length > 0" class="card__table">
                <table class="data-table" :class="{ 'data-table--updating': updating }">
                    <thead>
                        <tr>
                            <th>{{ $t('shipment.number') }}</th>
                            <th><TableSortingField field="empfangsdatum" :sorting="sorting" :updating="updating" @sortingChanged="sorting = $event">{{ $t('shipment.date') }}</TableSortingField></th>
                            <th>{{ $t('shipment.from') }}</th>
                            <th>{{ $t('shipment.to') }}</th>
                            <th>{{ $t('shipment.cargo') }}</th>
                            <th><TableSortingField field="status" :sorting="sorting" :updating="updating" @sortingChanged="sorting = $event">{{ $t('shipment.state') }}</TableSortingField></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="shipment in shipments">
                            <td class="data-table--bold data-table--no-break">
                                <div class="data-table__mobile-label">{{ $t('shipment.number') }}</div>
                                {{ shipment.externalNumber }}
                            </td>
                            <td class="data-table--no-break">
                                <div class="data-table__mobile-label">{{ $t('shipment.date') }}</div>
                                {{ dateFormat(shipment.start) }}
                            </td>
                            <td>
                                <div class="data-table__mobile-label">{{ $t('shipment.from') }}</div>
                                <div class="data-table__count-container">
                                    <div class="data-table__count" v-if="shipment.from.length > 1">+{{ shipment.from.length - 1 }}</div>

                                    <Label :text="shipment.from[0].name" />

                                    <!-- States -->
                                    <small v-if="getStateName(shipment) === 'cancelled'" class="data-table__state text-red">{{ $t('shipment.cancelled') }}</small>

                                    <template v-if="shipment.isTour">
                                        <small v-if="shipment.from[0].datetime" class="data-table__state text-green">
                                            {{ $t('shipment.pickedUp', { date: dateFormat(shipment.from[0].datetime), time: dateTimeFormat(shipment.from[0].datetime) }) }}
                                        </small>

                                        <small v-if="!shipment.from[0].datetime && shipment.from[0].timeStart && shipment.from[0].timeEnd" class="data-table__state text-green">
                                            {{ $t('shipment.plannedPickup', { from: dateTimeFormat(shipment.from[0].timeStart), to: dateTimeFormat(shipment.from[0].timeEnd) }) }}
                                        </small>
                                    </template>
                                    <template v-else>
                                        <small v-if="shipment.pickedUp" class="data-table__state text-green">
                                            {{ $t('shipment.pickedUp', { date: dateFormat(shipment.pickedUp), time: dateTimeFormat(shipment.pickedUp) }) }}
                                        </small>

                                        <small v-if="!shipment.pickedUp && shipment.pickupFrom && shipment.pickupTo" class="data-table__state text-green">
                                            {{ $t('shipment.plannedPickup', { from: dateTimeFormat(shipment.pickupFrom), to: dateTimeFormat(shipment.pickupTo) }) }}
                                        </small>
                                    </template>
                                </div>
                            </td>
                            <td>
                                <div class="data-table__mobile-label">{{ $t('shipment.to') }}</div>
                                <div class="data-table__count-container">
                                    <div class="data-table__count" v-if="shipment.to.length > 1">+{{ shipment.to.length - 1 }}</div>

                                    <Label :text="shipment.to[0].name" />

                                    <template v-if="shipment.isTour">
                                        <small v-if="shipment.to[0].datetime" class="data-table__state text-green">
                                            {{ $t('shipment.delivered', { date: dateFormat(shipment.to[0].datetime), time: dateTimeFormat(shipment.to[0].datetime) }) }}
                                        </small>

                                        <small v-if="!shipment.to[0].datetime && shipment.to[0].timeStart && shipment.to[0].timeEnd" class="data-table__state text-green">
                                            {{ $t('shipment.plannedDelivery', { from: dateTimeFormat(shipment.to[0].timeStart), to: dateTimeFormat(shipment.to[0].timeEnd) }) }}
                                        </small>
                                    </template>
                                    <template v-else>
                                        <small v-if="shipment.delivered" class="data-table__state text-green">
                                            {{ $t('shipment.delivered', { date: dateFormat(shipment.delivered), time: dateTimeFormat(shipment.delivered) }) }}
                                        </small>

                                        <small v-if="!shipment.delivered && shipment.deliveryFrom && shipment.deliveryTo" class="data-table__state text-green">
                                            {{ $t('shipment.plannedDelivery', { from: dateTimeFormat(shipment.deliveryFrom), to: dateTimeFormat(shipment.deliveryTo) }) }}
                                        </small>
                                    </template>
                                </div>
                            </td>
                            <td class="data-table--no-break">
                                <div class="data-table__mobile-label">{{ $t('shipment.cargo') }}</div>
                                {{ $t('shipment.colis') }}: {{ shipment.numberOfColis }}<br>
                                <small>{{ $t('shipment.totalWeight', { weight: shipment.totalWeight }) }}</small>
                            </td>
                            <td class="data-table--no-break">
                                <div class="data-table__mobile-label">{{ $t('shipment.state') }}</div>
                                {{ $t(`global.states.${getStateName(shipment)}`) }}
                            </td>
                            <td class="data-table--right">
                                <button class="data-table__button" @click="open(shipment.key)"></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div ref="popup" role="modal" id="track" class="popup popup--wide popup--active popup--detail" v-if="detail">
                <div class="popup__container" ref="container">
                    <button class="popup__close" @click="close"></button>

                    <div class="grid-container">
                        <div class="grid-x grid-margin-x">
                            <div class="cell small-24 large-4">
                                <h4 class="popup__section-title">{{ $t('shipment.number') }}</h4>

                                <span class="popup__large-number">{{ detail.externalNumber }}</span>
                            </div>
                            <div class="cell small-24 large-5">
                                <h4 class="popup__section-title">{{ $t('shipment.information') }}</h4>

                                <PopupShipmentForm v-if="isEditable(detail)" :shipment="detail"></PopupShipmentForm>
                                <div v-else class="popup__text">
                                    <strong>{{ $t('shipment.reference') }}</strong>: {{ detail.reference }}<br>
                                    <strong>{{ $t('shipment.numberOfColis') }}</strong>: {{ detail.numberOfColis }}
                                </div>

                                <PodDownloadButton :label="$t('shipment.downloadFreightDocuments')" :shipment="detail" />
                            </div>
                            <div class="cell small-24 large-5">
                                <h4 class="popup__section-title">{{ $t('shipment.sender') }}</h4>

                                <PopupAddressList :addresses="detail.from" :shipment="detail"></PopupAddressList>
                            </div>
                            <div class="cell small-24 large-5">
                                <h4 class="popup__section-title">{{ $t('shipment.receiver') }}</h4>

                                <PopupAddressList :addresses="detail.to" :shipment="detail"></PopupAddressList>
                            </div>
                            <div class="cell small-24 large-5">
                                <h4 class="popup__section-title">{{ $t('shipment.state') }}</h4>

                                {{ $t(`global.states.${getStateName(detail)}`) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card__footer" v-if="initialized && shipments.length > 0">
                <Pagination
                    :page="page"
                    :per-page="perPage"
                    :total="total"
                    :initialized="initialized"
                    :updating="updating"

                    @pageChanged="page = $event"
                    @perPageChanged="perPage = $event"
                ></Pagination>
            </div>

            <div v-if="initialized && shipments.length <= 0">
                {{ $t('active.empty') }}
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import axios from '@/config/axios';
import { createNamespacedHelpers } from 'vuex';

import Pagination from '@/components/Pagination.vue';
import ShipmentFilter from '@/components/ShipmentFilter.vue';
import TableSortingField from '@/components/TableSortingField.vue';
import PopupAddressList from '@/components/PopupAddressList.vue';
import PopupShipmentForm from '@/components/PopupShipmentForm.vue';
import Label from '@/components/MultiLineLabel.vue';
import PodDownloadButton from '@/components/PodDownloadButton.vue';

import { dateFormat, dateTimeFormat } from '@/services/date-formatter';
import { getStateName, transformFull, isEditable } from '@/services/shipment-transformer';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('active');

export default {
    name: 'Active',

    components: {
        PodDownloadButton,
        Label,
        PopupShipmentForm,
        PopupAddressList,
        TableSortingField,
        ShipmentFilter,
        Pagination,
    },

    data: () => ({
        perPage: 20,
        page: 0,

        filter: {},
        sorting: {
            field: 'empfangsdatum',
            direction: true,
        },

        detail: null,
        detailLoading: false,
    }),

    metaInfo() {
        return {
            title: this.$t('active.title'),
        };
    },

    computed: {
        ...mapGetters([
            'total',
            'initialized',
            'updating',
        ]),

        ...mapState([
            'shipments',
        ]),
    },

    methods: {
        async fetch() {
            await this.fetchShipments({
                count: this.perPage,
                offset: this.page * this.perPage,
                filters: this.filter,
                sorting: {
                    field: this.sorting.field,
                    direction: this.sorting.direction ? 'desc' : 'asc',
                },
            });
        },

        ...mapActions([
            'fetchShipments',
        ]),

        open(id) {
            this.detailLoading = true;

            $('html').addClass('html--fixed');
            $('body').addClass('body--fixed');

            axios({
                url: `/api/v2/shipments/${id}`,
                method: 'GET',
            })
                .then((response) => {
                    const { data } = response;

                    const shipment = transformFull(data);

                    this.detailLoading = false;
                    this.detail = shipment;
                });
        },

        close() {
            this.detailLoading = false;
            this.detail = null;

            $('html').removeClass('html--fixed');
            $('body').removeClass('body--fixed');
        },

        dateFormat,
        dateTimeFormat,
        getStateName,
        isEditable,
    },

    watch: {
        perPage() {
            this.page = 0;
            this.fetch();
        },

        filter: {
            deep: true,
            handler() {
                this.page = 0;
                this.fetch();
            },
        },

        sorting: {
            deep: true,
            handler() {
                this.page = 0;
                this.fetch();
            },
        },

        page() {
            this.fetch();
        },
    },

    mounted() {
        this.$nextTick(() => {
            $(document).on('keyup', (event) => {
                if (event.keyCode === 27) {
                    this.close();
                }
            });
        });
    },
};
</script>
