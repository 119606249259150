import qs from 'qs';
import axios from '@/config/axios';
import { transform } from '@/services/address-transformer';

export default {
    namespaced: true,
    state: {
        // Addresses
        initialized: false,
        updating: false,
        total: 0,
        addresses: [],
    },

    getters: {
        total: (state) => state.total,
        initialized: (state) => state.initialized,
        updating: (state) => state.updating,
    },

    actions: {
        fetchAddresses: async ({ state }, {
            count,
            offset,
            sorting,
            filter,
        }) => new Promise((resolve, reject) => {
            state.updating = true;

            axios({
                url: '/api/v2/addresses',
                method: 'GET',
                params: {
                    count,
                    offset,
                    sorting,
                    filter,
                },
                paramsSerializer: (params) => qs.stringify(params),
            })
                .then((response) => {
                    const { pagination, data } = response.data;
                    const addresses = [];

                    data.forEach((dataPoint) => {
                        const address = transform(dataPoint);

                        addresses.push(address);
                    });

                    state.total = parseInt(pagination.total, 10);
                    state.initialized = true;
                    state.updating = false;
                    state.addresses = addresses;

                    resolve(addresses);
                })
                .catch((error) => {
                    reject(error);
                });
        }),

        fetchContactAddress: async ({ state }, id) => new Promise((resolve, reject) => {
            axios({
                url: `/api/v2/address/${id}`,
                method: 'GET',
            })
                .then((response) => {
                    state.updating = false;
                    const { data } = response;
                    const address = transform(data);

                    resolve(address);
                })
                .catch((error) => {
                    reject(error);
                });
        }),
    },
};
