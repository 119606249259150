<template>
    <div class="profile">
        <div class="card">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-24 large-24 xlarge-24 xxlarge-16 xxlarge-offset-4">
                        <div class="card__header">
                            <h1 class="card__title">{{ $t('profile.title') }}</h1>
                        </div>

                        <div class="card__body">
                            <div class="grid-container">
                                <div class="grid-x grid-margin-x">
                                    <div class="cell small-24 medium-12">
                                        <ProfileForm :profile="user"></ProfileForm>
                                    </div>
                                    <div class="cell small-24 medium-12">
                                        <router-link :to="{ name: 'PasswordChange' }" class="button button--white">{{ $t('profile.changePassword') }}</router-link>

                                        <InfoBoxAddress :title="$t('profile.sender')" :address="user.defaultSenderAddress"></InfoBoxAddress>
                                        <InfoBoxAddress :title="$t('profile.receiver')" :address="user.defaultReceiverAddress"></InfoBoxAddress>
                                        <InfoBoxAddress :title="$t('profile.invoice')" :address="user.defaultInvoiceAddress"></InfoBoxAddress>

                                        <!-- Change on Request box -->
                                        <div class="info-box">
                                            <h3 class="info-box__title">{{ $t('profile.changeOnRequest.title') }}</h3>

                                            <ul class="info-box__list">
                                                <li class="info-box__list-item">
                                                    <div class="info-box__list-key">{{ $t('profile.changeOnRequest.deliveryType.label') }}</div>
                                                    <div class="info-box__list-value">{{ $t(`profile.changeOnRequest.deliveryType.${user.invoiceDispatchType}`) }}</div>
                                                </li>
                                                <li class="info-box__list-item">
                                                    <div class="info-box__list-key">{{ $t('profile.changeOnRequest.email') }}</div>
                                                    <div class="info-box__list-value">{{ user.invoiceEmail }}</div>
                                                </li>
                                                <li class="info-box__list-item">
                                                    <div class="info-box__list-key">{{ $t('profile.changeOnRequest.invoiceType.label') }}</div>
                                                    <div class="info-box__list-value">{{ $t(`profile.changeOnRequest.invoiceType.${user.invoiceType}`) }}</div>
                                                </li>
                                            </ul>

                                            <a :href="$t('links.changeRequestMailTo')" class="info-box__action">{{ $t('global.changeRequest') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProfileForm from '@/components/ProfileForm.vue';
import { mapGetters } from 'vuex';
import InfoBoxAddress from '@/components/InfoBoxAddress.vue';

export default {
    name: 'Profile',

    components: {
        InfoBoxAddress,
        ProfileForm,
    },

    metaInfo() {
        return {
            title: this.$t('profile.title'),
        };
    },

    computed: {
        ...mapGetters('security', [
            'user',
        ]),
    },
};
</script>
