<template>
    <div class="track">

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="cell auto">
                            <ValidationProvider v-slot="v" rules="required" tag="div" >
                                <div class="form-field" :class="v.classes">
                                    <input v-model="trackingId">

                                    <span class="form-field__label">{{ $t('trackShipment.placeholder') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>

                        </div>
                        <div class="cell small-24 xlarge-6">
                            <button class="button" type="submit" :disabled="loading">{{ $t('trackShipment.button') }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>

        <div
            v-if="error"
            class="tracking-form__error"
        >
            <div class="grid-x grid-margin-x grid-margin-y">
                <div class="cell small-24">
                    <p>{{ $t(error) }}</p>
                </div>
            </div>
        </div>

        <div v-if="result" class="tracking-form__result tracking-result">
            <ul v-if="list" class="tracking-stepper">
                <li v-for="(item, key) in displayStates" :key="key" class="tracking-stepper__item" :data-type="item">
                    <div class="tracking-stepper__link">
                        <div :class="{ 'tracking-stepper__state': true, 'tracking-stepper__state--active': !!list[item] }" />
                        <div class="tracking-stepper__label-container">
                            <span class="tracking-stepper__label">{{ $t('trackShipment.states.' + item) }}</span>
                        </div>
                    </div>
                </li>
            </ul>

            <div v-if="result.cancelled" class="tracking-result__details">
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-24">
                        <div class="tracking-result__title">
                            {{ $t('trackShipment.numberTitle') }}: {{ result.fbNumber }}
                        </div>
                    </div>
                </div>
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-24">
                        <div class="tracking-result__box">
                            <h6 class="tracking-result__box-title">
                                {{ $t('trackShipment.cancelled') }}
                            </h6>
                            <p>
                                {{ result.visierName }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="tracking-result__details">
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-24">
                        <div class="tracking-result__title">
                            {{ $t('trackShipment.numberTitle') }}: {{ result.fbNumber }}
                        </div>
                    </div>
                </div>
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-24 medium-12">
                        <div class="tracking-result__box">
                            <h6 class="tracking-result__box-title">
                                {{ $t('trackShipment.pickup') }}
                            </h6>
                            <p>
                                {{ result.from }}
                            </p>
                            <div v-if="list.pickedUp">
                                <p
                                    v-if="pickup"
                                    class="tracking-result__success"
                                >
                                    {{ $t('trackShipment.pickedUp', {
                                        date: pickup.format('DD.MM.YYYY'),
                                        time: pickup.format('HH:mm') })
                                    }}
                                </p>
                            </div>
                            <div v-else>
                                <p v-if="start">
                                    {{ $t('trackShipment.expectedPickUp', {
                                        date: start.format('DD.MM.YYYY'),
                                        time: start.format('HH:mm')
                                    }) }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="cell small-24 medium-12">
                        <div class="tracking-result__box">
                            <h6 class="tracking-result__box-title">
                                {{ $t('trackShipment.delivery') }}
                            </h6>

                            <p>
                                {{ result.to }}
                            </p>
                            <div v-if="list.delivered">
                                <p
                                    v-if="delivery"
                                    class="tracking-result__success"
                                >
                                    {{ $t('trackShipment.delivered', {
                                        date: delivery.format('DD.MM.YYYY'),
                                        time: delivery.format('HH:mm')
                                    }) }}
                                    <span v-if="result.visierName">{{ $t('trackShipment.to', { name: result.visierName }) }}</span>
                                </p>
                            </div>
                            <div v-else>
                                <p v-if="end">
                                    {{ $t('trackShipment.expectedDelivery', {
                                        date: end.format('DD.MM.YYYY'),
                                        time: end.format('HH:mm')
                                    }) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as moment from 'moment';
import axios from '@/config/axios';
import transform from '@/services/tracking-transformer';

// Mathilda branch
// features/v2-tracking
export default {
    name: 'TrackShipment',

    props: {
        reduced: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        loading: false,
        trackingId: '',

        error: null,
        result: null,
        list: null,

        pickup: null,
        delivery: null,
        start: null,
        end: null,
    }),

    computed: {
        displayStates() {
            if (!this.reduced) {
                return ['created', 'pickedUp', 'inDelivery', 'delivered'];
            }

            return ['pickedUp', 'inDelivery', 'delivered'];
        },
    },

    methods: {
        submit() {
            this.result = null;
            this.error = false;
            this.loading = true;

            axios({
                url: `/api/v2/track/${this.trackingId}`,
                method: 'GET',
            })
                .then((response) => {
                    const { data } = response;

                    const result = transform(data);
                    this.loading = false;

                    this.result = result;
                    this.list = result.list;

                    this.pickup = this.result.pickup ? moment.default(this.result.pickup) : null;
                    this.delivery = this.result.delivery ? moment.default(this.result.delivery) : null;
                    this.start = this.result.start ? moment.default(this.result.start) : null;
                    this.end = this.result.end ? moment.default(this.result.end) : null;
                })
                .catch(() => {
                    this.loading = false;
                    this.error = 'trackShipment.notFound';
                });
        },
    },
};
</script>
