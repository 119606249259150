<template>
    <div class="profile-form">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24">
                        <fieldset class="form-part">
                            <legend class="form-part__title">
                                {{ $t('profileForm.sections.user') }}
                            </legend>

                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="profile.username">

                                    <span class="form-field__label">{{ $t('profileForm.form.username') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider v-slot="v" rules="required|email" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="profile.email">

                                    <span class="form-field__label">{{ $t('profileForm.form.email') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </fieldset>

                        <fieldset class="form-part">
                            <legend class="form-part__title">
                                {{ $t('profileForm.sections.address') }}
                            </legend>

                            <!-- Salutation -->
                            <ValidationProvider v-slot="v" rules="" tag="div">
                                <ul class="radio-group" :class="v.classes">
                                    <li class="radio-group__item">
                                        <input id="address[salutation][male]" type="radio" v-model="profile.gender" value="male">
                                        <label for="address[salutation][male]">{{ $t('form.address.salutation.male') }}</label>
                                    </li>
                                    <li class="radio-group__item">
                                        <input id="address[salutation][female]" type="radio" v-model="profile.gender" value="female">
                                        <label for="address[salutation][female]">{{ $t('form.address.salutation.female') }}</label>
                                    </li>
                                </ul>
                            </ValidationProvider>

                            <div v-if="profile.gender !== 'company'">
                                <div class="grid-container">
                                    <div class="grid-x grid-margin-x">
                                        <div class="cell small-24 medium-12">
                                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                                <div class="form-field" :class="v.classes">
                                                    <input v-model="profile.firstname">

                                                    <span class="form-field__label">{{ $t('address.firstname') }}*</span>
                                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="cell small-24 medium-12">
                                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                                <div class="form-field" :class="v.classes">
                                                    <input v-model="profile.lastname">

                                                    <span class="form-field__label">{{ $t('address.lastname') }}*</span>
                                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="grid-container">
                                    <div class="grid-x grid-margin-x">
                                        <div class="cell small-24">
                                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                                <div class="form-field" :class="v.classes">
                                                    <input v-model="profile.company">

                                                    <span class="form-field__label">{{ $t('address.company') }}*</span>
                                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>

                                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                                <div class="form-field" :class="v.classes">
                                                    <input v-model="profile.contact">

                                                    <span class="form-field__label">{{ $t('address.contact') }}*</span>
                                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <AutosizeInput v-model="profile.street" class="textarea--autosize"></AutosizeInput>

                                    <span class="form-field__label">{{ $t('address.street') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>

                            <div class="grid-container">
                                <div class="grid-x grid-margin-x">
                                    <div class="cell small-8">
                                        <!-- Zip -->
                                        <ValidationProvider v-slot="v" rules="required" tag="div">
                                            <div class="form-field" :class="v.classes">
                                                <input v-model="profile.zip">

                                                <span class="form-field__label">{{ $t('address.zip') }}*</span>
                                                <span class="form-field__error">{{ v.errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="cell small-16">
                                        <!-- Location -->
                                        <ValidationProvider v-slot="v" rules="required" tag="div">
                                            <div class="form-field" :class="v.classes">
                                                <input v-model="profile.location">

                                                <span class="form-field__label">{{ $t('address.locationString') }}*</span>
                                                <span class="form-field__error">{{ v.errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="profile.phone">

                                    <span class="form-field__label">{{ $t('address.phone') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider v-slot="v" rules="required" slim>
                                <div class="form-field" :class="v.classes">
                                    <select class="select" v-model="profile.invoiceLanguage" required>
                                        <option value="de">{{ $t('address.language.de') }}</option>
                                        <option value="fr">{{ $t('address.language.fr') }}</option>
                                        <option value="it">{{ $t('address.language.it') }}</option>
                                        <option value="en">{{ $t('address.language.en') }}</option>
                                    </select>

                                    <span class="form-field__label">{{ $t('address.languageLabel') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>

                            <div class="form-field">
                                <ul class="checkbox-group checkbox-group--no-margin">
                                    <li class="checkbox-group__item">
                                        <div class="checkbox-group__input">
                                            <ValidationProvider v-slot="v" :rules="{ required: { allowFalse: true } }">
                                                <input id="profile[newsletter]" v-model="profile.newsletter" type="checkbox" :class="v.classes">
                                                <label for="profile[newsletter]" v-html="$t('profileForm.form.newsletter')"></label>
                                            </ValidationProvider>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <button class="button" :class="{ 'button--loading': loading }" :disabled="loading">{{ $t('profileForm.submit') }}</button>

                            <div v-if="showMessage">
                                <p class="text-green">{{ $t('profileForm.saveMessage') }}</p>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import $ from 'jquery';
import { reverseTransform } from '@/services/profile-transformer';
import axios from '@/config/axios';
import { AUTH_GET_PROFILE } from '@/store/modules/security';
import { mapActions } from 'vuex';
import AutosizeInput from '@/components/AutosizeInput.vue';

export default {
    name: 'ProfileForm',
    components: { AutosizeInput },
    props: {
        profile: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        loading: false,
        showMessage: false,
    }),

    methods: {
        submit() {
            this.loading = true;

            const data = reverseTransform(this.profile);

            axios({
                url: '/api/v2/profile',
                method: 'POST',
                data,
            }).then(() => {
                this.getProfile().then(() => {
                    this.loading = false;
                    this.showMessage = true;
                });
            });
        },

        ...mapActions('security', {
            getProfile: AUTH_GET_PROFILE,
        }),
    },

    mounted() {
        this.$nextTick(() => {
            $(document).trigger('value-changed');
        });
    },

    watch: {
        'profile.gender': {
            handler() {
                $(document).trigger('value-changed');
            },
        },
    },
};
</script>
