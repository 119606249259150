<template>
    <div class="grid-container">
        <div class="grid-x grid-margin-x grid-margin-y">
            <div class="cell small-24 large-12" v-for="entry in entries" :key="entry.title">
                <a :href="entry.link" class="box box--black" target="_blank">
                    <div class="box__image-container" :style="{ backgroundImage: `url(${entry.image}` }"></div>

                    <div class="box__content">
                        <h2 class="box__title">{{ entry.title }}</h2>
                        <button class="box__link">{{ $t('global.blogMore') }}</button>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Parser from 'rss-parser';
import { getSupportedLocale } from '@/config/i18n';
import axios from '@/config/mary';

export default {
    data: () => ({
        entries: [],
    }),

    mounted() {
        this.fetchBlogPosts();
    },

    watch: {
        '$i18n.locale': function localeWatcher() {
            this.fetchBlogPosts();
        },
    },

    methods: {
        fetchBlogPosts() {
            this.entries = [];

            const parser = new Parser({
                customFields: {
                    item: [
                        ['media:content', 'imageObject'],
                    ],
                },
            });

            const locale = getSupportedLocale(this.$i18n.locale);

            axios({
                url: `/share/${locale}.xml`,
                method: 'GET',
                headers: {
                    Accept: 'application/xml',
                },
            }).then((response) => {
                const { data } = response;

                parser.parseString(data).then((feed) => {
                    for (let i = 0; i < 2; i += 1) {
                        const item = feed.items[i];

                        if (!item) {
                            return;
                        }

                        const { link, title, imageObject } = item;
                        const image = imageObject.$.url;

                        this.entries.push({
                            link,
                            title,
                            image,
                        });
                    }
                });
            }).catch(() => {
                // NOP
            });
        },
    },
};
</script>
