import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

// Views
import Dashboard from '@/views/Dashboard.vue';
import Archive from '@/views/Archive.vue';
import AddressBook from '@/views/AddressBook.vue';
import Active from '@/views/Active.vue';
import CreateAddress from '@/views/CreateAddress.vue';
import PriceCalculator from '@/views/PriceCalculator.vue';
import Offer from '@/views/Offer.vue';
import Order from '@/views/Order.vue';
import RecurringShipments from '@/views/RecurringShipments.vue';
import Profile from '@/views/Profile.vue';
import EditAddress from '@/views/EditAddress.vue';
import OrderConfirm from '@/views/OrderConfirm.vue';
import OrderThankYou from '@/views/OrderThankYou.vue';
import PasswordChange from '@/views/PasswordChange.vue';
import OfferThankYou from '@/views/OfferThankYou.vue';
import RegisterThankYou from '@/views/RegisterThankYou.vue';

// Layouts
import Login from '@/layouts/Login.vue';
import Welcome from '@/layouts/Welcome.vue';
import Goodbye from '@/layouts/Goodbye.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            auth: true,
            layout: Welcome,
        },
    },
    {
        path: '/active',
        name: 'Active',
        component: Active,
        meta: {
            auth: true,
        },
    },
    {
        path: '/archive',
        name: 'Archive',
        component: Archive,
        meta: {
            auth: true,
        },
    },
    {
        path: '/recurring-orders',
        name: 'RecurringShipments',
        component: RecurringShipments,
        meta: {
            auth: true,
        },
    },
    {
        path: '/order',
        name: 'Order',
        component: Order,
        meta: {
            auth: true,
        },
    },
    {
        path: '/order/confirm',
        name: 'OrderConfirm',
        component: OrderConfirm,
        meta: {
            auth: true,
        },
    },
    {
        path: '/order/thank-you/:mail',
        name: 'OrderThankYou',
        component: OrderThankYou,
        meta: {
            auth: true,
        },
    },
    {
        path: '/offer',
        name: 'Offer',
        component: Offer,
        meta: {
            auth: true,
        },
    },
    {
        path: '/offer/thank-you',
        name: 'OfferThankYou',
        component: OfferThankYou,
        meta: {
            auth: true,
        },
    },
    {
        path: '/price-calculator',
        name: 'PriceCalculator',
        component: PriceCalculator,
        meta: {
            auth: true,
        },
    },

    // Address book
    {
        path: '/address-book',
        name: 'AddressBook',
        component: AddressBook,
        meta: {
            auth: true,
        },
    },
    {
        path: '/address-book/create',
        name: 'CreateAddress',
        component: CreateAddress,
        meta: {
            auth: true,
        },
    },
    {
        path: '/address-book/:address/edit',
        name: 'EditAddress',
        component: EditAddress,
        meta: {
            auth: true,
        },
    },

    // Profile
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            auth: true,
        },
    },
    {
        path: '/profile/change-password',
        name: 'PasswordChange',
        component: PasswordChange,
        meta: {
            auth: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
        meta: {
            layout: Login,
        },
    },
    {
        path: '/goodbye',
        name: 'Goodbye',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Goodbye.vue'),
        meta: {
            layout: Goodbye,
        },
    },
    {
        path: '/registered',
        name: 'RegisterThankYou',
        component: RegisterThankYou,
        meta: {
            layout: Login,
        },
    },
    {
        path: '/register/',
        component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue'),
        meta: {
            layout: Login,
        },
        children: [
            {
                name: 'RegisterUserStep',
                path: '',
                component: () => import(/* webpackChunkName: "register" */ '@/components/RegisterUserStep.vue'),
                meta: {
                    layout: Login,
                },
            },
            {
                name: 'RegisterAddressesStep',
                path: 'addresses',
                component: () => import(/* webpackChunkName: "register" */ '@/components/RegisterAddressesStep.vue'),
                meta: {
                    layout: Login,
                },
            },
            {
                name: 'RegisterPaymentStep',
                path: 'payment',
                component: () => import(/* webpackChunkName: "register" */ '@/components/RegisterPaymentStep.vue'),
                meta: {
                    layout: Login,
                },
            },
        ],
    },
    {
        path: '/login/password-reset',
        name: 'PasswordReset',
        component: () => import(/* webpackChunkName: "login" */ '@/views/PasswordReset.vue'),
        meta: {
            layout: Login,
        },
    },
    {
        path: '/login/password-reset/set-password/:hash',
        name: 'PasswordResetSetPassword',
        component: () => import(/* webpackChunkName: "login" */ '@/views/PasswordResetSetPassword.vue'),
        meta: {
            layout: Login,
        },
    },
    {
        name: '404',
        path: '*',
        component: () => import(/* webpackChunkName: "notFound" */ '@/views/NotFound.vue'),
        meta: {
            layout: Login,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.auth)) {
        if (store.getters['security/isAuthenticated']) {
            next();
            return;
        }

        next('/login');
    } else {
        next();
    }
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.auth)) {
        const user = store.getters['security/user'];

        // So, after a reload, we don't have the user stored yet
        // I consider this "good enough", as password change redirect
        // will happen on the next request anyway.
        if (!user) {
            next();
            return;
        }

        if (to.name === 'PasswordChange') {
            next();
            return;
        }

        if (user.shouldChangePassword) {
            next('/profile/change-password');
            return;
        }
    }

    next();
});

export default router;
