<template>
    <div class="filter" :class="{ 'filter--open': panelOpen, 'filter--is-filtered': isFiltered }" ref="filter">
        <button class="filter__button" @click="open">{{ $t('filter.button') }}</button>
        <button class="filter__reset" @click="reset" v-if="isFiltered">{{ $t('filter.reset') }}</button>

        <div class="filter__panel">
            <button class="filter__close" @click="close"></button>

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-16 medium-offset-4">
                        <div class="grid-container">
                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24">
                                    <h3 class="filter__title">{{ $t('filter.title') }}</h3>
                                </div>
                            </div>

                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <div class="form-field" :class="{ 'form-field--has-value': filter.day }">
                                            <v-select
                                                v-model.lazy="filter.day"
                                                :value="filter.day"
                                                :options="days"
                                                :filterable="false"
                                                :clearable="true"
                                                :searchable="false"
                                                :select-on-tab="true"
                                            >
                                                <template #no-options>
                                                    {{ $t('filter.indicator') }}
                                                </template>
                                            </v-select>

                                            <span class="form-field__label">{{ $t('filter.days') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <input v-model="filter.sender" type="text">

                                        <span class="form-field__label">{{ $t('filter.sender') }}</span>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <input v-model="filter.receiver" type="text">

                                        <span class="form-field__label">{{ $t('filter.receiver') }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-12">
                                    <div class="form-field" :class="{ 'form-field--has-value': filter.transportTypeId }">
                                        <v-select
                                            v-model.lazy="filter.transportTypeId"
                                            :value="filter.transportTypeId"
                                            :options="transportTypes"
                                            :filterable="false"
                                            :clearable="true"
                                            :searchable="false"
                                            :select-on-tab="true"
                                        >
                                            <template #no-options>
                                                {{ $t('filter.indicator') }}
                                            </template>
                                        </v-select>

                                        <span class="form-field__label">{{ $t('filter.transportType') }}</span>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <datepicker
                                            v-model="filter.pickupDate"
                                            :language="languages[this.$i18n.locale]"
                                            :monday-first="true"
                                            format="dd.MM.yyyy"
                                            name="pickupDate"
                                        />

                                        <span class="form-field__suffix form-field__suffix--calendar" />
                                        <span class="form-field__label">{{ $t('filter.pickupDate') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button class="button" @click="apply">{{ $t('filter.apply') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    de, en, fr, it,
} from 'vuejs-datepicker/dist/locale';
import $ from 'jquery';

export default {
    name: 'RecurringShipmentFilter',

    props: {
        initialState: {
            type: String,
            required: false,
            default: '',
        },
    },

    data: () => ({
        emptyFilter: {
            sender: '',
            receiver: '',
            transportTypeId: 0,
            pickupDate: null,
            day: '',
        },

        filter: {
            sender: '',
            receiver: '',
            transportTypeId: 0,
            pickupDate: null,
            day: '',
        },

        panelOpen: false,
        languages: {
            de,
            en,
            fr,
            it,
        },
    }),

    mounted() {
        this.$nextTick(() => {
            if (!this.initialState) {
                this.apply();
                return;
            }

            this.filter.state = this.states.find((state) => state.id === this.initialState);
            this.apply();
        });
    },

    computed: {
        isFiltered() {
            return JSON.stringify(this.emptyFilter) !== JSON.stringify(this.filter);
        },

        transportTypes() {
            return [
                { id: 0, label: this.$t('filter.transportTypes.0') },
                { id: 1, label: this.$t('filter.transportTypes.1') },
                { id: 3, label: this.$t('filter.transportTypes.3') },
                { id: 4, label: this.$t('filter.transportTypes.4') },
            ];
        },

        days() {
            return [
                { id: 'monday', label: this.$t('global.days.monday') },
                { id: 'tuesday', label: this.$t('global.days.tuesday') },
                { id: 'wednesday', label: this.$t('global.days.wednesday') },
                { id: 'thursday', label: this.$t('global.days.thursday') },
                { id: 'friday', label: this.$t('global.days.friday') },
                { id: 'saturday', label: this.$t('global.days.saturday') },
                { id: 'sunday', label: this.$t('global.days.sunday') },
            ];
        },
    },

    watch: {
        filter: {
            handler() {
                $(document).trigger('value-changed');
            },
            deep: true,
        },
    },

    methods: {
        apply() {
            const data = {
                transportTypeId: this.filter.transportTypeId ? this.filter.transportTypeId.id : null,
                pickupDate: this.filter.pickupDate,
                sender: this.filter.sender,
                receiver: this.filter.receiver,
            };

            if (this.filter.day) {
                data[this.filter.day.id] = 1;
            }

            // Explicitly mapped, as we don't want to have reactive fields
            this.$emit('filter', data);
            this.close();
        },

        open() {
            this.panelOpen = true;
        },

        close() {
            this.panelOpen = false;
        },

        reset() {
            this.filter = { ...this.emptyFilter };

            this.apply();
        },
    },
};
</script>
