import * as moment from 'moment';
import { getAddressesFromApi } from './shipment-transformer';

export function transform(shipment) {
    const from = getAddressesFromApi(shipment.addresses.from, 'from');
    const to = getAddressesFromApi(shipment.addresses.to, 'to');

    return {
        key: shipment.id,
        externalNumber: shipment.formatted_number,
        reference: shipment.rechnungsbeschreibung_offiziell,
        type: shipment.transportart_id,
        customerName: shipment.customerName ?? '',

        from,
        to,

        // Tour
        isTour: true,

        days: shipment.standing_order_active_on,

        price: shipment.preis_netto,

        // Pickup & Delivery window
        pickupFrom: shipment.pickup_from ? moment.default(shipment.pickup_from).toDate() : null,
        pickupTo: shipment.pickup_to ? moment.default(shipment.pickup_to).toDate() : null,
        deliveryFrom: shipment.drop_from ? moment.default(shipment.drop_from).toDate() : null,
        deliveryTo: shipment.drop_to ? moment.default(shipment.drop_to).toDate() : null,
    };
}
