import * as moment from 'moment';

moment.fn.roundNext15Min = function roundNext15Min() {
    let intervals = Math.floor(this.minutes() / 15);

    if (this.minutes() % 15 !== 0) {
        intervals += 1;
    }

    if (intervals === 4) {
        this.add('hours', 1);
        intervals = 0;
    }

    this.minutes(intervals * 15);
    this.seconds(0);
    return this;
};

export function getPickupDate(current) {
    const today = moment.default(new Date());

    if (!current) {
        return today.toDate();
    }

    const diff = today.diff(moment.default(current), 'days');

    if (diff > 0) {
        return today;
    }

    return current;
}

export function getPickupTime(date, time) {
    const currentDate = moment.default(date);
    const now = moment.default(new Date());
    const current = moment.default(`${currentDate.get('year')} ${currentDate.get('month') + 1} ${currentDate.get('date')} ${time}`, 'YYYY M D HHmm');

    const diff = now.diff(moment.default(current), 'minutes');

    if (diff <= 5) {
        return time;
    }

    const remainder = 5 - (now.minute() % 5);

    return moment.default(now).add(remainder, 'minutes').format('HHmm');
}

export function getLastOrderTime(result) {
    if (result === null) {
        return '';
    }

    const date = moment.default(new Date());

    date.hours(18)
        .minutes(0);

    const durationStruct = result.totalduration.split(':');

    date.subtract(Number.parseInt(durationStruct[0], 10), 'hours');
    date.subtract(Number.parseInt(durationStruct[1], 10), 'minutes');

    return date.roundNext15Min().format('HH:mm');
}
