import storage from '@/services/storage';

export default {
    namespaced: true,

    state: {
        form: storage.get('form'),
    },

    getters: {
        storedForm: (state) => state.form || {},
    },

    actions: {
        save: ({ commit }, data) => new Promise((resolve) => {
            commit('changeFormData', data);
            resolve();
        }),

        clear: ({ commit }) => new Promise((resolve) => {
            commit('resetFormData');
            storage.reset();

            resolve();
        }),
    },

    mutations: {
        changeFormData: (state, data) => {
            const form = state.form || {};

            state.form = Object.assign(form, data);
            storage.set('form', state.form);
        },

        resetFormData: (state) => {
            state.form = {};
            storage.set('form', {});
        },
    },
};
