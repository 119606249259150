<template>
    <div class="service-information">
        <ul class="service-information__list">
            <li class="service-information__entry">
                <h4 class="service-information__title">{{ $t('pickupTimeDisclaimer.title') }}</h4>
                <div class="service-information__description" v-html="$t('pickupTimeDisclaimer.text', { time: formattedTime })"></div>
            </li>
        </ul>
    </div>
</template>

<script>
import { timeFormat } from '@/services/date-formatter';

export default {
    props: {
        time: {
            type: String,
            required: true,
        },
    },

    computed: {
        formattedTime() {
            return timeFormat(this.time);
        },
    },
};
</script>
