<template>
    <div class="pagination" v-if="initialized">
        <div class="grid-container">
            <div class="grid-x grid-margin-x align-middle">
                <div class="cell shrink">
                    <div class="pagination__info">
                        <span>({{ $t('pagination.info', { start, end, total }) }})</span>
                    </div>
                </div>
                <div class="cell auto">
                    <div class="pagination__selector">
                        <label class="pagination__select-label" for="per-page">{{ $t('pagination.perPage') }}</label>
                        <select class="pagination__select" id="per-page" :value="perPage" :disabled="updating" @change="perPageChanged">
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
                <div class="cell shrink">
                    <div class="pagination__navigation">
                        <button :disabled="updating || page === 0" @click="prev" class="pagination__button">{{ $t('pagination.prev') }}</button>
                        <button :disabled="updating || page >= numberOfPages" @click="next" class="pagination__button">{{ $t('pagination.next') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pagination',

    props: {
        page: Number,
        total: Number,
        perPage: Number,
        initialized: Boolean,
        updating: Boolean,
    },

    computed: {
        start() {
            return this.total === 0 ? 0 : (this.page * this.perPage) + 1;
        },

        end() {
            return Math.min(this.total, (this.page * this.perPage) + this.perPage);
        },

        numberOfPages() {
            return Math.floor(this.total / this.perPage);
        },
    },

    methods: {
        perPageChanged(event) {
            this.$emit('perPageChanged', parseInt(event.target.value, 10));
        },

        next() {
            this.$emit('pageChanged', this.page + 1);
        },

        prev() {
            this.$emit('pageChanged', this.page - 1);
        },
    },
};
</script>
