<template>
    <div class="filter" :class="{ 'filter--open': panelOpen, 'filter--is-filtered': isFiltered }" ref="filter">
        <button class="filter__button" @click="open">{{ $t('filter.button') }}</button>
        <button class="filter__reset" @click="reset" v-if="isFiltered">{{ $t('filter.reset') }}</button>

        <div class="filter__panel">
            <button class="filter__close" @click="close"></button>

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-16 medium-offset-4">
                        <div class="grid-container">
                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24">
                                    <h3 class="filter__title">{{ $t('filter.title') }}</h3>
                                </div>
                            </div>

                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <input v-model="filter.company" type="text" @keyup.enter="apply" @input="resetCheckboxes">

                                        <span class="form-field__label">{{ $t('filter.company') }}</span>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <input v-model="filter.street" type="text" @keyup.enter="apply" @input="resetCheckboxes">

                                        <span class="form-field__label">{{ $t('filter.street') }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-12">
                                    <div class="grid-container">
                                        <div class="grid-x grid-margin-x">
                                            <div class="cell small-8">
                                                <!-- Zip -->
                                                <ValidationProvider v-slot="v" rules="" tag="div">
                                                    <div class="form-field" :class="v.classes">
                                                        <input v-model="filter.zip" @keyup.enter="apply" @input="resetCheckboxes">

                                                        <span class="form-field__label">{{ $t('address.zip') }}</span>
                                                        <span class="form-field__error">{{ v.errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="cell small-16">
                                                <!-- Location -->
                                                <ValidationProvider v-slot="v" rules="" tag="div">
                                                    <div class="form-field" :class="v.classes">
                                                        <input v-model="filter.location" @keyup.enter="apply" @input="resetCheckboxes">

                                                        <span class="form-field__label">{{ $t('address.locationString') }}</span>
                                                        <span class="form-field__error">{{ v.errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <input v-model="filter.id" type="text" @keyup.enter="apply" @input="resetCheckboxes">

                                        <span class="form-field__label">{{ $t('filter.addressId') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-12">
                                    <div class="form-field">
                                        <ul class="checkbox-group checkbox-group--vertical">
                                            <li class="checkbox-group__item">
                                                <div class="checkbox-group__input">
                                                    <input id="standardSenderAddress" v-model="filter.standardSenderAddress" type="checkbox" @change="resetTextInputs">
                                                    <label for="standardSenderAddress">{{ $t('form.address.standardSenderAddress') }}</label>
                                                </div>
                                            </li>
                                            <li class="checkbox-group__item">
                                                <div class="checkbox-group__input">
                                                    <input id="standardReceiverAddress" v-model="filter.standardReceiverAddress" type="checkbox" @change="resetTextInputs">
                                                    <label for="standardReceiverAddress">{{ $t('form.address.standardReceiverAddress') }}</label>
                                                </div>
                                            </li>
                                            <li class="checkbox-group__item">
                                                <div class="checkbox-group__input">
                                                    <input id="standardBillingAddress" v-model="filter.standardBillingAddress" type="checkbox" @change="resetTextInputs">
                                                    <label for="standardBillingAddress">{{ $t('form.address.standardBillingAddress') }}</label>
                                                </div>
                                            </li>
                                            <li class="checkbox-group__item">
                                                <div class="checkbox-group__input">
                                                    <input id="isBillingAddress" v-model="filter.isBillingAddress" type="checkbox" @change="resetTextInputs">
                                                    <label for="isBillingAddress">{{ $t('form.address.isBillingAddress') }}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button class="button" @click="apply">{{ $t('filter.apply') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';

export default {
    name: 'AddressFilter',
    data: () => ({
        emptyFilter: {
            company: '',
            street: '',
            id: '',
            location: '',
            zip: '',
            standardSenderAddress: false,
            standardReceiverAddress: false,
            standardBillingAddress: false,
            isBillingAddress: false,
        },

        filter: {
            company: '',
            street: '',
            id: '',
            location: '',
            zip: '',
            standardSenderAddress: false,
            standardReceiverAddress: false,
            standardBillingAddress: false,
            isBillingAddress: false,
        },

        panelOpen: false,
    }),

    computed: {
        isFiltered() {
            return JSON.stringify(this.emptyFilter) !== JSON.stringify(this.filter);
        },
    },

    watch: {
        filter: {
            handler() {
                $(document).trigger('value-changed');
            },
            deep: true,
        },

        'filter.standardBillingAddress': {
            handler(value) {
                if (value) {
                    this.filter.isBillingAddress = true;
                }
            },
        },
    },

    methods: {
        apply() {
            const data = {
                kontaktadresseId: this.filter.id,
                firma: this.filter.company,
                adresse: this.filter.street,
                plz: this.filter.zip,
                ort: this.filter.location,
                ...(this.filter.standardReceiverAddress && { default_empfaenger: this.filter.standardReceiverAddress }),
                ...(this.filter.standardSenderAddress && { default_absender: this.filter.standardSenderAddress }),
                ...(this.filter.standardBillingAddress && { default_rechnungsadresse: this.filter.standardBillingAddress }),
                ...(this.filter.isBillingAddress && { ist_rechnungsadresse: this.filter.isBillingAddress }),
            };

            // Explicitly mapped, as we don't want to have reactive fields
            this.$emit('filter', data);
        },

        open() {
            this.panelOpen = true;
        },

        close() {
            this.panelOpen = false;
        },

        reset() {
            this.filter = { ...this.emptyFilter };

            this.apply();
        },

        resetTextInputs() {
            this.filter.company = '';
            this.filter.street = '';
            this.filter.id = '';
            this.filter.location = '';
            this.filter.zip = '';
        },

        resetCheckboxes() {
            this.filter.standardSenderAddress = false;
            this.filter.standardReceiverAddress = false;
            this.filter.standardBillingAddress = false;
            this.filter.isBillingAddress = false;
        },
    },
};
</script>
