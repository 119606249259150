<template>
    <div class="widget">
        <div class="card">
            <h2 class="card__title">{{ $t('widget.active.title') }}</h2>

            <table class="widget-table">
                <tr class="widget-table__row widget-table__grid--1" :class="{ 'widget-table__row--loading': !initialized }">
                    <td class="widget-table__cell">{{ $t('widget.active.inPreparation') }}</td>
                    <td class="widget-table__cell widget-table__cell--text-right" v-if="initialized">{{ $tc('widget.active.shipments', inPreparation, { count: inPreparation }) }}</td>
                    <td class="widget-table__cell widget-table__cell--text-right" v-else>{{ $t('global.loading') }}</td>
                    <td class="widget-table__more">
                        <router-link :to="{ name: 'Active', query: { 'state': 'inPreparation' } }" :disabled="!initialized">{{ $t('global.more') }}</router-link>
                    </td>
                </tr>
                <tr class="widget-table__row widget-table__grid--1" :class="{ 'widget-table__row--loading': !initialized }">
                    <td class="widget-table__cell">{{ $t('widget.active.readyForPickup') }}</td>
                    <td class="widget-table__cell widget-table__cell--text-right" v-if="initialized">{{ $tc('widget.active.shipments', readyForPickup, { count: readyForPickup }) }}</td>
                    <td class="widget-table__cell widget-table__cell--text-right" v-else>{{ $t('global.loading') }}</td>
                    <td class="widget-table__more">
                        <router-link :to="{ name: 'Active', query: { 'state': 'readyForPickup' } }" :disabled="!initialized">{{ $t('global.more') }}</router-link>
                    </td>
                </tr>
                <tr class="widget-table__row widget-table__grid--1" :class="{ 'widget-table__row--loading': !initialized }">
                    <td class="widget-table__cell">{{ $t('widget.active.pickedUp') }}</td>
                    <td class="widget-table__cell widget-table__cell--text-right" v-if="initialized">{{ $tc('widget.active.shipments', pickedUp, { count: pickedUp }) }}</td>
                    <td class="widget-table__cell widget-table__cell--text-right" v-else>{{ $t('global.loading') }}</td>
                    <td class="widget-table__more">
                        <router-link :to="{ name: 'Active', query: { 'state': 'pickedUp' } }" :disabled="!initialized">{{ $t('global.more') }}</router-link>
                    </td>
                </tr>
                <tr class="widget-table__row widget-table__grid--1" :class="{ 'widget-table__row--loading': !initialized }">
                    <td class="widget-table__cell">{{ $t('widget.active.inTransit') }}</td>
                    <td class="widget-table__cell widget-table__cell--text-right" v-if="initialized">{{ $tc('widget.active.shipments', inTransit, { count: inTransit }) }}</td>
                    <td class="widget-table__cell widget-table__cell--text-right" v-else>{{ $t('global.loading') }}</td>
                    <td class="widget-table__more">
                        <router-link :to="{ name: 'Active', query: { 'state': 'inTransit' } }" :disabled="!initialized">{{ $t('global.more') }}</router-link>
                    </td>
                </tr>
                <tr class="widget-table__row widget-table__grid--1" :class="{ 'widget-table__row--loading': !initialized }">
                    <td class="widget-table__cell">{{ $t('widget.active.delivered') }}</td>
                    <td class="widget-table__cell widget-table__cell--text-right" v-if="initialized">{{ $tc('widget.active.shipments', delivered, { count: delivered }) }}</td>
                    <td class="widget-table__cell widget-table__cell--text-right" v-else>{{ $t('global.loading') }}</td>
                    <td class="widget-table__more">
                        <router-link :to="{ name: 'Archive' }" :disabled="!initialized">{{ $t('global.more') }}</router-link>
                    </td>
                </tr>
            </table>

            <router-link :to="{ name: 'Active' }" class="card__link">{{ $t('widget.active.link') }}</router-link>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios';

export default {
    name: 'ActiveWidget',

    data: () => ({
        initialized: false,

        inPreparation: undefined,
        readyForPickup: undefined,
        pickedUp: undefined,
        inTransit: undefined,
        delivered: undefined,
    }),

    mounted() {
        this.initialized = false;

        axios({
            url: '/api/v2/shipments/summary',
            method: 'GET',
        })
            .then((response) => {
                const { data } = response;

                this.inPreparation = data.inPreparation;
                this.readyForPickup = data.readyForPickup;
                this.pickedUp = data.pickedUp;
                this.inTransit = data.inTransit;
                this.delivered = data.delivered;

                this.initialized = true;
            });
    },
};
</script>
