<template>
    <header class="page__header" itemscope="" itemtype="http://schema.org/WPHeader">
        <div class="header">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24">
                        <div class="header__content">
                            <div class="header__nav" v-if="isAuthenticated">
                                <button @click="toggleMobileNav" class="header__burger"></button>
                            </div>

                            <div class="header__left">
                                <router-link :to="{ name: 'Dashboard' }" class="header__logo"></router-link>
                            </div>

                            <div class="header__right" v-if="isAuthenticated">
                                <div class="header__right-content">
                                    <div class="header__upper">
                                        <TrackPopup />
                                        <LanguageSwitcher />
                                        <Avatar />
                                    </div>
                                </div>
                            </div>
                            <div v-else class="header__right">
                                <div class="header__right-content">
                                    <div class="header__upper">
                                        <nav class="language">
                                            <LanguageSwitcher />
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="header__navigation-container">
            <NewVersionMessage />
            <WelcomeMessage v-if="showWelcomeMessage && isAuthenticated && user"></WelcomeMessage>

            <Navigation v-if="isAuthenticated && user" />
        </div>

        <div class="fixed-sidebar">
            <div class="fixed-sidebar__content">
                <a class="fixed-sidebar__help button button--small" :href="$t('links.faq')">{{ $t('header.faq') }}</a>
                <a :href="$t('links.telto')" class="fixed-sidebar__phone button button--small">{{ $t('header.tel') }}</a>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';

import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import Navigation from '@/components/Navigation.vue';
import WelcomeMessage from '@/components/WelcomeMessage.vue';
import TrackPopup from '@/components/TrackPopup.vue';
import Avatar from '@/components/Avatar.vue';
import NewVersionMessage from '@/components/NewVersionMessage.vue';
import $ from 'jquery';

export default {
    name: 'PageHeader',

    props: {
        showWelcomeMessage: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    components: {
        NewVersionMessage,
        Avatar,
        TrackPopup,
        WelcomeMessage,
        Navigation,
        LanguageSwitcher,
    },

    computed: {
        ...mapGetters('security', [
            'isAuthenticated',
            'user',
        ]),
    },

    methods: {
        toggleMobileNav() {
            const header = $('.header');
            const navigation = $('.navigation');
            const welcome = $('.welcome-message');

            $('body, html').toggleClass('mobile--open').animate({
                scrollTop: 0,
            }, 250);

            header.toggleClass('header--open');
            navigation.toggleClass('navigation--open');
            welcome.toggleClass('welcome-message--open');

            if (!header.hasClass('header--open')) {
                setTimeout(() => {
                    this.resetMobileNav();
                }, 500);
            }
        },

        resetMobileNav() {
            const header = $('.header');
            const navigation = $('.navigation');
            const welcome = $('.welcome-message');

            if (header.hasClass('header--open')) {
                $('body, html').removeClass('mobile--open');
                header.removeClass('header--open');
                navigation.removeClass('navigation--open');
                welcome.removeClass('welcome-message--open');
            }
        },
    },
};
</script>
