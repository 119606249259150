<template>
    <div class="offer">
        <div class="card">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 small-24 medium-24 large-24 xlarge-24 xxlarge-16 xxlarge-offset-4">
                        <div class="card__header">
                            <h1 class="card__title">{{ $t('orderThankYou.title') }}</h1>
                        </div>

                        <div class="card__body">
                            <div class="grid-container">
                                <div class="grid-x grid-margin-x">
                                    <div class="cell small-24 large-12">
                                        <p v-html="$t('orderThankYou.text', { mail: $route.params.mail })"></p>
                                    </div>
                                </div>
                            </div>

                            <p>
                                <strong>{{ $t('orderThankYou.contact') }}</strong>
                            </p>

                            <router-link :to="{ name: 'Order' }" class="button button--arrow">{{ $t('orderThankYou.orderButton') }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BlogPosts />
    </div>
</template>

<script>
import BlogPosts from '@/components/BlogPosts.vue';

export default {
    name: 'OrderThankYou',
    components: { BlogPosts },
    metaInfo() {
        return {
            title: this.$t('orderThankYou.title'),
        };
    },
};
</script>
