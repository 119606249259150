<template>
    <div class="info-box">
        <!-- Rechnung -->
        <h3 class="info-box__title" v-if="title">{{ title }}</h3>

        <template v-if="address">
            <ul class="info-box__list">
                <!-- Company / Name -->
                <li class="info-box__list-item" v-if="address.type === 'company'">
                    <div class="info-box__list-key">{{ $t('address.company') }}</div>
                    <div class="info-box__list-value">{{ address.name }}</div>
                </li>
                <li class="info-box__list-item" v-else>
                    <div class="info-box__list-key">{{ $t('address.name') }}</div>
                    <div class="info-box__list-value">{{ address.name }}</div>
                </li>

                <!-- Contact -->
                <li class="info-box__list-item" v-if="address.contact">
                    <div class="info-box__list-key">{{ $t('address.contact') }}</div>
                    <div class="info-box__list-value">{{ address.contact }}</div>
                </li>

                <!-- Street -->
                <li class="info-box__list-item">
                    <div class="info-box__list-key">{{ $t('address.street') }}</div>
                    <div class="info-box__list-value">{{ address.street }}</div>
                </li>

                <!-- Location -->
                <li class="info-box__list-item">
                    <div class="info-box__list-key">{{ $t('address.location') }}</div>
                    <div class="info-box__list-value">{{ address.zip }} {{ address.location }}</div>
                </li>

                <!-- Phone -->
                <li class="info-box__list-item" v-if="address.phone">
                    <div class="info-box__list-key">{{ $t('address.phone') }}</div>
                    <div class="info-box__list-value">{{ address.phone }}</div>
                </li>
            </ul>

            <router-link :to="{ name: 'EditAddress', params: { address: address.id } }" class="info-box__action" v-if="showChangeLink">{{ $t('profile.changeAddress') }}</router-link>
        </template>
        <template v-else>
            <router-link :to="{ name: 'AddressBook' }" class="info-box__action" v-if="showChangeLink">{{ $t('profile.setAddress') }}</router-link>
        </template>

    </div>
</template>

<script>
export default {
    name: 'InfoBoxAddress',

    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },

        address: {
            type: Object,
            required: false,
            default: null,
        },

        showChangeLink: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
};
</script>
