<template>
    <div class="price-calculator__result price-result">
        <div class="grid-x grid-margin-x grid-margin-y">
            <div class="cell small-24 medium-24 large-8">
                <div class="price-result__title">
                    {{ $t('priceCalculator.duration.title') }}
                </div>
                <div class="price-result__result">
                    {{ result.totalduration }} h
                </div>
                <div class="price-result__label">
                    {{ $t('priceCalculator.duration.label_title') }}:<br>
                    {{ $t('priceCalculator.duration.label', { lastOrderTime }) }}
                </div>
            </div>
            <div class="cell small-24 medium-24 large-8">
                <div class="price-result__title">
                    {{ $t('priceCalculator.price.title') }} {{ $t('priceCalculator.price.tax_included') }}
                    <small v-html="$t('priceCalculator.tariffs', { url: $t('links.tariffs')})"></small>
                </div>
                <div class="price-result__result">
                    CHF {{ result.price_inkl_mwst }}
                </div>
                <div class="price-result__label">
                    {{ $t('priceCalculator.price.label') }}
                </div>
            </div>

            <div v-if="co2" class="cell small-24 medium-24 large-8">
                <div class="price-result__title">
                    <div class="price-result__title">
                        {{ $t('priceCalculator.co2.title') }}
                    </div>
                </div>
                <div class="price-result__result price-result__co2-result">
                    {{ $t('priceCalculator.co2.value', { co2 }) }}
                </div>
                <div class="price-result__label">
                    {{ $t('priceCalculator.co2.description', { co2 }) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCo2 } from '@/services/result';
import { getLastOrderTime } from '@/services/pickup-datetime';

export default {
    name: 'PriceCalculatorResult',

    props: {
        result: {
            type: Object,
            required: true,
        },
    },

    computed: {
        co2() {
            return getCo2(this.result);
        },

        lastOrderTime() {
            return getLastOrderTime(this.result);
        },
    },
};
</script>
