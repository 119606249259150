<template>
    <div class="widget">
        <div class="card" :class="{ 'card--loading': !initialized }">
            <h2 class="card__title">{{ $t('widget.addresses.title') }}</h2>

            <div v-if="initialized && addresses.length > 0">
                <table class="widget-table">
                    <tr class="widget-table__row widget-table__grid--2" v-for="(address, index) in addresses" :key="index">
                        <td class="widget-table__cell">{{ address.name }}</td>
                        <td class="widget-table__cell">{{ address.zip }} {{ address.location }}</td>
                    </tr>
                </table>
            </div>

            <p v-if="initialized && addresses.length <= 0">
                {{ $t('widget.archive.empty') }}
            </p>

            <router-link :to="{ name: 'AddressBook' }" class="card__link">{{ $t('widget.addresses.link') }}</router-link>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios';
import { transform } from '@/services/address-transformer';
import qs from 'qs';

export default {
    name: 'AddressesWidget',

    data: () => ({
        initialized: false,

        addresses: [],
    }),

    mounted() {
        this.initialized = false;

        axios({
            url: '/api/v2/addresses',
            method: 'GET',
            params: {
                count: 3,
                offset: 0,
                sorting: {
                    field: 'id',
                    direction: 'desc',
                },
            },
            paramsSerializer: (params) => qs.stringify(params),
        })
            .then((response) => {
                const { data } = response.data;
                const addresses = [];

                data.forEach((dataPoint) => {
                    const address = transform(dataPoint);

                    addresses.push(address);
                });

                this.addresses = addresses;
                this.initialized = true;
            });
    },
};
</script>
