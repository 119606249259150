<template>
    <div class="offer-form">
        <ValidationObserver ref="observer">
            <form @submit.prevent="submit">
                <div class="form-part">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-12">
                            <PickupDate v-model="form.date" :placeholder="$t('offerForm.date')" />
                        </div>
                        <div class="cell small-24 medium-12">
                            <PickupTime v-model="form.time" :placeholder="$t('offerForm.time')" />
                        </div>
                    </div>

                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-24 large-12">
                            <ValidationProvider v-slot="v" rules="required|email" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="form.emailClient">

                                    <span class="form-field__label">{{ $t('orderForm.emailClient') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="cell small-24 medium-24 large-12">
                            <ul class="checkbox-group checkbox-group--no-margin">
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <input id="payment[syncEmailLetter]" v-model="form.syncEmailLetter" type="checkbox" name="syncEmailLetter">

                                        <label for="payment[syncEmailLetter]">{{ $t('orderForm.syncEmailLetter') }}</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-24 large-12">
                            <ValidationProvider v-slot="v" rules="required|email" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input id="emailLetter" v-model="form.emailLetter" :disabled="form.syncEmailLetter">

                                    <span class="form-field__label">{{ $t('orderForm.emailLetter') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>

                    <ValidationProvider v-slot="v" rules="required">
                        <ColiList v-model="form.colis" :class="v.classes" :enable-notes="true" />
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-12">
                        <fieldset class="form-part">
                            <legend class="form-part__title">
                                {{ $t('offerForm.sections.pickup') }}
                            </legend>

                            <OrderAddress v-model="form.sender" name="sender" />
                        </fieldset>
                    </div>
                    <div class="cell small-24 medium-12">
                        <fieldset class="form-part">
                            <legend class="form-part__title">
                                {{ $t('offerForm.sections.delivery') }}
                            </legend>

                            <OrderAddress v-model="form.receiver" name="receiver" />
                        </fieldset>
                    </div>
                </div>

                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-12">
                        <fieldset class="form-part">
                            <legend class="form-part__title">
                                {{ $t('offerForm.sections.invoice') }}
                            </legend>

                            <ValidationProvider v-slot="v" rules="" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="form.invoiceReference">

                                    <span class="form-field__label">{{ $t('offerForm.invoice.reference') }}</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider v-slot="v" rules="required|email" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="form.invoiceMail">

                                    <span class="form-field__label">{{ $t('orderForm.invoiceMail') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>

                            <ValidationObserver>
                                <ul class="radio-group radio-group--vertical">
                                    <li class="radio-group__item">
                                        <input id="invoice_monthly" v-model="form.invoiceType" type="radio" value="monthly">
                                        <label for="invoice_monthly">{{ $t('offerForm.invoice.typeMonthly') }}</label>
                                    </li>
                                    <li class="radio-group__item">
                                        <input id="invoice_single" v-model="form.invoiceType" type="radio" value="single">
                                        <label for="invoice_single">{{ $t('offerForm.invoice.typeSingle') }}</label>
                                    </li>
                                </ul>
                            </ValidationObserver>

                            <OrderAddress v-model="form.invoiceAddress" name="invoiceAddress" :is-billing-address="true" />
                        </fieldset>
                    </div>
                    <div class="cell small-24 medium-12">
                        <fieldset class="form-part">
                            <legend class="form-part__title">
                                {{ $t('offerForm.sections.notes') }}

                                <i18n
                                    path="notes.charsLeft"
                                    tag="small"
                                >
                                    <strong>{{ charsLeft }}</strong>
                                </i18n>
                            </legend>

                            <div class="form-field">
                                <ValidationProvider v-slot="v" rules="">
                                    <textarea v-model="form.notes" maxlength="2000" />

                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                    <span class="form-field__state" :class="v.classes" />
                                </ValidationProvider>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div class="offer-form__disclaimer" v-html="$t('offerForm.disclaimer', { url: $t('links.tos') })"></div>

                <div class="grid-container">
                    <div class="grid-x grid-container-x">
                        <div class="cell small-24 align-right">
                            <button type="submit" class="button button--arrow" :class="{ 'button--loading': loading }" :disabled="loading">
                                {{ $t('offerForm.button') }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import $ from 'jquery';
import { createNamespacedHelpers, mapGetters as mapGlobalGetters } from 'vuex';

import axios from '@/config/axios';

import PickupTime from '@/components/PickupTime.vue';
import PickupDate from '@/components/PickupDate.vue';
import ColiList from '@/components/ColiList.vue';
import OrderAddress from '@/components/OrderAddress.vue';

import getEmptyColiList from '@/services/coli-list-factory';
import { reverseTransform } from '@/services/shipment-transformer';
import { saveAddress } from '@/services/address-transformer';
import { getPickupDate, getPickupTime } from '@/services/pickup-datetime';
import { apiFormat, apiTimeFormat } from '@/services/date-formatter';
import WeightCalculator from '@/services/weight-calculator';

const { mapActions, mapGetters } = createNamespacedHelpers('order');

export default {
    name: 'OfferForm',
    components: {
        OrderAddress,
        ColiList,
        PickupDate,
        PickupTime,
    },

    data: () => ({
        form: {
            date: null,
            time: null,

            colis: getEmptyColiList(),

            notes: '',

            sender: null,
            receiver: null,

            syncEmailLetter: true,
            emailClient: '',
            emailLetter: '',

            invoiceReference: '',
            invoiceType: 'monthly',
            invoiceAddress: null,
            invoiceMail: '',
        },

        error: null,
        result: null,
        loading: false,

        notesTotalChars: 0,
    }),

    computed: {
        charsLeft() {
            return 2000 - this.notesTotalChars;
        },

        ...mapGlobalGetters('security', [
            'user',
        ]),

        ...mapGetters([
            'storedForm',
        ]),
    },

    methods: {
        async submit() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $('.form-field.invalid').offset().top - 25,
                }, 500);

                return;
            }

            this.loading = true;

            const weight = WeightCalculator.calculate(this.form.colis.colis);
            const data = reverseTransform(this.form, this.user, this.$i18n.locale);

            // Set to Offer instead of Order
            data.is_offer = true;

            axios({
                url: '/api/v2/get-price',
                method: 'GET',
                params: {
                    to_zip: this.form.receiver.zip,
                    to_location: this.form.receiver.location,
                    from_zip: this.form.sender.zip,
                    from_location: this.form.sender.location,
                    date: apiFormat(this.form.date),
                    time: apiTimeFormat(this.form.time),
                    weight,
                    colis: this.form.colis.count,
                },
            })
                .then((response) => {
                    const result = response.data;

                    data.transportart_id = result.transportart_id;
                    data.website_offertbetrag = result.price_inkl_mwst;
                })
                .catch(() => {
                    data.transportart_id = 3;
                    data.website_offertbetrag = 0;
                })
                .then(() => {
                    axios({
                        url: '/api/v2/shipments/create',
                        method: 'POST',
                        data,
                    }).then(() => {
                        const addresses = [this.form.sender, this.form.receiver, this.form.invoiceAddress];
                        const promises = [];

                        addresses.forEach((address) => {
                            if (!address.save) {
                                return;
                            }

                            const promise = saveAddress(address);

                            promises.push(promise);
                        });

                        Promise.all(promises).then(() => {
                            this.loading = false;

                            this.resetForm().then(() => {
                                this.$router.push({ name: 'OfferThankYou' });
                            });
                        });
                    });
                });
        },

        ...mapActions({
            saveFormChanges: 'save',
            resetForm: 'clear',
        }),
    },

    watch: {
        form: {
            handler() {
                this.saveFormChanges(this.form);
            },
            deep: true,
        },

        'form.notes': function watchNotes(value) {
            this.notesTotalChars = value.length;
        },

        'form.emailClient': function clientWatcher(value) {
            if (!this.form.syncEmailLetter) {
                return;
            }

            this.form.emailLetter = value;
        },

        'form.emailLetter': function letterWatcher(value) {
            const field = $('#emailLetter').closest('.form-field');

            if (value !== '') {
                field.attr('data-content', 1);
            } else {
                field.removeAttr('data-content');
            }
        },

        'form.syncEmailLetter': function syncWatcher(value) {
            if (!value) {
                return;
            }

            this.form.emailLetter = this.form.emailClient;
        },
    },

    mounted() {
        this.form.date = this.storedForm.date ?? getPickupDate(this.form.date);
        this.form.time = this.storedForm.time ?? getPickupTime(this.form.date, this.form.time);

        this.form.emailClient = this.user.email;
        this.form.invoiceMail = this.user.invoiceEmail;
        this.form.invoiceType = this.user.invoiceType;

        this.$nextTick(() => {
            $(document).trigger('value-changed');
        });
    },
};
</script>
