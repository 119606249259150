<template>
    <ValidationProvider v-slot="v" rules="required" tag="div">
        <div class="form-field" :class="v.classes" data-content>
            <input v-model="val" ref="input" @awesomplete-select="val = $event.text.value">

            <div class="form-field__label">{{ $t('coliList.notes') }}*</div>
            <span class="form-field__error">{{ v.errors[0] }}</span>
        </div>
    </ValidationProvider>
</template>

<script>
import Awesomplete from 'awesomplete';

export default {
    name: 'AutoCompleteInput',

    props: {
        value: String,
        list: Array,
    },

    data: () => ({
        val: '',
        completerObject: null,
    }),

    mounted() {
        this.val = this.value;
        this.init();
    },

    watch: {
        value(value) {
            this.val = value;
        },

        val(value) {
            this.$emit('input', value);
        },

        list() {
            this.completerObject.destroy();
            this.init();
        },
    },

    methods: {
        init() {
            this.completerObject = new Awesomplete(this.$refs.input, { minChars: 1, list: this.list.map((template) => template.label) });
        },
    },
};
</script>
