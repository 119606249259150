<template>
    <div class="track-popup">
        <button class="header__button" @click="open">{{ $t('trackPopup.button') }}</button>

        <div ref="popup" role="modal" id="track" class="popup popup--track">
            <div class="popup__container" ref="container">
                <button class="popup__close" @click="close"></button>

                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="cell">
                            <h3 class="title title--h3">{{ $t('trackPopup.title') }}</h3>
                            <TrackShipment />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import TrackShipment from '@/components/TrackShipment.vue';

export default {
    components: {
        TrackShipment,
    },

    mounted() {
        this.$nextTick(() => {
            $(document).on('keyup', (event) => {
                if (event.keyCode === 27) {
                    this.close();
                }
            });
        });
    },

    methods: {
        open() {
            $('html').addClass('html--fixed');
            $('body').addClass('body--fixed');
            $(this.$refs.popup).addClass('popup--active');
        },

        close() {
            $('html').removeClass('html--fixed');
            $('body').removeClass('body--fixed');
            $(this.$refs.popup).removeClass('popup--active');
        },
    },
};
</script>
