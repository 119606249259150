<template>
    <div>
        <ValidationProvider v-slot="v" :rules="{ required: true, regex: /^([0-1][0-9]|2[0-4])([0-5][0-9])([0-5][0-9])?$/, validPickupTime: 'pickupDate' }">
            <div class="form-field" :class="v.classes">
                <the-mask v-model="selected" mask="##:##" />

                <span class="form-field__label">{{ placeholder }}*</span>
                <span class="form-field__error">{{ $t(v.errors[0]) }}</span>
            </div>
        </ValidationProvider>
    </div>
</template>

<script>
import $ from 'jquery';

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        placeholder: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        selected: null,
    }),

    watch: {
        selected(updatedVal) {
            this.$emit('input', updatedVal);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.selected = this.value;
            $(document).trigger('value-changed');
        });
    },
};
</script>
