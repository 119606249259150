<template>
    <header class="page__header" itemscope="" itemtype="http://schema.org/WPHeader">
        <div class="header">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24">
                        <div class="header__content">
                            <div class="header__left">
                                <router-link :to="{ name: 'Login' }" class="header__logo"></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="header__navigation-container">
            <NewVersionMessage />
            <GoodbyeMessage />
        </div>

        <div class="fixed-sidebar">
            <div class="fixed-sidebar__content">
                <a class="fixed-sidebar__help button button--small" :href="$t('links.faq')">{{ $t('header.faq') }}</a>
                <a :href="$t('links.telto')" class="fixed-sidebar__phone button button--small">{{ $t('header.tel') }}</a>
            </div>
        </div>
    </header>
</template>

<script>
import NewVersionMessage from '@/components/NewVersionMessage.vue';
import GoodbyeMessage from '@/components/GoodbyeMessage.vue';

export default {
    name: 'PageHeaderGoodbye',

    components: {
        GoodbyeMessage,
        NewVersionMessage,
    },
};
</script>
