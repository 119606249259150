import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messageCatalogue from '@/translations/import.json';

// Translations
import master from '@/translations/master';

Vue.use(VueI18n);

// console.log(JSON.stringify(master));

export const messages = {
    de: messageCatalogue['de-CH'],
    fr: messageCatalogue['fr-CH'],
    it: messageCatalogue['it-CH'],
    en: messageCatalogue.en,
};

if (process.env.VUE_APP_INCLUDE_MASTER_LANGUAGE === 'true') {
    messages.master = master;
}

export function getSupportedLocale(locale) {
    if (!locale) {
        return 'de';
    }

    const parts = locale.split('-');

    if (['de', 'fr', 'it', 'en'].indexOf(parts[0]) === -1) {
        return 'de';
    }

    return parts[0];
}

export const locale = localStorage.getItem('language') || getSupportedLocale(navigator.language);

export default new VueI18n({
    locale,
    messages,
});
