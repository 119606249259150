<template>
    <div class="edit-address">
        <div class="card">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 small-24 medium-24 large-24 xlarge-24 xxlarge-16 xxlarge-offset-4">
                        <div class="card__header">
                            <div class="grid-container">
                                <div class="grid-x grid-margin-x">
                                    <div class="cell small-24 medium-12">
                                        <h1 class="card__title">{{ $t('editAddress.title') }}</h1>
                                    </div>
                                    <div class="cell small-24 medium-12 align-right">
                                        <router-link class="button button--white" :to="{ name: 'AddressBook' }">{{ $t('global.back') }}</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AddressForm @change="submit" v-if="address" :edit="address" :loading="loading"></AddressForm>
                        <div v-else>{{ $t('global.loading') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapActions as mapGlobalActions } from 'vuex';
import axios from '@/config/axios';
import AddressForm from '@/components/AddressForm.vue';
import { transform, fetchLocationData, editAddress } from '@/services/address-transformer';
import { AUTH_GET_PROFILE } from '@/store/modules/security';

const { mapActions } = createNamespacedHelpers('order');

export default {
    name: 'EditAddress',

    components: {
        AddressForm,
    },

    data: () => ({
        loading: false,
        address: null,
    }),

    metaInfo() {
        return {
            title: this.$t('editAddress.title'),
        };
    },

    methods: {
        submit(address) {
            this.loading = true;

            editAddress(address)
                .then(() => {
                    this.resetForm().then(() => {
                        this.getProfile().then(() => {
                            this.loading = false;
                            this.$router.push({ name: 'AddressBook' });
                        });
                    });
                });
        },

        ...mapActions({
            resetForm: 'clear',
        }),

        ...mapGlobalActions('security', {
            getProfile: AUTH_GET_PROFILE,
        }),
    },

    mounted() {
        const id = this.$route.params.address;
        this.loading = true;

        axios({
            url: `/api/v2/address/${id}`,
            method: 'GET',
        })
            .then((response) => {
                const { data } = response;
                const address = transform(data);

                fetchLocationData(address, this.$i18n.locale).then((location) => {
                    if (location) {
                        address.locationObject = location;
                    }

                    this.address = address;
                    this.loading = false;
                });
            });
    },
};
</script>
