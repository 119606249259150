<template>
    <div class="order-form">
        <ValidationObserver ref="observer">
            <form @submit.prevent="submit">
                <div class="form-part">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-12">
                            <PickupDate v-model="form.date" :placeholder="$t('orderForm.date')" />
                        </div>
                        <div class="cell small-24 medium-12">
                            <PickupTime v-model="form.time" :placeholder="$t('orderForm.time')" />
                        </div>
                    </div>

                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-24 large-12">
                            <ValidationProvider v-slot="v" rules="required|email" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="form.emailClient">

                                    <span class="form-field__label">{{ $t('orderForm.emailClient') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="cell small-24 medium-24 large-12">
                            <ul class="checkbox-group checkbox-group--no-margin">
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <input id="payment[syncEmailLetter]" v-model="form.syncEmailLetter" type="checkbox" name="syncEmailLetter">

                                        <label for="payment[syncEmailLetter]">{{ $t('orderForm.syncEmailLetter') }}</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-24 large-12">
                            <ValidationProvider v-slot="v" rules="required|email" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input id="emailLetter" v-model="form.emailLetter" :disabled="form.syncEmailLetter">

                                    <span class="form-field__label">{{ $t('orderForm.emailLetter') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>

                    <ValidationProvider v-slot="v" rules="required">
                        <ColiList v-model="form.colis" :class="v.classes" :enable-notes="true" />
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-12">
                        <fieldset class="form-part">
                            <legend class="form-part__title">
                                {{ $t('orderForm.sections.pickup') }}
                            </legend>

                            <OrderAddress v-model="form.sender" name="sender" :load-from-stored-form="loadFromStoredForm" :load-from-archive="loadFromArchive" :shipment="shipment"/>
                        </fieldset>
                    </div>
                    <div class="cell small-24 medium-12">
                        <fieldset class="form-part">
                            <legend class="form-part__title">
                                {{ $t('orderForm.sections.delivery') }}
                            </legend>
                            <OrderAddress v-model="form.receiver" name="receiver" :load-from-stored-form="loadFromStoredForm" :load-from-archive="loadFromArchive" :shipment="shipment"/>
                        </fieldset>
                    </div>
                </div>

                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-12">
                        <fieldset class="form-part">
                            <legend class="form-part__title">
                                {{ $t('orderForm.sections.invoice') }}
                            </legend>

                            <ValidationProvider v-slot="v" :rules="isReferenceRequired() ? 'required' : null" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="form.invoiceReference">

                                    <span class="form-field__label">{{ $t('orderForm.invoice.reference') }}<template v-if="isReferenceRequired()">*</template></span>
                                    <span class="form-field__error">{{ getReferenceMsg(v.errors) }}</span>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider v-show="shouldShowInvoiceMail" v-slot="v" rules="required|email" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="form.invoiceMail">

                                    <span class="form-field__label">{{ $t('orderForm.invoiceMail') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>

                            <ValidationObserver v-if="user.organization.change_invoice_type_allowed">
                                <ul class="radio-group radio-group--vertical">
                                    <li class="radio-group__item">
                                        <input id="invoice_monthly" v-model="form.invoiceType" type="radio" value="monthly">
                                        <label for="invoice_monthly">{{ $t('orderForm.invoice.typeMonthly') }}</label>
                                    </li>
                                    <li class="radio-group__item">
                                        <input id="invoice_single" v-model="form.invoiceType" type="radio" value="single">
                                        <label for="invoice_single">{{ $t('orderForm.invoice.typeSingle') }}</label>
                                    </li>
                                </ul>
                            </ValidationObserver>

                            <OrderAddress v-model="form.invoiceAddress" name="invoiceAddress" :is-billing-address="true" :load-from-stored-form="loadFromStoredForm"  :shipment="shipment" :select-or-create-invoice-address="this.user.organization.select_or_create_invoice_address_allowed"/>
                        </fieldset>
                    </div>
                    <div class="cell small-24 medium-12">
                        <fieldset class="form-part">
                            <legend class="form-part__title">
                                {{ $t('orderForm.sections.notes') }}

                                <i18n path="notes.charsLeft" tag="small">
                                    <strong>{{ charsLeft }}</strong>
                                </i18n>
                            </legend>

                            <div class="form-field">
                                <ValidationProvider v-slot="v" rules="">
                                    <textarea v-model="form.notes" maxlength="2000" />

                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                    <span class="form-field__state" :class="v.classes"/>
                                </ValidationProvider>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div class="grid-container">
                    <div class="grid-x grid-container-x">
                        <div class="cell small-24 align-right">
                            <button type="submit" class="button button--arrow">
                                {{ $t('orderForm.button') }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import $ from 'jquery';
import { createNamespacedHelpers, mapGetters as mapGlobalGetters } from 'vuex';
import getEmptyColiList from '@/services/coli-list-factory';
import PickupTime from '@/components/PickupTime.vue';
import PickupDate from '@/components/PickupDate.vue';
import ColiList from '@/components/ColiList.vue';
import OrderAddress from '@/components/OrderAddress.vue';
import { getPickupDate, getPickupTime } from '@/services/pickup-datetime';
import { fetchShipment } from '@/services/shipment-fetcher';

const { mapActions, mapGetters } = createNamespacedHelpers('order');

export default {
    name: 'OrderForm',

    components: {
        OrderAddress,
        ColiList,
        PickupDate,
        PickupTime,
    },

    data: () => ({
        form: {
            date: null,
            time: null,

            colis: getEmptyColiList(),

            notes: '',

            sender: null,
            receiver: null,

            syncEmailLetter: true,
            emailClient: '',
            emailLetter: '',

            invoiceReference: '',
            invoiceType: 'monthly',
            invoiceAddress: null,
            invoiceMail: '',
        },

        notesTotalChars: 0,
        error: false,
        shipment: {},
    }),

    computed: {
        charsLeft() {
            return 2000 - this.notesTotalChars;
        },

        loadFromStoredForm() {
            return !!this.$route.query.load;
        },

        loadFromArchive() {
            return !!this.$route.params.archivedShipment;
        },

        shouldShowInvoiceMail() {
            return this.form.invoiceType !== 'monthly' && this.user.organization.change_invoice_email_allowed;
        },

        ...mapGetters([
            'storedForm',
        ]),

        ...mapGlobalGetters('security', [
            'user',
        ]),
    },

    mounted() {
        this.$nextTick(() => {
            $(document).trigger('value-changed');
        });

        if (this.loadFromArchive) {
            this.form.date = getPickupDate(this.form.date);
            this.form.emailClient = this.user.email;

            fetchShipment(this.$route.params.archivedShipment)
                .then((shipment) => {
                    this.shipment = shipment;
                    this.form.invoiceMail = this.shipment.invoiceEmailAddresses;
                    this.form.invoiceType = this.shipment.invoiceType;
                    this.form.colis.count = this.shipment.numberOfColis;
                    this.form.colis.colis = this.shipment.colis;
                });
        } else {
            if (this.loadFromStoredForm) {
                Object.keys(this.storedForm).forEach((key) => {
                    const value = this.storedForm[key];

                    if (value === null) {
                        return;
                    }

                    this.form[key] = value;
                });

                this.form.date = this.storedForm.date ?? getPickupDate(this.form.date);
                this.form.time = this.storedForm.time ?? getPickupTime(this.form.date, this.form.time);

                return;
            }

            this.form.date = this.storedForm.date ?? getPickupDate(this.form.date);
            this.form.time = this.storedForm.time ?? getPickupTime(this.form.date, this.form.time);

            this.form.emailClient = this.user.email;
            this.form.invoiceMail = this.user.invoiceEmail;
            this.form.invoiceType = this.user.invoiceType ?? 'monthly';
        }

        if (this.user.organization.use_default_coli) {
            const defaultColi = {
                height: this.user.organization.default_coli_height,
                width: this.user.organization.default_coli_width,
                length: this.user.organization.default_coli_length,
                weight: this.user.organization.default_coli_weight,
                notes: this.user.organization.default_coli_description,
            };

            this.form.colis.colis = Array.from({ length: this.user.organization.default_coli_count }, () => ({ ...defaultColi }));
            this.form.colis.count = this.user.organization.default_coli_count;
        }

        if (this.user.organization.use_default_remark) {
            this.form.notes = this.user.organization.default_remark;
        }
    },

    methods: {
        async submit() {
            const isValid = await this.$refs.observer.validate();

            if (!isValid) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $('.form-field.invalid').offset().top - 25,
                }, 500);

                this.error = true;

                return false;
            }

            this.error = false;

            await this.$router.push({ name: 'OrderConfirm' });

            return true;
        },

        ...mapActions({
            saveFormChanges: 'save',
            resetForm: 'clear',
        }),

        prefillColisObject(element) {
            return {
                notes: element.description,
                length: element.length,
                weight: element.weight,
                height: element.height,
                width: element.width,
            };
        },

        isReferenceRequired() {
            return this.user.organization.is_reference_required;
        },

        getReferenceMsg(msgStack = []) {
            if (msgStack.length > 0) {
                const lang = this.$i18n.locale;
                if (this.isReferenceRequired() && lang && this.user.organization.reference_msgs[lang]) {
                    return this.user.organization.reference_msgs[lang];
                }
                return msgStack[0];
            }

            return null;
        },
    },

    watch: {
        form: {
            handler() {
                this.saveFormChanges(this.form);
            },
            deep: true,
        },

        'form.notes': function watchNotes(value) {
            this.notesTotalChars = value.length;
        },

        'form.emailClient': function clientWatcher(value) {
            if (!this.form.syncEmailLetter) {
                return;
            }

            this.form.emailLetter = value;
        },

        'form.emailLetter': function letterWatcher(value) {
            const field = $('#emailLetter').closest('.form-field');

            if (value !== '') {
                field.attr('data-content', 1);
            } else {
                field.removeAttr('data-content');
            }
        },

        'form.syncEmailLetter': function syncWatcher(value) {
            if (!value) {
                return;
            }

            this.form.emailLetter = this.form.emailClient;
        },
    },
};
</script>
