<template>
    <ValidationProvider
        v-slot="v"
        rules="required"
    >
        <div
            class="form-field form-field--has-suffix"
            :class="v.classes"
        >
            <datepicker
                v-model="selected"
                :disabled-dates="disabledDates"
                :language="languages.de"
                :monday-first="true"
                format="dd.MM.yyyy"
                name="pickupDate"
            />

            <span class="form-field__suffix form-field__suffix--calendar" />
            <span class="form-field__label">{{ placeholder }}*</span>
            <span class="form-field__error">{{ v.errors[0] }}</span>
        </div>
    </ValidationProvider>
</template>

<script>
import {
    de, en, fr, it,
} from 'vuejs-datepicker/dist/locale';
import $ from 'jquery';

const today = new Date();
const yesterday = new Date(new Date().setDate(today.getDate() - 1));

export default {
    props: {
        value: {
            type: [String, Date],
            required: false,
            default: () => new Date(),
        },
        placeholder: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        selected: null,
        disabledDates: {
            to: yesterday,
        },
        languages: {
            de,
            en,
            fr,
            it,
        },
    }),

    watch: {
        selected(updatedVal) {
            this.$emit('input', updatedVal);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.selected = this.value;
            $(document).trigger('value-changed');
        });
    },
};
</script>
