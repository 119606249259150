import IataCalculator from '@/services/iata-calculator';

export default {
    calculate(colis) {
        let totalWeight = 0;

        for (let i = 0; i < colis.length; i += 1) {
            const coli = colis[i];

            let length = Number.parseFloat(coli.length);
            let height = Number.parseFloat(coli.height);
            let width = Number.parseFloat(coli.width);
            let weight = Number.parseFloat(coli.weight);

            length = !Number.isNaN(length) ? length : 0;
            height = !Number.isNaN(height) ? height : 0;
            width = !Number.isNaN(width) ? width : 0;
            weight = !Number.isNaN(weight) ? weight : 0;

            const localWeight = weight;
            const localIata = IataCalculator.calculate(coli.length, coli.height, coli.width);

            totalWeight += Math.max(localWeight, localIata);
        }

        if (Number.isNaN(totalWeight)) {
            return 0;
        }

        return totalWeight.toFixed(2);
    },
};
