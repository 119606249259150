import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import DatePicker from 'vuejs-datepicker';
import VueTheMask from 'vue-the-mask';
import VueAnalytics from 'vue-analytics';
import $ from 'jquery';
import KonamiCode from 'vue-konami-code';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import navigation from '@/services/navigation';
import App from '@/App.vue';

import '@/registerServiceWorker';

import i18n from '@/config/i18n';
import router from '@/config/router';
import store from '@/store';
import '@/config/validator';

// DOM-Services
import formLabels from '@/services/form-labels';

// Styles
import '@/scss/main.scss';

console.log(
    '%cRosalina', `
        font-weight: bold;
        font-size: 50px;
        color: rgb(38, 70, 83);

        text-shadow:
            3px 3px 0 rgb(42, 157, 143),
            6px 6px 0 rgb(233, 196, 106),
            9px 9px 0 rgb(244, 162, 97),
            12px 12px 0 rgb(231, 111, 81);
    `,
);
console.log(
    '%cby https://swissconnect.ch', `
        font-weight: bold;
        color: rgb(231, 111, 81);
    `,
);

// Sentry
Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,

    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

Vue.use(VueMeta);
Vue.use(VueTheMask);

if (process.env.VUE_APP_GA !== '') {
    Vue.use(VueAnalytics, {
        id: process.env.VUE_APP_GA,
        router,
    });
}

Vue.use(KonamiCode, {
    callback() {
        window.location = 'https://www.youtube.com/watch?v=D6qhwCs4R88';
    },
});

Vue.component('Datepicker', DatePicker);
Vue.component('VSelect', VueSelect);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    store,

    render: (h) => h(App),
}).$mount('#app');

$(document).on('value-changed', formLabels.fixFloatingLabels);

$(() => {
    formLabels.registerChangeHandler();
    navigation.init();
});

if (process.env.VUE_APP_MOUSEFLOW !== '') {
    // eslint-disable-next-line no-underscore-dangle
    window._mfq = window._mfq || [];
    (() => {
        const mf = document.createElement('script');
        mf.type = 'text/javascript';
        mf.defer = true;
        mf.src = '//cdn.mouseflow.com/projects/1d4ea924-f658-4c8f-8b73-90698ce42d81.js';
        document.getElementsByTagName('head')[0].appendChild(mf);
    })();
}
