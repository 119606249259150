<template>
    <div class="address-list">
        <div class="address-list__slider">
            <VueSlickCarousel :arrows="false" :dots="addresses.length > 1" ref="carousel">
                <address class="address address-list__item" v-for="(address, index) in addresses" :key="index">
                    <span class="address__large"><Label :text="address.name" /><br></span>
                    <span v-if="address.contact">{{ address.contact }}<br></span>
                    {{ address.address }}<br>
                    {{ address.zip }} {{ address.location }}<br>
                    <span v-if="address.phone">{{ address.phone }}<br></span>

                    <div class="address__state address__state--green">
                        <template v-if="address.type === 'from'">
                            <span v-if="address.timeStart && address.timeEnd">
                                {{ $t('shipment.plannedPickup', { from: dateTimeFormat(address.timeStart), to: dateTimeFormat(address.timeEnd) }) }}
                            </span>
                            <span v-else-if="shipment.pickupFrom && shipment.pickupTo">
                                {{ $t('shipment.plannedPickup', { from: dateTimeFormat(shipment.pickupFrom), to: dateTimeFormat(shipment.pickupTo) }) }}
                            </span>
                        </template>
                        <template v-if="address.type === 'to'">
                            <span v-if="address.timeStart && address.timeEnd">
                                {{ $t('shipment.plannedDelivery', { from: dateTimeFormat(address.timeStart), to: dateTimeFormat(address.timeEnd) }) }}
                            </span>
                            <span v-else-if="shipment.deliveryFrom && shipment.deliveryTo">
                                {{ $t('shipment.plannedDelivery', { from: dateTimeFormat(shipment.deliveryFrom), to: dateTimeFormat(shipment.deliveryTo) }) }}
                            </span>
                        </template>
                    </div>
                </address>
            </VueSlickCarousel>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import Label from '@/components/MultiLineLabel.vue';

import { dateTimeFormat } from '@/services/date-formatter';

export default {
    name: 'RecurringShipmentAddressList',

    components: {
        Label,
        VueSlickCarousel,
    },

    props: {
        addresses: Array,
        shipment: Object,
    },

    watch: {
        addresses() {
            this.$refs.carousel.goTo(0);
        },
    },

    methods: {
        dateTimeFormat,
    },
};
</script>
