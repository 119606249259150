<template>
    <div class="price-calculator">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">

                <div class="form-part">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-12">
                            <ValidationProvider v-slot="v" rules="required" mode="aggressive">
                                <div class="form-field form-field--direct-evaluation" :class="v.classes">
                                    <LocationSelect v-model="form.from" />

                                    <span class="form-field__label">{{ $t('priceCalculator.form.from') }}</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="cell small-24 medium-12">
                            <ValidationProvider v-slot="v" rules="required" mode="aggressive">
                                <div class="form-field form-field--direct-evaluation" :class="v.classes">
                                    <LocationSelect v-model="form.to" />

                                    <span class="form-field__label">{{ $t('priceCalculator.form.to') }}</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-12">
                            <PickupDate v-model="form.date" :placeholder="$t('priceCalculator.form.date')" />
                        </div>
                        <div class="cell small-24 medium-12">
                            <PickupTime v-model="form.time" :placeholder="$t('priceCalculator.form.time')" />
                        </div>
                    </div>

                    <ValidationProvider v-slot="v" rules="required">
                        <ColiList v-model="form.colis" :class="v.classes" :enable-notes="false" />
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <div v-if="error" class="price-calculator__error">
                    <span v-html="error" />
                </div>

                <PriceCalculatorResult v-if="result" :result="result"></PriceCalculatorResult>

                <div class="form-part">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell small-24 align-right">
                            <div v-if="result">
                                <router-link :to="{ name: 'Order' }" class="button button--arrow">{{ $t('priceCalculator.form.orderButton') }}</router-link>
                            </div>
                            <div v-else>
                                <button type="submit" class="button button--arrow" :class="{ 'button--loading': loading }" :disabled="loading || error" ref="button">{{ $t('priceCalculator.form.submit') }}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import $ from 'jquery';
import { createNamespacedHelpers } from 'vuex';

import axios from '@/config/axios';

import getEmptyColiList from '@/services/coli-list-factory';
import { apiFormat, apiTimeFormat } from '@/services/date-formatter';
import { getPickupDate, getPickupTime } from '@/services/pickup-datetime';
import WeightCalculator from '@/services/weight-calculator';

import ColiList from '@/components/ColiList.vue';
import LocationSelect from '@/components/LocationSelect.vue';
import PickupDate from '@/components/PickupDate.vue';
import PickupTime from '@/components/PickupTime.vue';
import PriceCalculatorResult from '@/components/PriceCalculatorResult.vue';

const { mapActions, mapGetters } = createNamespacedHelpers('order');

export default {
    name: 'PriceCalculatorForm',

    components: {
        PriceCalculatorResult,
        PickupTime,
        PickupDate,
        ColiList,
        LocationSelect,
    },

    data: () => ({
        form: {
            from: null,
            to: null,
            date: null,
            time: null,
            colis: getEmptyColiList(),
        },

        error: null,
        result: null,
        loading: false,

        offerUrl: '',
        pricePageUrl: '',

        firstRun: true,
    }),

    computed: {
        ...mapGetters([
            'storedForm',
        ]),
    },

    watch: {
        form: {
            handler() {
                this.result = null;
                this.error = false;
                this.loading = false;

                this.saveFormChanges(this.form);

                this.$nextTick(() => {
                    if (!this.firstRun) {
                        $(this.$refs.button).click();
                    }
                });
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions({
            saveFormChanges: 'save',
        }),

        submit() {
            this.error = null;
            this.result = false;
            this.loading = true;
            this.firstRun = false;

            const weight = WeightCalculator.calculate(this.form.colis.colis);

            axios({
                url: '/api/v2/get-price',
                method: 'GET',
                params: {
                    to_zip: this.form.to.code,
                    to_location: this.form.to.name,
                    from_zip: this.form.from.code,
                    from_location: this.form.from.name,
                    date: apiFormat(this.form.date),
                    time: apiTimeFormat(this.form.time),
                    weight,
                    colis: this.form.colis.count,
                },
            })
                .then((response) => {
                    this.result = response.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.error = this.$t('priceCalculator.error.noresult', { url: this.offerUrl });
                });
        },
    },

    mounted() {
        Object.keys(this.form).forEach((key) => {
            const value = this.storedForm[key];

            if (value === null || value === undefined) {
                return;
            }

            this.form[key] = value;
        });

        this.form.date = this.storedForm.date ?? getPickupDate(this.form.date);
        this.form.time = this.storedForm.time ?? getPickupTime(this.form.date, this.form.time);

        this.$nextTick(() => {
            $(document).trigger('value-changed');
        });
    },
};
</script>
