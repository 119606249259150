<template>
    <div class="archive">
        <div class="card">
            <div class="card__header">
                <h1 class="card__title">{{ $t('recurringShipments.title') }}</h1>

                <div class="data-table__header-button">
                    <Export :enabled="initialized" :sorting="sorting" :filter="filter" :number-of-results="total" :selected-shipments="exportShipments"  url="/api/v2/standing-order/export"></Export>
                </div>

                <RecurringShipmentFilter @filter="filter = $event" :initial-state="$route.query.state"></RecurringShipmentFilter>
            </div>

            <div v-if="!initialized">{{ $t('global.loading') }}</div>

            <div v-if="initialized && shipments.length > 0" class="card__table">
                <table class="data-table" :class="{ 'data-table--updating': updating }">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{{ $t('shipment.number') }}</th>
                            <th><TableSortingField field="sender" :sorting="sorting" :updating="updating" @sortingChanged="sorting = $event">{{ $t('shipment.sender') }}</TableSortingField></th>
                            <th><TableSortingField field="receiver" :sorting="sorting" :updating="updating" @sortingChanged="sorting = $event">{{ $t('shipment.receiver') }}</TableSortingField></th>
                            <th><TableSortingField field="price" :sorting="sorting" :updating="updating" @sortingChanged="sorting = $event">{{ $t('shipment.prices') }}</TableSortingField></th>
                            <th>{{ $t('shipment.days') }}</th>
                            <th>{{ $t('shipment.transportType') }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="shipment in shipments">
                            <td>
                                <ul class="checkbox-group checkbox-group--inline">
                                    <li class="checkbox-group__item">
                                        <div class="checkbox-group__input">
                                            <input :id="`export[${shipment.key}]`" type="checkbox" :value="shipment.key" v-model="exportShipments">
                                            <label :for="`export[${shipment.key}]`"></label>
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td>{{ shipment.key }}</td>
                            <td>
                                <div class="data-table__address-list-container">
                                    <RecurringShipmentAddressList :addresses="shipment.from" :shipment="shipment"></RecurringShipmentAddressList>
                                </div>
                            </td>
                            <td>
                                <div class="data-table__address-list-container">
                                    <RecurringShipmentAddressList :addresses="shipment.to" :shipment="shipment"></RecurringShipmentAddressList>
                                </div>
                            </td>
                            <td class="data-table--no-break">
                                {{ parseFloat(shipment.price).toFixed(2) }} CHF (exkl.)
                            </td>
                            <td>
                                <span v-for="(day, index) in shipment.days" :key="index">{{ $t(`global.days.${day}`) }}<br></span>
                            </td>
                            <td>
                                {{ $t(`global.transportTypes.${shipment.type}`) }}
                            </td>
                            <td class="data-table--right">
                                <button class="data-table__button" @click="open(shipment)"></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div ref="popup" role="modal" id="track" class="popup popup--fluid popup--active popup--detail" v-if="detail">
                <div class="popup__container" ref="container">
                    <button class="popup__close" @click="close"></button>

                    <PopupRecurringShipmentForm :shipment="detail"></PopupRecurringShipmentForm>
                </div>
            </div>

            <div class="card__footer" v-if="initialized && shipments.length > 0">
                <Pagination
                    :page="page"
                    :per-page="perPage"
                    :total="total"
                    :initialized="initialized"
                    :updating="updating"

                    @pageChanged="page = $event"
                    @perPageChanged="perPage = $event"
                ></Pagination>
            </div>

            <div v-if="initialized && shipments.length <= 0">
                {{ $t('recurringShipments.empty') }}
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import { createNamespacedHelpers } from 'vuex';
import { dateTimeFormat, dateFormat } from '@/services/date-formatter';

import Pagination from '@/components/Pagination.vue';
import RecurringShipmentFilter from '@/components/RecurringShipmentFilter.vue';
import TableSortingField from '@/components/TableSortingField.vue';
import Export from '@/components/Export.vue';
import RecurringShipmentAddressList from '@/components/RecurringShipmentAddressList.vue';
import PopupRecurringShipmentForm from '@/components/PopupRecurringShipmentForm.vue';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('recurring');

export default {
    name: 'RecurringShipments',

    components: {
        RecurringShipmentAddressList,
        PopupRecurringShipmentForm,
        RecurringShipmentFilter,
        TableSortingField,
        Pagination,
        Export,
    },

    data: () => ({
        perPage: 20,
        page: 0,

        filter: {},
        sorting: {
            field: 'sender',
            direction: false,
        },

        exportShipments: [],

        detail: null,
    }),

    metaInfo() {
        return {
            title: this.$t('recurringShipments.title'),
        };
    },

    computed: {
        ...mapGetters([
            'total',
            'initialized',
            'updating',
        ]),

        ...mapState([
            'shipments',
        ]),
    },

    methods: {
        dateTimeFormat,
        dateFormat,

        async fetch() {
            await this.fetchShipments({
                count: this.perPage,
                offset: this.page * this.perPage,
                filters: this.filter,
                sorting: {
                    field: this.sorting.field,
                    direction: this.sorting.direction ? 'desc' : 'asc',
                },
            });
        },

        open(shipment) {
            this.detail = shipment;

            $('html').addClass('html--fixed');
            $('body').addClass('body--fixed');
        },

        close() {
            this.detail = null;

            $('html').removeClass('html--fixed');
            $('body').removeClass('body--fixed');
        },

        ...mapActions([
            'fetchShipments',
        ]),
    },

    watch: {
        perPage() {
            this.exportShipments = [];
            this.page = 0;
            this.fetch();
        },

        filter: {
            deep: true,
            handler() {
                this.exportShipments = [];
                this.page = 0;
                this.fetch();
            },
        },

        sorting: {
            deep: true,
            handler() {
                this.exportShipments = [];
                this.page = 0;
                this.fetch();
            },
        },

        page() {
            this.exportShipments = [];
            this.fetch();
        },
    },

    mounted() {
        this.$nextTick(() => {
            $(document).on('keyup', (event) => {
                if (event.keyCode === 27) {
                    this.close();
                }
            });
        });
    },
};
</script>
