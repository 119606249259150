<template>
    <div class="order-address">
        <ValidationProvider v-show="selectOrCreateInvoiceAddress" v-slot="v" rules="" tag="div" mode="aggressive">
            <div class="form-field form-field--direct-evaluation" :class="v.classes">
                <AddressSelect v-model="address.saved" @input="savedSelected" @optionsReady="setStandardAddress" :type="isBillingAddress ? 'billing' : 'shipping'" />

                <span class="form-field__label">{{ $t('address.saved') }}*</span>
                <span class="form-field__error">{{ v.errors[0] }}</span>
            </div>
        </ValidationProvider>

        <ValidationObserver>
            <ul class="radio-group">
                <li class="radio-group__item">
                    <input :id="name + '-1'" v-model="address.type" type="radio" :name="name" value="company" :disabled="!isEditable">
                    <label :for="name + '-1'">{{ $t('address.company') }}</label>
                </li>
                <li class="radio-group__item">
                    <input :id="name + '-2'" v-model="address.type" type="radio" :name="name" value="male" :disabled="!isEditable">
                    <label :for="name + '-2'">{{ $t('address.male') }}</label>
                </li>
                <li class="radio-group__item">
                    <input :id="name + '-3'" v-model="address.type" type="radio" :name="name" value="female" :disabled="!isEditable">
                    <label :for="name + '-3'">{{ $t('address.female') }}</label>
                </li>
            </ul>

            <div v-if="address.type === 'company'">
                <ValidationProvider v-slot="v" rules="required" tag="div">
                    <div class="form-field" :class="v.classes">
                        <input v-model="address.name" :disabled="!isEditable">

                        <span class="form-field__label">{{ $t('address.company') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="v" :rules="{ required: !isBillingAddress }" tag="div">
                    <div class="form-field" :class="v.classes">
                        <input v-model="address.contact">

                        <span class="form-field__label">{{ $t('address.contact') }}<span v-if="!isBillingAddress">*</span></span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>
            <div v-else>
                <ValidationProvider v-slot="v" rules="required" tag="div">
                    <div class="form-field" :class="v.classes">
                        <input v-model="address.name" :disabled="!isEditable">

                        <span class="form-field__label">{{ $t('address.names') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>

            <ValidationProvider v-slot="v" rules="required" tag="div">
                <div class="form-field" :class="v.classes">
                    <AutosizeInput v-model="address.street" :disabled="!isEditable" class="textarea--autosize"></AutosizeInput>

                    <span class="form-field__label">{{ $t('address.street') }}*</span>
                    <span class="form-field__error">{{ v.errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div v-if="isBillingAddress">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-8">
                            <!-- Zip -->
                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="address.zip" :disabled="!isEditable">

                                    <span class="form-field__label">{{ $t('form.address.zip') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="cell small-16">
                            <!-- Location -->
                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="address.location" :disabled="!isEditable">

                                    <span class="form-field__label">{{ $t('form.address.locationString') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <ValidationProvider v-slot="v" rules="required" tag="div" mode="aggressive">
                    <div class="form-field form-field--direct-evaluation" :class="v.classes">
                        <LocationSelect v-model="address.locationObject" :disabled="!isLocationEditable" />
                        <div class="form-field form-field__intro" v-if="inactiveZipWarning">{{ $t('form.address.inactiveZipWarning') }}</div>
                        <span class="form-field__label">{{ $t('form.address.location') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>

            <ValidationProvider v-slot="v" :rules="{ required: !isBillingAddress }" tag="div">
                <div class="form-field" :class="v.classes">
                    <input v-model="address.phone">

                    <span class="form-field__label">{{ $t('address.phone') }}<span v-if="!isBillingAddress">*</span></span>
                    <span class="form-field__error">{{ v.errors[0] }}</span>
                </div>
            </ValidationProvider>

            <ul v-show="selectOrCreateInvoiceAddress" class="checkbox-group checkbox-group--no-margin">
                <li class="checkbox-group__item">
                    <div class="checkbox-group__input">
                        <ValidationProvider v-slot="v" :rules="{ required: { allowFalse: true } }">
                            <input :id="name + '-save'" v-model="address.save" type="checkbox" :class="v.classes" :disabled="saveDisabled">
                            <label :for="name + '-save'">
                                {{ $t('address.save') }}
                            </label>
                            <small class="checkbox-group__help">{{ $t('address.saveInfo') }}</small>
                        </ValidationProvider>
                    </div>
                </li>
            </ul>
        </ValidationObserver>
    </div>
</template>

<script>
import $ from 'jquery';
import { fetchLocationData } from '@/services/address-transformer';

import LocationSelect from '@/components/LocationSelect.vue';
import AddressSelect from '@/components/AddressSelect.vue';
import { createNamespacedHelpers } from 'vuex';
import AutosizeInput from '@/components/AutosizeInput.vue';

const { mapGetters } = createNamespacedHelpers('order');
const { mapActions: addressMapActions } = createNamespacedHelpers('address');

export default {
    name: 'OrderAddress',
    components: { AutosizeInput, AddressSelect, LocationSelect },
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        isBillingAddress: {
            type: Boolean,
            required: false,
            default: false,
        },
        loadFromStoredForm: {
            type: Boolean,
            required: false,
            default: false,
        },
        loadFromArchive: {
            type: Boolean,
            required: false,
            default: false,
        },
        shipment: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        selectOrCreateInvoiceAddress: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    data: () => ({
        address: {
            saved: null,
            label: '',
            id: null,
            type: 'company',
            contact: '',
            name: '',
            street: '',
            zip: '',
            location: '',
            locationId: null,
            locationObject: null,
            phone: '',

            // Save after submit
            save: false,

            // Flags
            standardBillingAddress: false,
            standardSenderAddress: false,
            standardReceiverAddress: false,
            isBillingAddress: false,
        },
        inactiveZipWarning: false,
    }),

    computed: {
        isEditable() {
            return this.address.saved === null;
        },

        isLocationEditable() {
            return this.address.saved === null || this.address.locationId === null;
        },

        saveDisabled() {
            return this.address.id && this.isLocationEditable;
        },
    },

    watch: {
        address: {
            handler(value) {
                this.$emit('input', value);
            },
            deep: true,
        },

        'address.type': function typeWatcher(value) {
            if (value === 'company') {
                this.address.firstname = '';
                this.address.lastname = '';
            } else {
                // Do not reset names field, this will be used for displaying
                // first and lastname. #91
                // this.address.name = '';
            }

            $(document).trigger('value-changed');
        },

        'address.locationObject': function locationObjectWatcher(value) {
            if (!value) {
                return;
            }

            this.inactiveZipWarning = false;
            this.address.location = value.name;
            this.address.zip = value.code;
        },

        shipment() {
            if (!this.loadFromArchive) {
                return;
            }

            let id = null;
            const shipmentData = {
                name: '',
                contact: '',
                street: '',
                phone: '',
            };
            if (this.name === 'sender') {
                id = this.shipment.from[0].kontaktadresse_id;
                shipmentData.name = this.shipment.from[0].name;
                shipmentData.contact = this.shipment.from[0].contact;
                shipmentData.street = this.shipment.from[0].address;
                shipmentData.phone = this.shipment.from[0].phone;
            } else if (this.name === 'receiver') {
                id = this.shipment.to[0].kontaktadresse_id;
                shipmentData.name = this.shipment.to[0].name;
                shipmentData.contact = this.shipment.to[0].contact;
                shipmentData.street = this.shipment.to[0].address;
                shipmentData.phone = this.shipment.to[0].phone;
            }

            if (id) {
                this.fetchContactAddress(id)
                    .then((address) => {
                        const enrichedAddress = address;
                        enrichedAddress.name = shipmentData.name;
                        enrichedAddress.contact = shipmentData.contact;
                        enrichedAddress.street = shipmentData.street;
                        enrichedAddress.phone = shipmentData.phone;

                        this.savedSelected(enrichedAddress);
                    })
                    .catch(() => {
                    //
                    });
            } else {
                this.address.name = shipmentData.name;
                this.address.contact = shipmentData.contact;
                this.address.street = shipmentData.street;
                this.address.phone = shipmentData.phone;
            }
        },
    },

    mounted() {
        if (this.loadFromArchive) {
            return;
        }

        const storedForm = this.storedForm();

        if (!storedForm[this.name]) {
            return;
        }

        this.savedSelected(storedForm[this.name]);
    },

    methods: {
        getFlag(type) {
            if (type === 'sender') {
                return 'standardSenderAddress';
            }

            if (type === 'receiver') {
                return 'standardReceiverAddress';
            }

            if (type === 'invoiceAddress') {
                return 'standardBillingAddress';
            }

            return undefined;
        },

        setStandardAddress(collection) {
            // Do not set from standard address pool if it should load from the archived shipment
            if (this.loadFromArchive) {
                return;
            }

            // Do not set from standard address pool if it should load from the stored form
            if (this.loadFromStoredForm) {
                return;
            }

            // Search for standard address if no value is set already
            const flag = this.getFlag(this.name);

            // Only set if the type is known
            if (flag === undefined) {
                return;
            }

            for (let i = 0; i < collection.length; i += 1) {
                const address = collection[i];
                const condition = address[flag];

                if (condition) {
                    this.savedSelected(address);

                    // Break out of the loop
                    break;
                }
            }
        },

        savedSelected(value) {
            this.inactiveZipWarning = false;

            if (value === null) {
                this.address.id = null;
                this.address.saved = null;
                this.address.locationId = null;
                this.address.standardBillingAddress = false;
                this.address.standardSenderAddress = false;
                this.address.standardReceiverAddress = false;
                this.address.isBillingAddress = this.isBillingAddress;

                return;
            }

            // Overwrite address
            this.address.saved = value;
            this.address.id = value.id;
            this.address.label = value.label;
            this.address.type = value.type;
            this.address.street = value.street;
            this.address.name = value.name;
            this.address.contact = value.contact;
            this.address.phone = value.phone;
            this.address.zip = value.zip;
            this.address.location = value.location;
            this.address.standardBillingAddress = value.standardBillingAddress;
            this.address.standardSenderAddress = value.standardSenderAddress;
            this.address.standardReceiverAddress = value.standardReceiverAddress;
            this.address.isBillingAddress = this.isBillingAddress;

            // Reset save flag
            this.address.save = false;

            if (value.locationId) {
                fetchLocationData(value, this.$i18n.locale).then((location) => {
                    this.address.locationId = location.id;
                    this.address.locationObject = location;

                    if (!location.is_active && !this.address.isBillingAddress) {
                        this.inactiveZipWarning = true;

                        this.address.id = null;
                        this.address.locationId = null;
                        this.address.locationObject = null;
                    }

                    $(document).trigger('value-changed');
                });
            } else {
                this.inactiveZipWarning = true;

                this.address.locationId = null;
                this.address.locationObject = null;
            }

            $(document).trigger('value-changed');
        },

        ...mapGetters([
            'storedForm',
        ]),

        ...addressMapActions([
            'fetchContactAddress',
        ]),
    },
};
</script>
