<template>
    <div class="widget savings-widget">
        <div class="card">
            <i18n path="widget.savings.title" tag="h3" class="savings-widget__sub-title">
                <template v-slot:co2>CO<sub>2</sub></template>
            </i18n>

            <div v-if="initialized">
                <i18n path="widget.savings.text" tag="h2" class="savings-widget__title">
                    <template v-slot:savings><span class="nbsp">{{ savings }}kg CO<sub>2</sub></span></template>
                </i18n>
            </div>

            <i18n path="widget.savings.link" tag="a" class="savings-widget__link" :href="$t('links.co2')" target="_blank">
                <template v-slot:co2>CO<sub>2</sub></template>
            </i18n>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios';

export default {
    name: 'SavingsWidget',

    data: () => ({
        initialized: false,
        savings: undefined,
        savingsRaw: undefined,
    }),

    watch: {
        '$i18n.locale': function i18nWatcher() {
            this.savings = parseInt(this.savingsRaw, 10).toLocaleString(`${this.$i18n.locale}-CH`);
        },
    },

    mounted() {
        this.initialized = false;

        axios({
            url: '/api/v2/saved-emission',
            method: 'GET',
        })
            .then((response) => {
                let { savedEmissionThisYear } = response.data;

                savedEmissionThisYear = Math.max(0, savedEmissionThisYear);

                this.savingsRaw = savedEmissionThisYear;
                this.savings = parseInt(savedEmissionThisYear, 10).toLocaleString(`${this.$i18n.locale}-CH`);
                this.initialized = true;
            });
    },
};
</script>
