export default function getEmptyColiList() {
    return {
        count: 1,
        colis: [{
            notes: '',
            height: '',
            width: '',
            length: '',
            weight: '',
        }],
        notes: '',
        palette: false,
    };
}
