<template>
    <component :is="layout"/>
</template>

<script>
import Default from '@/layouts/Default.vue';

export default {
    name: 'Layout',

    data: () => ({
        layout: Default,
    }),

    watch: {
        $route: {
            immediate: true,
            handler(route) {
                const newLayout = route.meta.layout;

                if (!newLayout) {
                    this.layout = Default;
                    return;
                }

                this.layout = newLayout;
            },
        },
    },
};
</script>
