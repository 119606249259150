<template>
    <div class="shipment-export text-right">

        <div v-if="selectedShipments.length > 0">
            <button class="button button--arrow" :class="{ 'button--loading': loading }" :disabled="loading || !enabled" @click="download()">
                {{ $t('export.button') }}
            </button>
        </div>
        <div v-else>
            <template v-if="numberOfResults <= 1000">
                <button class="button button--arrow" :class="{ 'button--loading': loading }" :disabled="numberOfResults <= 0 || loading || !enabled" @click="download()">
                    {{ $t('export.buttonAll') }}
                </button>
            </template>
            <template v-else>
                <button class="button button--arrow" disabled>
                    {{ $t('export.buttonAll') }}
                </button>

                <p>
                    <small class="display-block text-right" v-html="$t('export.hint')"></small>
                </p>
            </template>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios';
import qs from 'qs';
import { getSupportedLocale } from '@/config/i18n';

export default {
    name: 'Export',

    props: {
        url: {
            type: String,
            required: true,
        },

        enabled: {
            type: Boolean,
            required: true,
        },

        filter: {
            type: Object,
            required: true,
        },
        sorting: {
            type: Object,
            required: true,
        },
        numberOfResults: {
            type: Number,
            required: true,
        },
        selectedShipments: {
            type: Array,
            required: false,
            default: () => ([]),
        },
    },

    data: () => ({
        loading: false,
    }),

    methods: {
        download() {
            this.loading = true;

            const { filter, sorting } = this;

            if (this.selectedShipments.length > 0) {
                filter.orderList = this.selectedShipments.join(',');
            }

            const locale = getSupportedLocale(this.$i18n.locale);

            axios({
                url: this.url,
                method: 'GET',
                params: {
                    sorting,
                    filter,
                    locale,
                },
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                responseType: 'arraybuffer',
                paramsSerializer: (params) => qs.stringify(params),
            }).then((fileResponse) => {
                if (!fileResponse) {
                    return;
                }

                const fileData = fileResponse.data;

                this.forceDownload(fileData);
                this.loading = false;
            });
        },

        forceDownload(data) {
            // Force download by creating an invisible a tag and click on it
            const a = document.createElement('a');
            a.style.display = 'none';
            document.body.appendChild(a);

            a.href = window.URL.createObjectURL(
                new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }),
            );

            // a.setAttribute('target', '_blank');
            a.setAttribute('download', 'export.xlsx');
            a.click();

            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        },
    },
};
</script>
