<template>
    <div class="register-thank-you">
        <h1 class="login__main-title" v-html="$t('registerThankYou.mainTitle')"></h1>
        <div class="card">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-24 large-16 large-offset-4">
                        <div class="card__header">
                            <h1 class="card__title">{{ $t('registerThankYou.title') }}</h1>
                        </div>

                        <div class="card__body">
                            <p>{{ $t('registerThankYou.text') }}</p>

                            <router-link :to="{ name: 'Login' }" class="button button--arrow">{{ $t('registerThankYou.loginButton') }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="login__footer" v-html="$t('login.footer')"></div>
    </div>
</template>

<script>
export default {
    name: 'RegisterThankYou',

    metaInfo() {
        return {
            title: this.$t('registerThankYou.title'),
        };
    },
};
</script>
