<template>
    <div class="dashboard" v-if="user">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="cell small-24 medium-12">
                    <h1 class="dashboard__title">{{ $t('dashboard.title') }}</h1>
                </div>
                <div class="cell small-24 medium-12">
                    <div class="dashboard__action">
                        <button type="button" class="button button--small button--white" @click="sortingEnabled = true" v-if="!sortingEnabled">{{ $t('dashboard.sortButton') }}</button>
                        <button type="button" class="button button--small" v-if="sortingEnabled" @click="saveSorting">{{ $t('dashboard.saveButton') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="cell small-24 large-12">
                    <draggable handle=".dashboard__action-move" :list="listOne" group="widgets" :disabled="!sortingEnabled" class="dashboard__column" :class="{ 'dashboard__column--sorting-enabled': sortingEnabled }">
                        <div class="dashboard__sortable" v-for="(element, index) in listOne" :key="index" :class="{ 'dashboard__sortable--hidden': isHidden(element.name) }">
                            <div class="dashboard__actions" v-if="sortingEnabled">
                                <div class="dashboard__action-move"></div>
                                <div class="dashboard__action-hide" :class="{ 'dashboard__action-hide--is-hidden': hidden[element.name] }" @click="hidden[element.name] = !hidden[element.name]"></div>
                            </div>

                            <component v-bind:is="element.component"></component>
                        </div>
                    </draggable>
                </div>
                <div class="cell small-24 large-12">
                    <draggable handle=".dashboard__action-move" :list="listTwo" group="widgets" :disabled="!sortingEnabled" class="dashboard__column" :class="{ 'dashboard__column--sorting-enabled': sortingEnabled }">
                        <div class="dashboard__sortable" v-for="(element, index) in listTwo" :key="index" :class="{ 'dashboard__sortable--hidden': isHidden(element.name) }">
                            <div class="dashboard__actions" v-if="sortingEnabled">
                                <div class="dashboard__action-move"></div>
                                <div class="dashboard__action-hide" :class="{ 'dashboard__action-hide--is-hidden': hidden[element.name] }" @click="hidden[element.name] = !hidden[element.name]"></div>
                            </div>

                            <component v-bind:is="element.component"></component>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import draggable from 'vuedraggable';
import AddressesWidget from '@/components/AddressesWidget.vue';
import ArchiveWidget from '@/components/ArchiveWidget.vue';
import SavingsWidget from '@/components/SavingsWidget.vue';
import ActiveWidget from '@/components/ActiveWidget.vue';
import QuickLinks from '@/components/QuickLinks.vue';
import EmployeeWidget from '@/components/EmployeeWidget.vue';
import PriceCalculatorWidget from '@/components/PriceCalculatorWidget.vue';
import ServiceInformationWidget from '@/components/ServiceInformationWidget.vue';
import TrackWidget from '@/components/TrackWidget.vue';
import SwitchUserWidget from '@/components/SwitchUserWidget.vue';
import RecurringShipmentWidget from '@/components/RecurringShipmentWidget.vue';

const { mapGetters } = createNamespacedHelpers('security');
const localStorageKey = 'dashboardLists-v3';

export default {
    name: 'Dashboard',

    metaInfo: {
        title: 'Dashboard',
    },

    components: {
        draggable,

        // Widgets
        ServiceInformationWidget,
        PriceCalculatorWidget,
        EmployeeWidget,
        TrackWidget,
        QuickLinks,
        ActiveWidget,
        SavingsWidget,
        ArchiveWidget,
        AddressesWidget,
        SwitchUserWidget,
        RecurringShipmentWidget,
    },

    data: () => ({
        components: {
            serviceInformation: ServiceInformationWidget,
            active: ActiveWidget,
            archive: ArchiveWidget,
            track: TrackWidget,
            addresses: AddressesWidget,
            savings: SavingsWidget,
            quickLinks: QuickLinks,
            priceCalculator: PriceCalculatorWidget,
            employee: EmployeeWidget,
            switchUser: SwitchUserWidget,
            recurringShipments: RecurringShipmentWidget,
        },

        listOne: [
            { name: 'serviceInformation', component: ServiceInformationWidget },
            { name: 'switchUser', component: SwitchUserWidget },
            { name: 'active', component: ActiveWidget },
            { name: 'archive', component: ArchiveWidget },
            { name: 'track', component: TrackWidget },
            { name: 'addresses', component: AddressesWidget },
        ],
        listTwo: [
            { name: 'savings', component: SavingsWidget },
            { name: 'quickLinks', component: QuickLinks },
            { name: 'priceCalculator', component: PriceCalculatorWidget },
            { name: 'employee', component: EmployeeWidget },
            { name: 'recurringShipments', component: RecurringShipmentWidget },
        ],
        hidden: {
            serviceInformation: false,
            active: false,
            archive: false,
            track: false,
            addresses: false,
            savings: false,
            quickLinks: false,
            priceCalculator: false,
            employee: false,
            recurringShipments: false,
        },

        sortingEnabled: false,
    }),

    computed: {
        ...mapGetters([
            'user',
        ]),
    },

    methods: {
        isHidden(key) {
            return this.hidden[key];
        },

        saveSorting() {
            localStorage.setItem(localStorageKey, JSON.stringify({
                listOne: this.listOne.map((item) => ({ name: item.name, hidden: this.hidden[item.name] })),
                listTwo: this.listTwo.map((item) => ({ name: item.name, hidden: this.hidden[item.name] })),
            }));

            this.sortingEnabled = false;
        },
    },

    mounted() {
        const lists = localStorage.getItem(localStorageKey);

        if (!lists) {
            return;
        }

        const listsObject = JSON.parse(lists);

        if (!listsObject) {
            return;
        }

        const listOne = [];
        const listTwo = [];

        for (let i = 0; i < listsObject.listOne.length; i += 1) {
            const element = listsObject.listOne[i];
            const key = element.name;

            listOne.push({ name: key, component: this.components[key] });
            this.hidden[key] = element.hidden;
        }

        for (let i = 0; i < listsObject.listTwo.length; i += 1) {
            const element = listsObject.listTwo[i];
            const key = element.name;

            listTwo.push({ name: key, component: this.components[key] });
            this.hidden[key] = element.hidden;
        }

        this.listOne = listOne;
        this.listTwo = listTwo;

        // TODO: Add all widgets that are not saved right now.
    },
};
</script>
