<template>
    <div class="create-address">
        <div class="card">

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 small-24 medium-24 large-24 xlarge-24 xxlarge-16 xxlarge-offset-4">
                        <div class="card__header">
                            <div class="grid-container">
                                <div class="grid-x grid-margin-x">
                                    <div class="cell small-24 medium-12">
                                        <h1 class="card__title">{{ $t('createAddress.title') }}</h1>
                                    </div>
                                    <div class="cell small-24 medium-12 align-right">
                                        <router-link class="button button--white" :to="{ name: 'AddressBook' }">{{ $t('global.back') }}</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AddressForm @change="submit" :loading="loading"></AddressForm>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddressForm from '@/components/AddressForm.vue';
import { createAddress } from '@/services/address-transformer';
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('order');

export default {
    name: 'CreateAddress',
    components: {
        AddressForm,
    },

    data: () => ({
        loading: false,
    }),

    metaInfo() {
        return {
            title: this.$t('createAddress.title'),
        };
    },

    methods: {
        submit(address) {
            this.loading = true;

            createAddress(address)
                .then(() => {
                    this.resetForm().then(() => {
                        this.loading = false;
                        this.$router.push({ name: 'AddressBook' });
                    });
                });
        },

        ...mapActions({
            resetForm: 'clear',
        }),
    },
};
</script>
