<template>
    <div class="order">
        <div class="card">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 small-24 medium-24 large-24 xlarge-24 xxlarge-16 xxlarge-offset-4">
                        <div class="card__header">
                            <h1 class="card__title">{{ $t('orderConfirm.title') }}</h1>
                        </div>

                        <div class="card__body">
                            <OrderConfirmForm></OrderConfirmForm>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OrderConfirmForm from '@/components/OrderConfirmForm.vue';

export default {
    name: 'OrderConfirm',

    components: {
        OrderConfirmForm,
    },

    metaInfo() {
        return {
            title: this.$t('orderConfirm.title'),
        };
    },
};
</script>
