<template>
    <div class="widget">
        <div class="card">
            <h2 class="card__title">{{ $t('widget.tracking.title') }}</h2>

            <TrackShipment :reduced="true" />
        </div>
    </div>
</template>

<script>
import TrackShipment from '@/components/TrackShipment.vue';

export default {
    components: {
        TrackShipment,
    },
};
</script>
