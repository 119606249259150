<template>
    <div class="address-form">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)" v-if="address">

                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-12">
                            <ul class="checkbox-group checkbox-group--vertical">
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <ValidationProvider v-slot="v" :rules="{ required: { allowFalse: true } }">
                                            <input id="standardSenderAddress" v-model="address.standardSenderAddress" type="checkbox"
                                                   :class="v.classes">
                                            <label for="standardSenderAddress">{{ $t('form.address.standardSenderAddress') }}</label>
                                        </ValidationProvider>
                                    </div>
                                </li>
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <ValidationProvider v-slot="v" :rules="{ required: { allowFalse: true } }">
                                            <input id="standardReceiverAddress" v-model="address.standardReceiverAddress"
                                                   type="checkbox" :class="v.classes">
                                            <label for="standardReceiverAddress">{{
                                                $t('form.address.standardReceiverAddress')
                                            }}</label>
                                        </ValidationProvider>
                                    </div>
                                </li>
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <ValidationProvider v-slot="v" :rules="{ required: { allowFalse: true } }">
                                            <input id="standardBillingAddress" v-model="address.standardBillingAddress" type="checkbox"
                                                   :class="v.classes" :disabled="!user.organization.change_default_invoice_address_allowed || !user.organization.select_or_create_invoice_address_allowed">
                                            <label for="standardBillingAddress">{{ $t('form.address.standardBillingAddress') }}</label>
                                        </ValidationProvider>
                                    </div>
                                </li>
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <ValidationProvider v-slot="v" :rules="{ required: { allowFalse: true } }">
                                            <input id="isBillingAddress" v-model="address.isBillingAddress" type="checkbox"
                                                   :class="v.classes" :disabled="billingAddressLocked || !user.organization.select_or_create_invoice_address_allowed">
                                            <label for="isBillingAddress">{{ $t('form.address.isBillingAddress') }}</label>
                                        </ValidationProvider>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="cell small-24 medium-12" v-html="$t('form.address.addressHint')">
                        </div>
                    </div>
                </div>

                <div v-if="!address.readonly && !(address.standardBillingAddress && !user.organization.change_default_invoice_address_allowed)">
                    <!-- Salutation -->
                    <ValidationProvider v-slot="v" rules="" tag="div">
                        <ul class="radio-group" :class="v.classes">
                            <li class="radio-group__item">
                                <input id="address[salutation][company]" type="radio" v-model="address.type" value="company">
                                <label for="address[salutation][company]">{{ $t('form.address.salutation.company') }}</label>
                            </li>
                            <li class="radio-group__item">
                                <input id="address[salutation][male]" type="radio" v-model="address.type" value="male">
                                <label for="address[salutation][male]">{{ $t('form.address.salutation.male') }}</label>
                            </li>
                            <li class="radio-group__item">
                                <input id="address[salutation][female]" type="radio" v-model="address.type" value="female">
                                <label for="address[salutation][female]">{{ $t('form.address.salutation.female') }}</label>
                            </li>
                        </ul>
                    </ValidationProvider>

                    <!-- Name -->
                    <div v-if="address.type !== 'company'">
                        <div class="grid-container">
                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24">
                                    <ValidationProvider v-slot="v" rules="required" tag="div">
                                        <div class="form-field" :class="v.classes">
                                            <input v-model="address.name">

                                            <span class="form-field__label">{{ $t('form.address.name') }}*</span>
                                            <span class="form-field__error">{{ v.errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="grid-container">
                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24">
                                    <ValidationProvider v-slot="v" rules="required" tag="div">
                                        <div class="form-field" :class="v.classes">
                                            <input v-model="address.name">

                                            <span class="form-field__label">{{ $t('form.address.company') }}*</span>
                                            <span class="form-field__error">{{ v.errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid-container">
                        <div class="grid-x grid-margin-x">
                            <div class="cell small-24 medium-12">
                                <!-- Street -->
                                <ValidationProvider v-slot="v" rules="required" tag="div">
                                    <div class="form-field" :class="v.classes">
                                        <AutosizeInput v-model="address.street" class="textarea--autosize" :min-height="40"></AutosizeInput>

                                        <span class="form-field__label">{{ $t('form.address.street') }}*</span>
                                        <span class="form-field__error">{{ v.errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="cell small-24 medium-12">
                                <!-- Zip -->
                                <div v-if="address.isBillingAddress && !(address.standardSenderAddress || address.standardReceiverAddress)">
                                    <div class="grid-container">
                                        <div class="grid-x grid-margin-x">
                                            <div class="cell small-8">
                                                <!-- Zip -->
                                                <ValidationProvider v-slot="v" rules="required" tag="div">
                                                    <div class="form-field" :class="v.classes">
                                                        <input v-model="address.zip">

                                                        <span class="form-field__label">{{ $t('form.address.zip') }}*</span>
                                                        <span class="form-field__error">{{ v.errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="cell small-16">
                                                <!-- Location -->
                                                <ValidationProvider v-slot="v" rules="required" tag="div">
                                                    <div class="form-field" :class="v.classes">
                                                        <input v-model="address.location">

                                                        <span class="form-field__label">{{ $t('form.address.locationString') }}*</span>
                                                        <span class="form-field__error">{{ v.errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <ValidationProvider v-slot="v" rules="required" tag="div" mode="aggressive">
                                        <div class="form-field form-field--direct-evaluation" :class="v.classes">
                                            <LocationSelect v-model="address.locationObject" />

                                            <span class="form-field__label">{{ $t('form.address.location') }}*</span>
                                            <span class="form-field__error">{{ v.errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid-container">
                        <div class="grid-x grid-margin-x">
                            <div class="cell small-24 medium-12">
                                <!-- Contact -->
                                <ValidationProvider v-slot="v" rules="" tag="div">
                                    <div class="form-field" :class="v.classes">
                                        <input v-model="address.contact">

                                        <span class="form-field__label">{{ $t('form.address.contact') }}</span>
                                        <span class="form-field__error">{{ v.errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="cell small-24 medium-12">
                                <!-- Phone -->
                                <ValidationProvider v-slot="v" rules="" tag="div">
                                    <div class="form-field" :class="v.classes">
                                        <input v-model="address.phone">

                                        <span class="form-field__label">{{ $t('form.address.phone') }}</span>
                                        <span class="form-field__error">{{ v.errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>

                <button class="button" :class="{ 'button--loading': loading }" type="submit" :disabled="loading">{{ $t(`form.address.${ address.id ? 'edit' : 'create' }`) }}</button>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import $ from 'jquery';
import LocationSelect from '@/components/LocationSelect.vue';
import AutosizeInput from '@/components/AutosizeInput.vue';
import { mapGetters as mapGlobalGetters } from 'vuex';

export default {
    name: 'AddressForm',
    components: { AutosizeInput, LocationSelect },
    props: {
        edit: {
            type: Object,
            required: false,
            default: () => ({
                id: null,
                type: 'company',
                name: '',
                street: '',
                zip: '',
                location: '',
                locationObject: null,
                contact: '',
                phone: '',

                standardSenderAddress: false,
                standardReceiverAddress: false,
                standardBillingAddress: false,
                isBillingAddress: false,
            }),
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        address: null,
        billingAddressLocked: false,
    }),

    computed: {
        ...mapGlobalGetters('security', [
            'user',
        ]),
    },

    watch: {
        address: {
            handler() {
                this.$nextTick(() => {
                    $(document).trigger('value-changed');
                });
            },
            deep: true,
        },

        'address.standardBillingAddress': {
            handler(value) {
                if (!value) {
                    this.billingAddressLocked = false;
                    return;
                }

                this.address.isBillingAddress = true;
                this.billingAddressLocked = true;
            },
        },

        'address.isBillingAddress': {
            handler() {
                $(document).trigger('value-changed');
            },
        },

        'address.locationObject': {
            handler(value) {
                if (!value) {
                    return;
                }

                this.address.location = value.name;
                this.address.zip = value.code;
            },
        },
    },

    methods: {
        submit() {
            this.$emit('change', this.address);
        },
    },

    mounted() {
        this.address = { ...this.edit };

        this.$nextTick(() => {
            $(document).trigger('value-changed');
        });
    },
};
</script>
