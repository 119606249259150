<template>
    <div v-if="updateExists" class="new-version-message">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="cell small-24 medium-20 medium-offset-2">
                    <div class="new-version-message__text-container">
                        <div class="new-version-message__title">{{ $t('newVersionMessage.title') }}</div>
                        <div class="new-version-message__description">{{ $t('newVersionMessage.description') }}</div>

                        <button class="button button--small button--white-transparent" @click.prevent="update">{{ $t('newVersionMessage.button') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import { mapGetters } from 'vuex';

// Test 2
export default {
    data: () => ({
        worker: null,
        updateExists: false,
        refreshing: false,
    }),

    mounted() {
        document.addEventListener('service-worker.updated', this.onUpdated);
    },

    methods: {
        onUpdated(event) {
            // No worker data found
            if (!event.detail) {
                return;
            }

            this.worker = event.detail;
            this.updateExists = true;

            $('html, body').animate({ scrollTop: 0 }, 250);

            if (!this.isAuthenticated) {
                this.update();
            }
        },

        update() {
            this.updateExists = false;

            if (!this.worker) {
                return;
            }

            // Update client
            this.worker.postMessage({ type: 'SKIP_WAITING' });
        },
    },

    computed: {
        ...mapGetters('security', [
            'isAuthenticated',
        ]),
    },
};
</script>
