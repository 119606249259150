<template>
    <div class="offer">
        <div class="card">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 small-24 medium-24 large-24 xlarge-24 xxlarge-16 xxlarge-offset-4">
                        <div class="card__header">
                            <h1 class="card__title">{{ $t('offerThankYou.title') }}</h1>
                        </div>

                        <div class="card__body">
                            {{ $t('offerThankYou.text') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BlogPosts />
    </div>
</template>

<script>
import BlogPosts from '@/components/BlogPosts.vue';

export default {
    name: 'OfferThankYou',
    components: { BlogPosts },
    metaInfo() {
        return {
            title: this.$t('offerThankYou.title'),
        };
    },
};
</script>
