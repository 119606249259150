<template>
    <div class="popup-recurring-shipment-form">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <ValidationProvider v-slot="v" rules="">
                    <div class="form-field form-field--direct-evaluation" :class="v.classes">
                        <input v-model="customerName" type="text" :disabled="loading">

                        <span class="form-field__label">{{ $t('popupRecurringShipmentForm.customerName') }}</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <div class="popup-shipment-form__confirmation" v-if="showConfirmation" v-html="$t('popupRecurringShipmentForm.confirmation')"></div>

                <button type="submit" class="button button--small button--white" :class="{ 'button--loading': loading }" :disabled="loading">{{ $t('popupRecurringShipmentForm.button') }}</button>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import $ from 'jquery';
import axios from '@/config/axios';

export default {
    name: 'PopupRecurringShipmentForm',
    props: {
        shipment: Object,
    },

    data: () => ({
        customerName: null,

        loading: false,
        showConfirmation: false,
    }),

    methods: {
        submit() {
            this.showConfirmation = false;
            this.loading = true;

            axios({
                url: `/api/v2/standing-order/${this.shipment.key}/change-customer-name`,
                data: {
                    customerName: this.customerName,
                },
                method: 'POST',
            })
                .then(() => {
                    this.loading = false;
                    this.showConfirmation = true;

                    this.shipment.customerName = this.customerName;
                });
        },
    },

    mounted() {
        this.customerName = this.shipment.customerName;

        this.$nextTick(() => {
            $(document).trigger('value-changed');
        });
    },
};
</script>
