import $ from 'jquery';

const self = {
    init() {
        $(window).on('resize', self.resize);

        self.resize();
    },
    resetMobileNav() {
        const header = $('.header');
        const navigation = $('.navigation');
        const welcome = $('.welcome-message');

        if (header.hasClass('header--open')) {
            $('body, html').removeClass('mobile--open');
            header.removeClass('header--open');
            navigation.removeClass('navigation--open');
            welcome.removeClass('welcome-message--open');
        }
    },
    resize() {
        if (window.innerWidth >= 1200) {
            self.resetMobileNav();
        }
    },
};

export default self;
