import axios from '@/config/axios';
import { transform } from '@/services/profile-transformer';

export const AUTH_LOGOUT = '🔐 Logout';
export const AUTH_LOGIN = '🔐 Login';
export const AUTH_GET_PROFILE = '🔐 Get Profile';

export const AUTH_SET_USER = '🔐 Storing user';
export const AUTH_REQUEST = '🔐 Started authentication request';
export const AUTH_SUCCESS = '🔐✅ Successfully authenticated';
export const AUTH_ERROR = '🔐❌ Authentication attempt failed';
export const AUTH_CLEAR = '🔐 Clearing Tokens';

export default {
    namespaced: true,

    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        refreshToken: localStorage.getItem('refreshToken') || '',
        user: null,
    },

    getters: {
        isAuthenticated: (state) => !!state.token,
        authStatus: (state) => state.status,
        user: (state) => state.user,
    },

    actions: {
        [AUTH_LOGIN]: ({ commit }, data) => new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);

            const formData = new FormData();
            formData.append('_username', data.username);
            formData.append('_password', data.password);

            axios({
                url: '/api/v2/login_check',
                data: formData,
                method: 'POST',
            })
                .then((resp) => {
                    const { token } = resp.data;
                    const refreshToken = resp.data.refresh_token;

                    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                    commit(AUTH_SUCCESS, { token, refreshToken });
                    resolve(resp);
                })
                .catch((err) => {
                    commit(AUTH_ERROR);
                    commit(AUTH_CLEAR);

                    reject(err);
                });
        }),

        [AUTH_GET_PROFILE]: ({ commit }) => new Promise((resolve, reject) => {
            axios({
                url: '/api/v2/profile',
                method: 'GET',
            })
                .then((response) => {
                    const profile = transform(response.data);

                    commit(AUTH_SET_USER, profile);
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        }),

        [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve) => {
            commit(AUTH_CLEAR);
            resolve();
        }),
    },

    mutations: {
        [AUTH_REQUEST]: (state) => {
            state.status = 'loading';
        },

        [AUTH_SUCCESS]: (state, data) => {
            const { token, refreshToken } = data;

            state.status = 'success';
            state.token = token;
            state.refreshToken = refreshToken;

            localStorage.setItem('token', state.token);
            localStorage.setItem('refreshToken', state.refreshToken);
        },

        [AUTH_ERROR]: (state) => {
            state.status = 'error';
        },

        [AUTH_SET_USER]: (state, data) => {
            state.user = data;
        },

        [AUTH_CLEAR]: (state) => {
            state.token = '';
            state.refreshToken = '';

            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('switchUser');
        },
    },
};
