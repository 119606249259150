import axios from '@/config/axios';

export async function fetchLocationData(address, locale) {
    if (address.locationId === null) {
        return null;
    }

    return axios
        .get(`/api/v2/zip/${address.locationId}`)
        .then((response) => {
            const result = response.data;

            const key = `name_${locale}`;
            const name = result[key] || result.name_de;
            const label = `${result.code} ${name}`;

            return {
                id: result.id,
                label,
                name,
                code: result.code,
                is_active: result.is_active,
            };
        });
}

export function transform(dataPoint) {
    const address = dataPoint.kontaktadresse;

    let label = `${address.firma}`;

    if (address.kontakt) {
        label = `${label}, ${address.kontakt}`;
    }

    label = `${label}, ${address.addresse}, ${address.postleitzahl} ${address.ort}`;

    return {
        id: address.id,
        type: address.anrede ? address.anrede : 'company',
        name: address.firma,
        street: address.addresse,
        contact: address.kontakt,
        zip: address.postleitzahl,
        location: address.ort,
        locationId: address.plz,
        locationObject: null,
        phone: address.phone,

        label,

        standardSenderAddress: dataPoint.default_absender,
        standardReceiverAddress: dataPoint.default_empfaenger,
        standardBillingAddress: dataPoint.default_rechnungsadresse,
        isBillingAddress: dataPoint.default_rechnungsadresse ? true : dataPoint.istRechnungsadresse,

        readonly: dataPoint.readonly,
    };
}

export function reverseTransform(form, isBillingAddress) {
    let locationId = null;
    let postleitzahl = form.zip;
    let ort = form.location;

    const { locationObject } = form;

    if (locationObject) {
        locationId = locationObject.id;
        postleitzahl = locationObject.code;
        ort = locationObject.name;
    }

    return {
        anrede: form.type,
        firma: form.name,
        addresse: form.street,
        kontakt: form.contact,
        referenz: null,
        kontaktadresse_id: form.id,
        biller: isBillingAddress,
        phone: form.phone,
        added: false,

        // Zip/Location
        postleitzahl,
        ort,
        plz: locationId,
    };
}

export function createAddress(address) {
    let locationId = null;
    let postleitzahl = address.zip;
    let ort = address.location;

    const { locationObject } = address;

    if (locationObject && !(address.isBillingAddress && !(address.standardSenderAddress || address.standardReceiverAddress))) {
        locationId = locationObject.id;
        postleitzahl = locationObject.code;
        ort = locationObject.name;
    }

    return axios({
        url: '/api/v2/address',
        data: {
            default_rechnungsadresse: address.standardBillingAddress,
            default_absender: address.standardSenderAddress,
            default_empfaenger: address.standardReceiverAddress,
            ist_rechnungsadresse: address.isBillingAddress,
            kontaktadresse: {
                firma: address.name,
                addresse: address.street,
                kontakt: address.contact,
                postleitzahl,
                ort,
                phone: address.phone,
                anrede: address.type,
                plz: locationId,
            },
        },
        method: 'POST',
    });
}

export function editAddress(address) {
    let locationId = null;
    let postleitzahl = address.zip;
    let ort = address.location;

    const { locationObject } = address;

    if (locationObject && !(address.isBillingAddress && !(address.standardSenderAddress || address.standardReceiverAddress))) {
        locationId = locationObject.id;
        postleitzahl = locationObject.code;
        ort = locationObject.name;
    }

    return axios({
        url: `/api/v2/address/${address.id}`,
        data: {
            default_rechnungsadresse: address.standardBillingAddress,
            default_absender: address.standardSenderAddress,
            default_empfaenger: address.standardReceiverAddress,
            ist_rechnungsadresse: address.isBillingAddress,
            kontaktadresse: {
                id: address.id,
                firma: address.name,
                addresse: address.street,
                kontakt: address.contact,
                postleitzahl,
                ort,
                phone: address.phone,
                anrede: address.type,
                plz: locationId,
            },
        },
        method: 'POST',
    });
}

export function saveAddress(address) {
    if (address.saved !== null) {
        return editAddress(address);
    }

    return createAddress(address);
}
