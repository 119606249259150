<template>
    <nav id="nav" class="navigation">
        <div class="navigation__background">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-22 xlarge-offset-1">
                        <router-link :to="{ name: 'Dashboard' }" exact class="navigation__item" active-class="navigation__item--active">{{ $t('navigation.dashboard') }}</router-link>
                        <router-link :to="{ name: 'Active' }" class="navigation__item" active-class="navigation__item--active">{{ $t('navigation.active') }}</router-link>
                        <router-link :to="{ name: 'Order' }" class="navigation__item" active-class="navigation__item--active">{{ $t('navigation.order') }}</router-link>
                        <router-link :to="{ name: 'Offer' }" class="navigation__item" active-class="navigation__item--active">{{ $t('navigation.offer') }}</router-link>
                        <router-link :to="{ name: 'PriceCalculator' }" class="navigation__item" active-class="navigation__item--active">{{ $t('navigation.priceCalculator') }}</router-link>
                        <router-link :to="{ name: 'Archive' }" class="navigation__item" active-class="navigation__item--active">{{ $t('navigation.archive') }}</router-link>
                        <router-link :to="{ name: 'AddressBook' }" class="navigation__item" active-class="navigation__item--active">{{ $t('navigation.addressBook') }}</router-link>
                        <router-link :to="{ name: 'RecurringShipments' }" class="navigation__item" active-class="navigation__item--active">{{ $t('navigation.recurringOrders') }}</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="navigation__mobile">
            <Avatar />
            <TrackPopup />
            <LanguageSwitcher />
        </div>
    </nav>
</template>

<script>
import Avatar from '@/components/Avatar.vue';
import TrackPopup from '@/components/TrackPopup.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

export default {
    components: {
        Avatar,
        TrackPopup,
        LanguageSwitcher,
    },

    name: 'Navigation',
};
</script>
