import qs from 'qs';
import axios from '@/config/axios';
import { transform } from '@/services/shipment-transformer';

export default {
    namespaced: true,
    state: {
        // Shipments
        initialized: false,
        updating: false,
        total: 0,
        shipments: [],
    },

    getters: {
        total: (state) => state.total,
        initialized: (state) => state.initialized,
        updating: (state) => state.updating,
    },

    actions: {
        fetchShipments: async ({ state }, {
            count,
            offset,
            sorting,
            filters,
        }) => new Promise((resolve, reject) => {
            state.updating = true;

            const filter = {};
            const keys = Object.keys(filters);

            keys.forEach((key) => {
                if (filters[key]) {
                    filter[key] = filters[key];
                }
            });

            axios({
                url: '/api/v2/shipments/active',
                method: 'GET',
                params: {
                    count,
                    offset,
                    sorting,
                    filter,
                },
                paramsSerializer: (params) => qs.stringify(params),
            })
                .then((response) => {
                    const { pagination, data } = response.data;
                    const shipments = [];

                    data.forEach((dataPoint) => {
                        const shipment = transform(dataPoint);

                        shipments.push(shipment);
                    });

                    state.total = parseInt(pagination.total, 10);
                    state.initialized = true;
                    state.updating = false;
                    state.shipments = shipments;

                    resolve(shipments);
                })
                .catch((error) => {
                    reject(error);
                });
        }),
    },
};
