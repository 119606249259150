<template>
    <div class="password-change-form">
        <p>{{ $t('passwordChangeForm.hint') }}</p>

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <ValidationProvider v-slot="v" rules="required">
                    <div class="form-field form-field--direct-evaluation" :class="v.classes">
                        <input v-model="password" type="password">

                        <span class="form-field__label">{{ $t('passwordChangeForm.password') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="v" rules="required" vid="password">
                    <div class="form-field form-field--direct-evaluation" :class="v.classes">
                        <input v-model="newPassword" type="password">

                        <span class="form-field__label">{{ $t('passwordChangeForm.newPassword') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="v" rules="required|confirmed:password">
                    <div class="form-field form-field--direct-evaluation" :class="v.classes">
                        <input v-model="newPasswordRepeat" type="password">

                        <span class="form-field__label">{{ $t('passwordChangeForm.newPasswordRepeat') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-12">
                        <button type="submit" class="button button--arrow" :class="{ 'button--loading': loading }" :disabled="loading">{{ $t('passwordChangeForm.button') }}</button>

                        <div v-if="error">
                            <p class="text-red">{{ $t('passwordChangeForm.error') }}</p>
                        </div>
                    </div>
                    <div class="cell small-12 align-right">
                        <router-link :to="{ name: 'Profile' }" class="button button--white">{{ $t('global.back') }}</router-link>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { AUTH_LOGOUT } from '@/store/modules/security';
import axios from '@/config/axios';
import { mapActions } from 'vuex';

export default {
    name: 'PasswordChangeForm',

    props: {
        user: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        password: '',
        newPassword: '',
        newPasswordRepeat: '',

        loading: false,
        error: false,
    }),

    methods: {
        submit() {
            this.error = false;
            this.loading = true;

            axios({
                url: '/api/v2/profile/change-password',
                method: 'POST',
                data: {
                    old_password: this.password,
                    new_password: {
                        first: this.newPassword,
                        second: this.newPasswordRepeat,
                    },
                },
            })
                .then(() => {
                    this.performLogout().then(() => {
                        this.loading = false;
                        this.$router.push({ name: 'Login' });
                    });
                })
                .catch(() => {
                    this.password = '';
                    this.newPassword = '';
                    this.newPasswordRepeat = '';

                    this.loading = false;
                    this.error = true;
                });
        },

        ...mapActions('security', {
            performLogout: AUTH_LOGOUT,
        }),
    },
};
</script>
