<template>
    <div class="order-result">
        <div v-if="showHelp" class="grid-x grid-margin-x">
            <div class="cell small-24">
                <div class="order-form__help">
                    {{ $t('orderResult.help') }}
                </div>
            </div>
        </div>

        <div class="grid-x grid-margin-x">
            <div class="cell small-24 large-12">
                <div class="order-result__cell order-result__cell--bt">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-12">
                            <div class="order-result__label">
                                {{ $t('orderResult.duration') }}
                            </div>
                        </div>
                        <div class="cell small-12 text-right">
                            <div class="order-result__value">
                                {{ $t('orderResult.duration_value', {
                                    duration
                                }) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cell small-24 large-12">
                <div class="order-result__cell order-result__cell--bt">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-12">
                            <div class="order-result__label">
                                {{ $t('orderResult.estimate') }}
                            </div>
                        </div>
                        <div class="cell small-12 text-right">
                            <div class="order-result__value">
                                {{ $t('orderResult.estimate_value', {
                                    start: estimateStart,
                                    end: estimateEnd,
                                }) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="co2" class="grid-x grid-margin-x">
            <div class="cell small-24">
                <div class="order-result__cell">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-12">
                            <div class="order-result__label">
                                {{ $t('orderResult.co2') }}
                            </div>
                        </div>
                        <div class="cell small-12 text-right">
                            <div class="order-result__value order-result__value--green">
                                {{ $t('orderResult.co2_value', { co2 }) }}
                            </div>
                        </div>
                        <div class="cell small-24">
                            <div class="order-result__description">
                                {{ $t('orderResult.co2_description', { co2 }) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-x grid-margin-x">
            <div class="cell small-24">
                <div class="order-result__cell">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-12">
                            <div class="order-result__label order-result__label--large">
                                {{ $t('orderResult.price') }} <small>({{ $t('orderResult.vat') }})</small>
                            </div>
                        </div>
                        <div class="cell small-12 text-right">
                            <div class="order-result__value order-result__value--large">
                                CHF {{ result.price_inkl_mwst }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as moment from 'moment';
import { getCo2 } from '@/services/result';

export default {
    props: {
        result: {
            type: Object,
            required: true,
        },

        showHelp: {
            type: Boolean,
            required: false,
            default() {
                return false;
            },
        },
    },

    computed: {
        co2() {
            return getCo2(this.result);
        },

        duration() {
            const duration = moment.default(this.result.totalduration, 'HH:mm');

            return duration.format('HH:mm');
        },

        estimateStart() {
            const estimation = moment.default(this.result.estimated_time_of_arrival, 'HH:mm');
            estimation.subtract(1, 'hours');

            return estimation.format('HH:mm');
        },

        estimateEnd() {
            const estimation = moment.default(this.result.estimated_time_of_arrival, 'HH:mm');

            return estimation.format('HH:mm');
        },
    },
};
</script>
