<template>
    <button @click="$emit('sortingChanged', { field, direction: !sorting.direction })" :disabled="updating" class="table-sorting-field">
        <slot></slot>

        <span class="table-sorting-field__indicator" :class="{ 'table-sorting-field--up': sorting.field === field && sorting.direction, 'table-sorting-field--down': sorting.field === field && !sorting.direction  }"></span>
    </button>
</template>

<script>
export default {
    name: 'TableSortingField',

    props: {
        field: String,
        sorting: Object,
        updating: Boolean,
    },
};
</script>
