<template>
    <span v-html="rendered"></span>
</template>

<script>
export default {
    name: 'Label',

    props: {
        text: {
            type: String,
            required: true,
        },
    },

    computed: {
        rendered() {
            let { text } = this;

            text = text.replace('\n', '<br>');
            // text = text.replace('\r', '<br>');

            return text;
        },
    },
};
</script>
