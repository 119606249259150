<template>
    <div id="app">
        <Layout></Layout>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AUTH_GET_PROFILE, AUTH_LOGOUT } from '@/store/modules/security';
import axios from '@/config/axios';
import Layout from '@/components/Layout.vue';
import $ from 'jquery';

export default {
    components: {
        Layout,
    },

    metaInfo() {
        return {
            htmlAttrs: {
                lang: this.$i18n.locale,
            },

            title: this.$t('global.pageTitle'),
            titleTemplate: this.$t('global.pageTitleTemplate'),
        };
    },

    computed: {
        ...mapGetters('security', [
            'isAuthenticated',
            'user',
        ]),
    },

    watch: {
        $route() {
            const header = $('.header');
            const navigation = $('.navigation');
            const welcome = $('.welcome-message');

            $('body, html').removeClass('mobile--open');
            header.removeClass('header--open');
            navigation.removeClass('navigation--open');
            welcome.removeClass('welcome-message--open');
        },
    },

    methods: {
        ...mapActions('security', {
            getProfile: AUTH_GET_PROFILE,
            performLogout: AUTH_LOGOUT,
        }),

        ...mapActions('order', {
            clearStorage: 'clear',
        }),

        logout() {
            this.performLogout().then(() => {
                this.clearStorage().then(() => {
                    this.$router.push('/login');
                });
            });
        },
    },

    created() {
        axios.interceptors.response.use(undefined, (err) => new Promise(() => {
            // Could have been canceled
            if (err.response) {
                // eslint-disable-next-line no-underscore-dangle
                if (this.isAuthenticated && err.response.status === 401) {
                    this.logout();
                }
            }

            throw err;
        }));
    },

    mounted() {
        if (this.isAuthenticated) {
            this.getProfile();
        }
    },
};
</script>
