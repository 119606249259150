<template>
    <div>
        <button class="button button--small button--white" :class="{ 'button--loading': loading }" @click.prevent="download" :disabled="loading">{{ label }}</button>

        <div class="text-red" v-if="error">
            <small>{{ errorMessage }}</small>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios';

export default {
    name: 'PodDownloadButton',

    props: {
        label: {
            type: String,
            required: true,
        },

        shipment: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        loading: false,

        error: false,
        errorMessage: '',
    }),

    methods: {
        download() {
            this.error = false;
            this.errorMessage = '';
            this.loading = true;

            axios({
                method: 'GET',
                url: `/api/v2/shipments/${this.shipment.key}/request-waybill`,
            }).catch((error) => {
                this.error = true;
                this.errorMessage = error.response.data.message;

                this.loading = false;
            }).then((response) => {
                const { data } = response;
                const { token } = data;

                axios({
                    method: 'POST',
                    url: `/api/v2/shipments/${this.shipment.key}/waybill`,
                    data: {
                        token,
                    },
                    headers: {
                        'Content-Type': 'application/pdf',
                    },
                    responseType: 'arraybuffer',
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.response ? error.response.data.message : 'Error!';

                    this.loading = false;

                    return false;
                }).then((fileResponse) => {
                    if (!fileResponse) {
                        return;
                    }

                    const fileData = fileResponse.data;

                    this.forceDownload(this.shipment.key, fileData);
                    this.loading = false;
                });
            });
        },

        forceDownload(id, data) {
            // Force download by creating an invisible a tag and click on it
            const a = document.createElement('a');
            a.style.display = 'none';
            document.body.appendChild(a);

            a.href = window.URL.createObjectURL(
                new Blob([data], {
                    type: 'application/pdf',
                }),
            );

            // a.setAttribute('target', '_blank');
            a.setAttribute('download', `waybill-${id}.pdf`);
            a.click();

            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        },
    },
};
</script>
