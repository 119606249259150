export function getCo2(result) {
    if (!result) {
        return null;
    }

    if (!result.saved_co2) {
        return null;
    }

    return (Number.parseFloat(result.saved_co2) / 1000).toFixed(2);
}
