<template>
    <div ref="container">
        <v-select
            v-model="selected"
            :value="selected"
            :options="options"
            :filterable="true"
            :clearable="true"
            :select-on-tab="true"
            :class="classes"
            :filter="fuseSearch"

            @search:focus="onFocus"
            @search:blur="onBlur"
        >
            <template #no-options>
                {{ $t('addressSelect.indicator') }}
            </template>
        </v-select>
        <a class="form-field__action" @click.prevent="reset" v-if="selected">{{ $t('addressSelect.reset') }}</a>
    </div>
</template>

<script>
import axios from '@/config/axios';
import { transform } from '@/services/address-transformer';
import $ from 'jquery';

export default {
    props: {
        value: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        classes: {
            type: Array,
            required: false,
            default: () => ([]),
        },
        type: {
            type: String,
            required: false,
            default: 'shipping',
        },
    },

    data: () => ({
        selected: null,
        options: [],
    }),

    watch: {
        selected(updatedVal) {
            this.$emit('input', updatedVal);
        },
        value(value) {
            this.selected = value;
        },
    },

    methods: {
        reset() {
            this.selected = null;

            this.onBlur();
        },

        onFocus() {
            const field = $(this.$refs.container).closest('.form-field');
            field.addClass('form-field--has-value');
        },

        onBlur() {
            const field = $(this.$refs.container).closest('.form-field');

            if (this.selected === null) {
                field.removeClass('validated');
                field.removeClass('passed');
                field.removeClass('form-field--has-value');
            }
        },

        fuseSearch(options, search) {
            if (!search.length) {
                return options;
            }

            const searchStrings = search.split(' ');

            return options.filter((item) => {
                let match = true;

                searchStrings.forEach((searchString) => {
                    if (!item.label.toLowerCase().includes(searchString.toLowerCase())) {
                        match = false;
                    }
                });

                return match;
            });
        },
    },

    mounted() {
        axios({
            url: '/api/v2/addresses',
            method: 'GET',
            params: {
                // TODO
                count: 10000,
                offset: 0,
                sorting: {
                    field: 'firma',
                    direction: 'asc',
                },
            },
        })
            .then((response) => {
                const { data } = response.data;
                let addresses = [];

                data.forEach((dataPoint) => {
                    const address = transform(dataPoint);

                    addresses.push(address);
                });

                // Sort by first letter
                addresses.sort((a, b) => a.label.localeCompare(b.label));

                // Filter by type
                if (this.type === 'billing') {
                    addresses = addresses.filter((address) => address.isBillingAddress);
                }

                if (this.type === 'shipping') {
                    addresses = addresses.filter((address) => (address.standardSenderAddress || address.standardReceiverAddress) || !address.isBillingAddress || address.locationId);
                }

                this.options = addresses;
                this.initialized = true;

                this.$emit('optionsReady', addresses);

                this.$nextTick(() => {
                    this.selected = this.value;
                });
            });
    },
};
</script>
