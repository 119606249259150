<template>
    <div class="layout" data-type="default">
        <div class="page">
            <PageHeaderGoodbye />

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24">
                        <main class="page__content">
                            <router-view />
                        </main>
                    </div>
                </div>
            </div>
        </div>

        <PageFooter></PageFooter>
    </div>
</template>

<script>
import PageFooter from '@/components/PageFooter.vue';
import PageHeaderGoodbye from '@/components/PageHeaderGoodbye.vue';

export default {
    components: {
        PageHeaderGoodbye,
        PageFooter,
    },
};
</script>
