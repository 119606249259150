<template>
    <div class="popup-shipment-form">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <ValidationProvider v-slot="v" rules="">
                    <div class="form-field form-field--direct-evaluation" :class="v.classes">
                        <input v-model="reference" type="text" :disabled="loading">

                        <span class="form-field__label">{{ $t('popupShipmentForm.reference') }}</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="v" rules="required|min_value:1">
                    <div class="form-field form-field--direct-evaluation" :class="v.classes">
                        <input v-model="numberOfColis" type="text" :disabled="loading">

                        <span class="form-field__label">{{ $t('popupShipmentForm.numberOfColis') }}</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <div class="popup-shipment-form__confirmation" v-if="showConfirmation" v-html="$t('popupShipmentForm.confirmation')"></div>
                <div class="popup-shipment-form__confirmation" v-if="showConfirmationColisChanged" v-html="$t('popupShipmentForm.confirmationColisChanges')"></div>

                <button type="submit" class="button button--small button--white" :class="{ 'button--loading': loading }" :disabled="loading">{{ $t('popupShipmentForm.button') }}</button>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import $ from 'jquery';
import axios from '@/config/axios';

export default {
    name: 'PopupShipmentForm',
    props: {
        shipment: Object,
    },

    data: () => ({
        reference: null,
        numberOfColis: 0,
        numberOfColisOriginal: 0,

        loading: false,
        showConfirmation: false,
        showConfirmationColisChanged: false,
    }),

    methods: {
        submit() {
            this.showConfirmation = false;
            this.showConfirmationColisChanged = false;

            this.loading = true;

            axios({
                url: `/api/v2/shipments/${this.shipment.key}/edit`,
                data: {
                    reference: this.reference,
                    coli_count: this.numberOfColis,
                },
                method: 'POST',
            })
                .then(() => {
                    if (this.numberOfColisOriginal === this.numberOfColis) {
                        this.showConfirmation = true;
                    } else {
                        this.showConfirmationColisChanged = true;
                    }

                    this.numberOfColisOriginal = this.numberOfColis;
                    this.loading = false;
                });
        },
    },

    mounted() {
        this.reference = this.shipment.reference;
        this.numberOfColis = this.shipment.numberOfColis;
        this.numberOfColisOriginal = this.shipment.numberOfColis;

        this.$nextTick(() => {
            $(document).trigger('value-changed');
        });
    },
};
</script>
