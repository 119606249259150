import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import security from '@/store/modules/security';
import archive from '@/store/modules/archive';
import active from '@/store/modules/active';
import address from '@/store/modules/address';
import order from '@/store/modules/order';
import recurring from '@/store/modules/recurring-shipments';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        security,
        archive,
        active,
        address,
        order,
        recurring,
    },
});
