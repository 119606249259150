<template>
    <div class="widget" v-if="entries.length">
        <div class="service-information">
            <ul class="service-information__list">
                <li class="service-information__entry" v-for="(entry, key) in entries" :key="key">
                    <h4 class="service-information__title">{{ entry.title }}</h4>
                    <div v-html="entry.description" class="service-information__description"></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from '@/config/mary';

export default {
    name: 'ServiceInformationWidget',

    data: () => ({
        entries: [],
    }),

    mounted() {
        this.fetchServiceInformationEntries();
    },

    watch: {
        '$i18n.locale': function localeWatcher() {
            this.fetchServiceInformationEntries();
        },
    },

    methods: {
        fetchServiceInformationEntries() {
            axios({
                url: `/${this.$i18n.locale}/api/service-information`,
                method: 'GET',
            }).then((response) => {
                const { data } = response;

                this.entries = data;
            });
        },
    },
};
</script>

<style lang="scss">
@import "../scss/util/vars";

.service-information {
    border-radius: 10px;
    background: transparent linear-gradient(238deg, $color-grey-4 0%, $color-grey-6 100%) 0 0 no-repeat;
    padding: 25px 60px 25px calc(60px + 25px);
    margin-bottom: 30px;

    &__list {
        position: relative;

        &:before {
            position: absolute;
            right: calc(100% + 20px);
            top: 0;
            content: "";
            height: 40px;
            width: 40px;

            background-image: url("../scss/images/info.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    &__title {
        font-size: 18px;
        line-height: 24px;
        color: $color-white;
        font-weight: bold;
    }

    &__description {
        font-size: 18px;
        line-height: 24px;
        color: $color-white;

        p {
            color: $color-white;
        }
    }
}
</style>
