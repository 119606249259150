<template>
    <div class="page__footer">
        <footer class="footer">
            <div class="footer__top">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-24 medium-order-1 large-24 xlarge-5 xxlarge-6">
                            <a :href="$t('links.website')" class="footer__logo"></a>
                        </div>
                        <div class="cell small-24 small-order-1 medium-12 large-6 xlarge-5 xxlarge-4">
                            <address>
                                <p v-html="$t('footer.address')"></p>
                                <p>
                                    <a :href="$t('links.telto')">041 227 20 00</a><br>
                                    <a :href="$t('links.mailto')">info@swissconnect.ch</a>
                                </p>
                            </address>

                            <LanguageSwitcherExpanded />
                        </div>
                        <div class="cell small-24 medium-12 medium-order-0 large-6 large-order-1 xlarge-4">

                            <nav class="footer-nav">
                                <ul class="footer-nav__level footer-nav__level--1">
                                    <li class="footer-nav__item main-nav__item--large first">
                                        <router-link :to="{ name: 'Order' }" class="footer-nav__link main-nav__item--large">{{ $t('footer.order') }}</router-link>
                                    </li>
                                    <li class="footer-nav__item main-nav__item--large">
                                        <router-link :to="{ name: 'Offer' }" class="footer-nav__link main-nav__item--large">{{ $t('footer.offer') }}</router-link>
                                    </li>
                                    <li class="footer-nav__item">
                                        <a :href="$t('links.services')" class="footer-nav__link" target="_blank">{{ $t('footer.services') }}</a>
                                    </li>
                                    <li class="footer-nav__item">
                                        <a :href="$t('links.faq')" class="footer-nav__link" target="_blank">{{ $t('footer.faq') }}</a>
                                    </li>
                                    <li class="footer-nav__item">
                                        <a :href="$t('links.tos')" class="footer-nav__link" target="_blank">{{ $t('footer.tos') }}</a>
                                    </li>
                                    <li class="footer-nav__item">
                                        <a :href="$t('links.contact')" class="footer-nav__link" target="_blank">{{ $t('footer.contact') }}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="cell small-24 medium-12 medium-order-1 large-8 xlarge-6">
                            <div class="footer__newsletter" v-if="['de', 'fr'].indexOf(this.$i18n.locale) > -1">
                                <p>{{ $t('footer.newsletter') }}</p>
                                <a :href="$t('links.newsletter')" class="button" target="_blank">{{ $t('footer.newsletterButton') }}</a>
                            </div>
                        </div>
                        <div class="cell small-24 medium-4 medium-order-0 large-order-1">

                            <nav class="nav-certificates">
                                <div class="nav-certificates__level-container">
                                    <ul class="nav-certificates__level">
                                        <li class="nav-certificates__item">
                                            <a :href="$t('links.iso')" class="nav-certificates__link nav-certificates__link--iso first" itemprop="url" target="_blank"></a>
                                        </li>
                                        <li class="nav-certificates__item">
                                            <a :href="$t('links.climate')" title="Sustainable Business" class="nav-certificates__link nav-certificates__link--sustain" target="_blank"></a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer__bottom">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-12">
                            <span class="copyright">{{ $t('footer.copyright', { year: currentYear}) }}</span>
                        </div>
                        <div class="cell small-24 medium-12">
                            <nav class="service-nav">
                                <ul class="service-nav__level">
                                    <li class="service-nav__item first">
                                        <a :href="$t('links.imprint')" class="service-nav__link" itemprop="url" target="_blank">{{ $t('footer.imprint') }}</a>
                                    </li>
                                    <li class="service-nav__item last">
                                        <a :href="$t('links.tos')" class="service-nav__link" itemprop="url" target="_blank">{{ $t('footer.tosFull') }}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import LanguageSwitcherExpanded from '@/components/LanguageSwitcherExpanded.vue';

export default {
    name: 'PageFooter',

    components: {
        LanguageSwitcherExpanded,
    },

    computed: {
        currentYear: () => new Date().getFullYear(),
    },
};
</script>
