<template>
    <div class="price-calculator">
        <div class="card">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-24 large-24 xlarge-24 xxlarge-16 xxlarge-offset-4">
                        <div class="card__header">
                            <h2 class="card__title">{{ $t('priceCalculator.title') }}</h2>
                        </div>
                        <div class="card__body">
                            <PriceCalculatorForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PriceCalculatorForm from '@/components/PriceCalculatorForm.vue';

export default {
    name: 'PriceCalculator',
    components: {
        PriceCalculatorForm,
    },

    metaInfo() {
        return {
            title: this.$t('priceCalculator.title'),
        };
    },
};
</script>
