<template>
    <div class="layout" data-type="login">
        <div class="page page--white">
            <PageHeader />

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-16 medium-offset-4">
                        <main class="page__content">
                            <router-view/>
                        </main>
                    </div>
                </div>
            </div>
        </div>

        <PageFooter></PageFooter>
    </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import PageFooter from '@/components/PageFooter.vue';

export default {
    components: {
        PageFooter,
        PageHeader,
    },
};
</script>
