import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use((request) => {
    const { token } = localStorage;

    if (token) {
        // eslint-disable-next-line no-param-reassign
        request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
});

instance.interceptors.request.use((request) => {
    const { switchUser } = localStorage;

    if (switchUser) {
        // eslint-disable-next-line no-param-reassign
        request.headers['X-Switch-User'] = switchUser;
    }

    return request;
});

export const base = axios;
export const { CancelToken } = axios;
export default instance;
