<template>
    <div class="address-list">
        <div class="address-list__slider">
            <VueSlickCarousel :arrows="false" :dots="addresses.length > 1" ref="carousel">
                <address class="address address-list__item" v-for="(address, index) in addresses" :key="index">
                    <span class="address__large"><Label :text="address.name" /><br></span>
                    <span v-if="address.contact">{{ address.contact }}<br></span>
                    {{ address.address }}<br>
                    {{ address.zip }} {{ address.location }}<br>
                    <span v-if="address.phone">{{ address.phone }}<br></span>

                    <!-- States -->
                    <small v-if="address.type === 'from' && getStateName(shipment) === 'cancelled'" class="address__state address__state--red">
                        <strong>{{ $t('shipment.cancelled') }}</strong>
                    </small>

                    <template v-if="getStateName(shipment) !== 'cancelled'">
                        <div v-if="showTimes && shipment.isTour" class="address__state address__state--green">
                            <template v-if="address.type === 'from'">
                                <span v-if="address.datetime">
                                    {{ $t('shipment.pickedUp', { date: dateFormat(address.datetime), time: dateTimeFormat(address.datetime) }) }}
                                </span>

                                <span v-if="!address.datetime && address.timeStart && address.timeEnd">
                                    {{ $t('shipment.plannedPickup', { from: dateTimeFormat(address.timeStart), to: dateTimeFormat(address.timeEnd) }) }}
                                </span>
                            </template>
                            <template v-if="address.type === 'to'">
                                <span v-if="address.datetime">
                                    {{ $t('shipment.delivered', { from: dateFormat(address.datetime), time: dateTimeFormat(address.datetime) }) }}
                                </span>

                                <span v-if="!address.datetime && address.timeStart && address.timeEnd">
                                    {{ $t('shipment.plannedDelivery', { from: dateTimeFormat(address.timeStart), to: dateTimeFormat(address.timeEnd) }) }}
                                </span>
                            </template>
                        </div>

                        <div v-if="showTimes && !shipment.isTour" class="address__state address__state--green">
                            <template v-if="address.type === 'from'">
                                <span v-if="shipment.pickedUp">
                                    {{ $t('shipment.pickedUp', { date: dateFormat(shipment.pickedUp), time: dateTimeFormat(shipment.pickedUp) }) }}
                                </span>

                                <span v-if="!shipment.pickedUp && shipment.pickupFrom && shipment.pickupTo">
                                    {{ $t('shipment.plannedPickup', { from: dateTimeFormat(shipment.pickupFrom), to: dateTimeFormat(shipment.pickupTo) }) }}
                                </span>
                            </template>
                            <template v-if="address.type === 'to'">
                                <span v-if="shipment.delivered">
                                    {{ $t('shipment.delivered', { date: dateFormat(shipment.delivered), time: dateTimeFormat(shipment.delivered) }) }}
                                </span>

                                <span v-if="!shipment.delivered && shipment.deliveryFrom && shipment.deliveryTo">
                                    {{ $t('shipment.plannedDelivery', { from: dateTimeFormat(shipment.deliveryFrom), to: dateTimeFormat(shipment.deliveryTo) }) }}
                                </span>
                            </template>
                        </div>
                    </template>
                </address>
            </VueSlickCarousel>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import Label from '@/components/MultiLineLabel.vue';

import { getStateName } from '@/services/shipment-transformer';
import { dateFormat, dateTimeFormat } from '@/services/date-formatter';

export default {
    name: 'PopupAddressList',

    components: {
        Label,
        VueSlickCarousel,
    },

    props: {
        addresses: Array,
        shipment: Object,
        showTimes: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    watch: {
        addresses() {
            this.$refs.carousel.goTo(0);
        },
    },

    methods: {
        dateFormat,
        dateTimeFormat,
        getStateName,
    },
};
</script>
