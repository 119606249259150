<template>
    <div class="address-book">
        <div class="card">
            <div class="card__header">
                <h1 class="card__title">{{ $t('addressBook.title') }}</h1>

                <div class="data-table__header-button">
                    <router-link class="button button--arrow" :to="{ name: 'CreateAddress' }">{{ $t('navigation.createAddress') }}</router-link>
                </div>

                <AddressFilter @filter="filter = $event"></AddressFilter>
            </div>

            <div v-if="!initialized">{{ $t('global.loading') }}</div>

            <div v-if="initialized && addresses.length > 0" class="card__table">
                <table class="data-table" :class="{ 'data-table--updating': updating }">
                    <thead>
                        <tr>
                            <th>
                                <TableSortingField field="id" :sorting="sorting" :updating="updating" @sortingChanged="sorting = $event">{{ $t('address.id') }}</TableSortingField>
                            </th>
                            <th>{{ $t('address.name') }}</th>
                            <th>{{ $t('address.street') }}</th>
                            <th>{{ $t('address.location') }}</th>
                            <th>{{ $t('address.contact') }}</th>
                            <th>{{ $t('address.phone') }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="address in addresses">
                            <td>
                                <div class="data-table__mobile-label">{{ $t('address.id') }}</div>
                                {{ address.id }}
                            </td>
                            <td>
                                <div class="data-table__mobile-label">{{ $t('address.name') }}</div>
                                {{ address.name }}

                                <router-link :to="{ name: 'EditAddress', params: { address: address.id } }" v-if="address.standardSenderAddress" class="address__type">{{ $t('address.isStandardSenderAddress') }}</router-link>
                                <router-link :to="{ name: 'EditAddress', params: { address: address.id } }" v-if="address.standardReceiverAddress" class="address__type">{{ $t('address.isStandardReceiverAddress') }}</router-link>
                                <router-link :to="{ name: 'EditAddress', params: { address: address.id } }" v-if="address.standardBillingAddress" class="address__type">{{ $t('address.isStandardBillingAddress') }}</router-link>
                            </td>
                            <td>
                                <div class="data-table__mobile-label">{{ $t('address.street') }}</div>
                                <Label :text="address.street"></Label>
                            </td>
                            <td>
                                <div class="data-table__mobile-label">{{ $t('address.location') }}</div>
                                {{ address.zip }} {{ address.location }}
                            </td>
                            <td>
                                <div class="data-table__mobile-label">{{ $t('address.contact') }}</div>
                                {{ address.contact }}
                            </td>
                            <td>
                                <div class="data-table__mobile-label">{{ $t('address.phone') }}</div>
                                {{ address.phone }}
                            </td>
                            <td>
                                <router-link class="data-table__button data-table__button--edit" :to="{ name: 'EditAddress', params: { address: address.id } }"></router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="initialized && addresses.length <= 0">
                {{ $t('addressBook.empty') }}
            </div>

            <div class="card__footer" v-if="initialized && addresses.length > 0">
                <Pagination
                    :page="page"
                    :per-page="perPage"
                    :total="total"
                    :initialized="initialized"
                    :updating="updating"

                    @pageChanged="page = $event"
                    @perPageChanged="perPage = $event"
                ></Pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Pagination from '@/components/Pagination.vue';
import TableSortingField from '@/components/TableSortingField.vue';
import AddressFilter from '@/components/AddressFilter.vue';
import Label from '@/components/MultiLineLabel.vue';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('address');

export default {
    name: 'Active',

    components: {
        AddressFilter,
        TableSortingField,
        Pagination,
        Label,
    },

    data: () => ({
        perPage: 20,
        page: 0,

        filter: {},
        sorting: {
            field: 'id',
            direction: true,
        },
    }),

    metaInfo() {
        return {
            title: this.$t('addressBook.title'),
        };
    },

    computed: {
        ...mapGetters([
            'total',
            'initialized',
            'updating',
        ]),

        ...mapState([
            'addresses',
        ]),
    },

    methods: {
        async fetch() {
            await this.fetchAddresses({
                count: this.perPage,
                offset: this.page * this.perPage,
                filter: this.filter,
                sorting: {
                    field: this.sorting.field,
                    direction: this.sorting.direction ? 'desc' : 'asc',
                },
            });
        },

        ...mapActions([
            'fetchAddresses',
        ]),
    },

    watch: {
        perPage() {
            this.page = 0;
            this.fetch();
        },

        filter: {
            deep: true,
            handler() {
                this.page = 0;
                this.fetch();
            },
        },

        sorting: {
            deep: true,
            handler() {
                this.page = 0;
                this.fetch();
            },
        },

        page() {
            this.fetch();
        },
    },

    mounted() {
        this.fetch();
    },
};
</script>
