<template>
    <div class="goodbye-message">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="cell small-24 medium-20 medium-offset-2">
                    <div class="welcome-message__title">
                        {{ $t('goodbyeMessage.title') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GoodbyeMessage',
};
</script>
