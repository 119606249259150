<template>
    <div class="language">
        <div class="language-switcher">
            <ul class="language-switcher__expanded-switcher">
                <li v-for="(language, index) in messages" :key="index">
                    <button class="language-switcher__expanded-item" :class="{ 'language-switcher__expanded-item--active': $i18n.locale === language }" @click="onChange(language)">{{ $t(`languageSwitcher.lang.${language}`) }}</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { messages } from '@/config/i18n';
import { localize } from 'vee-validate';

export default {
    data: () => ({
        messages: Object.keys(messages),
    }),

    methods: {
        onChange(language) {
            this.$i18n.locale = language;

            localStorage.setItem('language', language);
            localize(language);
        },
    },
};
</script>
