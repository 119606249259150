<template>
    <v-select
        v-model="selected"
        :value="selected"
        :options="options"
        :filterable="false"
        :clearable="false"
        :select-on-tab="true"
        :class="classes"

        :disabled="disabled"

        @search="search"
    >
        <template #no-options>
            {{ $t('locationSelect.indicator') }}
        </template>
    </v-select>
</template>

<script>
import axios, { CancelToken, base } from '@/config/axios';

let source = null;

export default {
    name: 'LocationSelect',

    props: {
        value: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        classes: {
            type: Array,
            required: false,
            default: () => ([]),
        },
    },

    data: () => ({
        selected: null,
        options: [],
    }),

    watch: {
        value() {
            this.$nextTick(() => {
                this.selected = this.value;
            });
        },

        selected(updatedVal) {
            this.$emit('input', updatedVal);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.selected = this.value;
        });
    },

    methods: {
        search(search, loading) {
            loading(true);

            if (source !== null) {
                source.cancel();
            }

            source = CancelToken.source();

            axios
                .get('/api/v2/zips', {
                    cancelToken: source.token,
                    params: {
                        search,
                    },
                })
                .then((response) => {
                    const locations = [];

                    response.data.forEach((result) => {
                        const key = `name_${this.$i18n.locale}`;
                        const name = result[key] || result.name_en;
                        const label = `${result.code} ${name}`;

                        locations.push({
                            id: result.id,
                            label,
                            name,
                            code: result.code,
                        });
                    });

                    this.options = locations;

                    loading(false);
                })
                .catch((error) => {
                    if (base.isCancel(error)) {
                        return;
                    }

                    throw error;
                });
        },
    },
};
</script>
