<template>
    <div>
        <hr>
        <div v-if="showMessage">
            <p class="text-green">{{ $t('podEmailForm.message') }}</p>
        </div>
        <div v-else>
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <ValidationProvider v-slot="v" rules="required|email">
                        <div class="form-field form-field--direct-evaluation" :class="v.classes">
                            <input v-model="email" type="text">

                            <span class="form-field__label">{{ $t('podEmailForm.email') }}*</span>
                            <span class="form-field__error">{{ v.errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <button type="submit" class="button button--small" :class="{ 'button--loading': loading }" :disabled="loading">{{ $t('podEmailForm.button') }}</button>

                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios';

export default {
    name: 'PodEmailForm',

    props: {
        shipment: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        email: '',

        loading: false,
        showMessage: false,
    }),

    methods: {
        submit() {
            this.showMessage = false;
            this.loading = true;

            axios({
                url: '/api/v2/shipments/request-waybill-email',
                method: 'POST',
                data: {
                    order: this.shipment.key,
                    email: this.email,
                },
            }).then(() => {
                this.showMessage = true;
                this.loading = false;
            });
        },
    },
};
</script>
