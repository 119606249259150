<template>
    <div class="profile">
        <div class="card">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 small-24 medium-24 large-24 xlarge-24 xxlarge-16 xxlarge-offset-4">
                        <div class="card__header">
                            <h1 class="card__title">{{ $t('passwordChange.title') }}</h1>
                        </div>

                        <div class="card__body">
                            <div class="grid-container">
                                <div class="grid-x grid-margin-x">
                                    <div class="cell small-24">
                                        <PasswordChangeForm :user="user"></PasswordChangeForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PasswordChangeForm from '@/components/PasswordChangeForm.vue';

export default {
    name: 'Profile',

    components: {
        PasswordChangeForm,
    },

    metaInfo() {
        return {
            title: this.$t('passwordChange.title'),
        };
    },

    computed: {
        ...mapGetters('security', [
            'user',
        ]),
    },
};
</script>
