<template>
    <div class="widget">
        <div class="card" :class="{ 'card--loading': !initialized }">

            <div class="grid-x grid-margin-x">
                <div class="cell small-8 medium-12">
                    <h2 class="card__title">
                        {{ $t('widget.archive.title') }}
                    </h2>
                </div>
                <div class="cell small-16 medium-12 medium-text-right align-right">
                    <router-link class="card__subtitle" :to="{ name: 'Archive' }">{{ $t('widget.archive.export') }}</router-link>
                </div>
            </div>

            <div v-if="initialized && shipments.length > 0">
                <table class="widget-table">
                    <tr>
                        <th class="widget-table__header-cell">{{ $t('widget.archive.externalNumber') }}</th>
                        <th class="widget-table__header-cell">{{ $t('widget.archive.delivered') }}</th>
                        <th class="widget-table__header-cell">{{ $t('widget.archive.visiertVon') }}</th>
                    </tr>
                    <tr class="widget-table__row widget-table__grid--2" v-for="(shipment, index) in shipments" :key="index">
                        <td class="widget-table__cell">{{ shipment.externalNumber }}</td>
                        <td class="widget-table__cell">{{ longFormat(shipment.delivered) }}</td>
                        <td class="widget-table__cell">{{ shipment.visierName }}</td>
                    </tr>
                </table>
            </div>

            <p v-if="initialized && shipments.length <= 0">
                {{ $t('widget.archive.empty') }}
            </p>

            <router-link :to="{ name: 'Archive' }" class="card__link">{{ $t('widget.archive.link') }}</router-link>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios';
import { transform } from '@/services/shipment-transformer';
import { longFormat } from '@/services/date-formatter';

export default {
    name: 'ArchiveWidget',

    data: () => ({
        initialized: false,

        shipments: [],
    }),

    mounted() {
        this.initialized = false;

        axios({
            url: '/api/v2/shipments/archive',
            method: 'GET',
            sorting: {
                field: 'visier_datum',
                direction: 'desc',
            },
            params: {
                count: 3,
                offset: 0,
            },
        })
            .then((response) => {
                const { data } = response.data;
                const shipments = [];

                data.forEach((dataPoint) => {
                    const shipment = transform(dataPoint);

                    shipments.push(shipment);
                });

                this.shipments = shipments;
                this.initialized = true;
            });
    },

    methods: {
        longFormat,
    },
};
</script>
