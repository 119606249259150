class Storage {
    constructor(storeImplementation, base = 'rosalina') {
        this.store = storeImplementation;
        this.base = base;
    }

    get(key) {
        const data = JSON.parse(this.store.getItem(this.base)) || {};

        if (data[key] === undefined) {
            return null;
        }

        return data[key];
    }

    set(key, value) {
        const data = JSON.parse(this.store.getItem(this.base)) || {};

        data[key] = value;

        this.store.setItem(this.base, JSON.stringify(data));
    }

    reset() {
        this.store.removeItem(this.base);
    }
}

export default new Storage(window.sessionStorage);
