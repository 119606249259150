/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            let refreshing = false;
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (refreshing) {
                    return;
                }

                refreshing = true;
                window.location.reload();
            });
        },

        registered(registration) {
            setInterval(() => {
                registration.update();
            }, 1000 * 5);
        },

        // Content has been cached for offline use.
        cached() {
        },

        // New content is downloading...
        updatefound() {
        },

        updated(registration) {
            const event = new CustomEvent('service-worker.updated', {
                detail: registration.waiting,
            });

            document.dispatchEvent(event);
        },

        // No internet connection found. App is running in offline mode.
        offline() {
        },

        error(error) {
            console.error(error);
        },
    });
}
