<template>
    <div class="widget price-calculator-widget">
        <div class="card">
            <h2 class="card__title">{{ $t('widget.priceCalculator.title') }}</h2>

            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">

                    <div class="grid-x">
                        <div class="cell small-24 large-11">
                            <ValidationProvider v-slot="v" rules="required" mode="aggressive">
                                <div class="form-field form-field--direct-evaluation" :class="v.classes">
                                    <LocationSelect v-model="form.from" />

                                    <span class="form-field__label">{{ $t('widget.priceCalculator.from') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="cell show-for-large large-2">
                            <div class="form-field-connector"></div>
                        </div>
                        <div class="cell small-24 large-11">
                            <ValidationProvider v-slot="v" rules="required" mode="aggressive">
                                <div class="form-field form-field--direct-evaluation" :class="v.classes">
                                    <LocationSelect v-model="form.to" />

                                    <span class="form-field__label">{{ $t('widget.priceCalculator.to') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell small-24 align-right">
                            <button type="submit" class="button button--arrow">{{ $t('widget.priceCalculator.button') }}</button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import LocationSelect from '@/components/LocationSelect.vue';

const { mapActions } = createNamespacedHelpers('order');

export default {
    name: 'PriceCalculatorWidget',

    components: {
        LocationSelect,
    },

    data: () => ({
        form: {
            from: null,
            to: null,
        },
    }),

    methods: {
        ...mapActions({
            saveFormChanges: 'save',
        }),

        submit() {
            this.saveFormChanges(this.form);

            this.$router.push({ name: 'PriceCalculator' });
        },
    },
};
</script>
