<template>
    <div class="widget" v-if="user.canSwitchUser || switchedUser">
        <div class="card">
            <h2 class="card__title">{{ $t('widget.switchUser.title') }}</h2>

            <div v-if="!switchedUser">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(submit)">
                        <div class="grid-x grid-margin-x grid-margin-y">
                            <div class="cell small-24 align-right">
                                <ValidationProvider v-slot="v" rules="required">
                                    <v-select :options="users" :class="v.classes" v-model="selected">
                                        <template #no-options>
                                            {{ $t('widget.switchUser.indicator') }}
                                        </template>
                                    </v-select>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="grid-x grid-margin-x grid-margin-y">
                            <div class="cell small-24 align-right">
                                <button type="submit" class="button button--arrow">{{ $t('widget.switchUser.button') }}</button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
            <div v-else>
                <p v-html="$t('widget.switchUser.warning', { user: user.username })"></p>
                <button class="button button--small" @click="reset">{{ $t('widget.switchUser.resetButton') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios';
import { createNamespacedHelpers, mapGetters } from 'vuex';

const { mapActions } = createNamespacedHelpers('order');

export default {
    name: 'SwitchUserWidget',

    data: () => ({
        selected: null,
        users: [],
    }),

    mounted() {
        if (!this.user.canSwitchUser) {
            return;
        }

        this.fetchSwitchableUsers();
    },

    methods: {
        submit() {
            localStorage.switchUser = this.selected.id;
            this.$router.go(0);
        },

        reset() {
            localStorage.removeItem('switchUser');

            this.clearData().then(() => {
                this.$router.go(0);
            });
        },

        fetchSwitchableUsers() {
            axios({
                url: '/api/v2/get-switchable-users',
                method: 'GET',
            }).then((response) => {
                const { data } = response;

                if (!Array.isArray(data)) {
                    return;
                }

                this.users = data.map((user) => ({
                    id: user.username,
                    label: user.username,
                }));
            });
        },

        ...mapActions({
            clearData: 'clear',
        }),
    },

    computed: {
        switchedUser() {
            return localStorage.switchUser;
        },

        ...mapGetters('security', [
            'user',
        ]),
    },
};
</script>
