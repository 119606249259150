import $ from 'jquery';

export default {
    fixFloatingLabels: () => {
        setTimeout(() => {
            $(':input').each(function forEach() {
                const input = $(this);
                const field = $(this).closest('.form-field');

                if (input.val() !== '') {
                    field.attr('data-content', 1);
                } else {
                    field.removeAttr('data-content');
                }
            });
        }, 10);
    },

    registerChangeHandler: () => {
        $(document).on('change', '.form-field :input', function onChange() {
            const input = $(this);
            const field = $(this).closest('.form-field');

            if (input.val() !== '') {
                field.attr('data-content', 1);
            } else {
                field.removeAttr('data-content');
            }
        });
    },
};
