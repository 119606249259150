import axios from '@/config/axios';
import { transformFull } from '@/services/shipment-transformer';

export async function fetchShipment(id) {
    return axios({
        url: `/api/v2/shipments/${id}`,
        method: 'GET',
    })
        .then((response) => {
            const { data } = response;
            return transformFull(data);
        });
}
