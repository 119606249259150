<template>
    <div class="avatar" v-if="isAuthenticated && user">
        <div class="avatar__info">
            <div class="avatar__name">{{ user.firstname }} {{ user.lastname }}</div>
            <div class="avatar__links">
                <router-link :to="{ name: 'Profile' }" class="avatar__link">{{ $t('avatar.profile') }}</router-link>
                <button @click="logout" class="avatar__link">{{ $t('avatar.logout') }}</button>
            </div>
        </div>
        <div class="avatar__image-containter">
            <router-link :to="{ name: 'Profile' }" class="avatar__image">
                {{ profileShortcut }}
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/modules/security';

export default {
    name: 'Avatar',

    computed: {
        ...mapGetters('security', [
            'isAuthenticated',
            'user',
        ]),

        profileShortcut() {
            if (!this.user.firstname || !this.user.lastname) {
                return `${this.user.username.charAt(0)}${this.user.username.charAt(1)}`.toUpperCase();
            }

            return `${this.user.firstname.charAt(0)}${this.user.lastname.charAt(0)}`.toUpperCase();
        },
    },

    methods: {
        ...mapActions('security', {
            performLogout: AUTH_LOGOUT,
        }),

        ...mapActions('order', {
            clearStorage: 'clear',
        }),

        logout() {
            this.performLogout().then(() => {
                this.clearStorage().then(() => {
                    this.$router.push('/goodbye');
                });
            });
        },
    },
};
</script>
