<template>
    <div class="coli-list" :class="classes">
        <div class="form-part">
            <div class="form-part__title">{{ $t('coliList.title') }}</div>
        </div>

        <div class="grid-x grid-margin-x">
            <div class="cell small-12 medium-5">
                <ValidationProvider v-slot="v" rules="required|min_value:1|max_value:15" tag="div">
                    <div class="form-field" :class="v.classes" data-content>
                        <input v-model.lazy="list.count">
                        <div class="form-field__label">
                            {{ $t('coliList.count') }}*
                        </div>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>
        </div>

        <div class="coli-list__list">
            <div v-for="(coli, index) in list.colis" :key="index" class="grid-x grid-margin-x coli-list__entry">
                <div class="cell small-20 medium-24">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 medium-7" v-if="enableNotes">
                            <AutoCompleteInput v-model="coli.notes" :list="templates" @input="onNotesChange(coli)" />
                        </div>
                        <div class="cell small-24" :class="{ 'medium-4': enableNotes, 'medium-5': !enableNotes }">
                            <ValidationProvider v-slot="v" :rules="{ required: true, regex: /^(\d*[.])?\d+$/ }" tag="div">
                                <div class="form-field form-field--has-suffix" :class="v.classes">
                                    <input v-model="coli.length">

                                    <span class="form-field__label">{{ $t('coliList.form.length') }}*</span>
                                    <span class="form-field__suffix">{{ $t('coliList.cm') }}</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="cell small-24" :class="{ 'medium-4': enableNotes, 'medium-5': !enableNotes }">
                            <ValidationProvider v-slot="v" :rules="{ required: true, regex: /^(\d*[.])?\d+$/ }" tag="div">
                                <div class="form-field form-field--has-suffix" :class="v.classes">
                                    <input v-model="coli.width">

                                    <span class="form-field__label">{{ $t('coliList.form.width') }}*</span>
                                    <span class="form-field__suffix">{{ $t('coliList.cm') }}</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="cell small-24" :class="{ 'medium-4': enableNotes, 'medium-5': !enableNotes }">
                            <ValidationProvider v-slot="v" :rules="{ required: true, regex: /^(\d*[.])?\d+$/ }" tag="div">
                                <div class="form-field form-field--has-suffix" :class="v.classes">
                                    <input v-model="coli.height">

                                    <span class="form-field__label">{{ $t('coliList.form.height') }}*</span>
                                    <span class="form-field__suffix">{{ $t('coliList.cm') }}</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>

                        <div class="cell small-24" :class="{ 'medium-4': enableNotes, 'medium-8': !enableNotes }">
                            <ValidationProvider v-slot="v" :rules="{ required: true, regex: /^(\d*[.])?\d+$/ }" tag="div">
                                <div class="form-field form-field--has-suffix" :class="v.classes">
                                    <input v-model="coli.weight">

                                    <span class="form-field__label">{{ $t('coliList.form.weight') }}*</span>
                                    <span class="form-field__suffix">{{ $t('coliList.kg') }}</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>

                        <div class="cell hide-for-small-only medium-1">
                            <button class="button button--minus" @click="removeColi(index)"></button>
                        </div>
                    </div>
                </div>
                <div class="cell small-4 hide-for-medium">
                    <div class="coli-button__container align-center text-center">
                        <button class="button button--minus coli-button--minus" @click="removeColi(index)"></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-x grid-margin-x">
            <div class="cell small-order-2 medium-order-1 small-24 medium-8 medium-offset-15">
                <div class="iata">
                    <div class="form-field form-field--has-suffix form-field--disabled" data-content>
                        <input v-model="iata" disabled>
                        <span class="form-field__suffix">{{ $t('coliList.kg') }}</span>
                        <span class="form-field__label">{{ $t('coliList.form.iata_weight') }}</span>
                    </div>

                    <div class="iata__description">
                        <i18n path="coliList.iata_help" tag="span">
                            <a :href="$t('links.iata')" target="_blank">{{ $t('coliList.iata_help_page') }}</a>
                        </i18n>
                    </div>
                </div>
            </div>

            <div class="cell small-order-1 medium-order-2 small-24 medium-1">
                <div class="coli-button__container-horizontal">
                    <button class="button button--plus coli-button--plus" @click="addColi"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import WeightCalculator from '@/services/weight-calculator';
import axios from '@/config/axios';
import AutoCompleteInput from '@/components/AutoCompleteInput.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('order');

export default {
    components: { AutoCompleteInput },
    props: {
        value: {
            type: Object,
            required: true,
        },
        enableNotes: {
            type: Boolean,
            required: false,
            default: false,
        },
        classes: {
            type: Array,
            required: false,
            default: () => ([]),
        },
    },

    data: () => ({
        list: {
            count: 0,
            colis: [],
        },

        templates: [],
    }),

    computed: {
        iata() {
            return WeightCalculator.calculate(this.list.colis);
        },

        loadFromStoredForm() {
            return !!this.$route.query.load;
        },

        ...mapGetters([
            'storedForm',
        ]),
    },

    watch: {
        list: {
            handler(value) {
                this.$emit('input', value);
            },
            deep: true,
        },

        colis(value) {
            this.$emit('input', value);
        },

        'list.count': function countWatcher(input) {
            const value = Math.min(Number.parseInt(input, 10), 15);

            if (Number.isNaN(value)) {
                return;
            }

            const count = this.list.colis.length;

            if (value === this.list.colis.length) {
                return;
            }

            const colis = this.list.colis.slice(0, value);

            for (let i = count; i < value; i += 1) {
                colis.push({
                    height: '',
                    width: '',
                    length: '',
                    weight: '',
                    notes: '',
                });
            }

            this.list.colis = colis;
        },
    },

    mounted() {
        if (this.loadFromStoredForm && this.storedForm.colis) {
            this.list.colis = this.storedForm.colis.colis;
            this.list.count = this.storedForm.colis.count;
        }

        axios({
            url: '/api/v2/gebinde?kundentool',
            method: 'GET',
        })
            .then((response) => {
                const templates = [];

                response.data.forEach((result) => {
                    const key = `label_${this.$i18n.locale}`;
                    const label = result[key] || result.label_de;

                    const { width, height, length } = result;

                    templates.push({
                        id: result.id,
                        label,
                        width: parseInt(width, 10),
                        height: parseInt(height, 10),
                        length: parseInt(length, 10),
                    });
                });

                this.templates = templates;
            });

        this.$nextTick(() => {
            this.list = this.value;

            $(document).trigger('value-changed');
        });
    },

    methods: {
        addColi() {
            const values = {
                height: '',
                width: '',
                length: '',
                weight: '',
                notes: '',
            };

            this.list.colis.push(values);
            this.list.count = this.list.colis.length;

            $(document).trigger('value-changed');
        },

        removeColi(index) {
            this.list.colis.splice(index, 1);
            this.list.count = this.list.colis.length;
        },

        onNotesChange(coli) {
            const value = coli.notes;

            this.templates.forEach((template) => {
                if (value !== template.label) {
                    return;
                }

                // eslint-disable-next-line no-param-reassign
                if (!coli.height) coli.height = template.height;

                // eslint-disable-next-line no-param-reassign
                if (!coli.width) coli.width = template.width;

                // eslint-disable-next-line no-param-reassign
                if (!coli.length) coli.length = template.length;
            });

            $(document).trigger('value-changed');
        },
    },
};
</script>
