<template>
    <div class="layout" data-type="default">
        <div class="page">
            <PageHeader></PageHeader>

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-24">
                        <main class="page__content">
                            <div v-if="user">
                                <transition>
                                    <router-view />
                                </transition>
                            </div>
                            <div v-else>
                                {{ $t('global.loading') }}
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>

        <PageFooter></PageFooter>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '@/components/PageHeader.vue';
import PageFooter from '@/components/PageFooter.vue';

export default {
    components: {
        PageFooter,
        PageHeader,
    },

    computed: {
        ...mapGetters('security', [
            'user',
        ]),
    },
};
</script>
