<template>
    <div class="language">
        <div class="language-switcher">
            <select v-model="$i18n.locale" @change="onChange" class="language-switcher__switcher">
                <option v-for="(language, index) in messages" :key="index" :value="language">{{ $t(`languageSwitcher.lang.${language}`) }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import { messages } from '@/config/i18n';
import { localize } from 'vee-validate';

export default {
    data: () => ({
        messages: Object.keys(messages),
    }),

    methods: {
        onChange(event) {
            localStorage.setItem('language', event.target.value);
            localize(event.target.value);
        },
    },
};
</script>
