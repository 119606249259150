<template>
    <div class="welcome-message">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="cell small-24 medium-20 medium-offset-1">
                    <div class="welcome-message__title">
                        {{ $t('welcomeMessage.welcome', { user: user.firstname }) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('security', [
            'user',
        ]),
    },
};
</script>
