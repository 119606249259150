<template>
    <div class="widget">
        <div class="quick-links">
            <div class="grid-container">
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-12 medium-6 large-8 xlarge-6">
                        <router-link :to="{ name: 'Order' }" class="quick-links__box">
                            <div class="quick-links__content">
                                <img :src="IconOrder" class="quick-links__image" alt="">
                                <span class="quick-links__title">{{ $t('widget.quickLinks.order.label') }}</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="cell small-12 medium-6 large-8 xlarge-6">
                        <router-link :to="{ name: 'Offer' }" class="quick-links__box">
                            <div class="quick-links__content">
                                <img :src="IconOffer" class="quick-links__image" alt="">
                                <span class="quick-links__title">{{ $t('widget.quickLinks.offer.label') }}</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="cell small-12 medium-6 large-8 xlarge-6">
                        <router-link :to="{ name: 'Profile' }" class="quick-links__box">
                            <div class="quick-links__content">
                                <img :src="IconProfile" class="quick-links__image" alt="">
                                <span class="quick-links__title">{{ $t('widget.quickLinks.profile.label') }}</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="cell small-12 medium-6 large-8 xlarge-6">
                        <a :href="$t('widget.quickLinks.faq.link')" target="_blank" class="quick-links__box">
                            <div class="quick-links__content">
                                <img :src="IconFaq" class="quick-links__image" alt="">
                                <span class="quick-links__title">{{ $t('widget.quickLinks.faq.label') }}</span>
                            </div>
                        </a>
                    </div>
                    <div class="cell small-12 medium-6 large-8 xlarge-6" v-if="['de', 'fr'].indexOf(this.$i18n.locale) > -1">
                        <a :href="$t('widget.quickLinks.blog.link')" target="_blank" class="quick-links__box">
                            <div class="quick-links__content">
                                <img :src="IconBlog" class="quick-links__image" alt="">
                                <span class="quick-links__title">{{ $t('widget.quickLinks.blog.label') }}</span>
                            </div>
                        </a>
                    </div>
                    <div class="cell small-12 medium-6 large-8 xlarge-6">
                        <a :href="$t('widget.quickLinks.team.link')" target="_blank" class="quick-links__box">
                            <div class="quick-links__content">
                                <img :src="IconTeam" class="quick-links__image" alt="">
                                <span class="quick-links__title">{{ $t('widget.quickLinks.team.label') }}</span>
                            </div>
                        </a>
                    </div>
                    <div class="cell small-12 medium-6 large-8 xlarge-6">
                        <a :href="$t('widget.quickLinks.prices.link')" target="_blank" class="quick-links__box">
                            <div class="quick-links__content">
                                <img :src="IconPrices" class="quick-links__image" alt="">
                                <span class="quick-links__title">{{ $t('widget.quickLinks.prices.label') }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconOrder from '@/assets/dashboard-order.svg';
import IconOffer from '@/assets/dashboard-offer.svg';
import IconProfile from '@/assets/dashboard-profile.svg';
import IconFaq from '@/assets/dashboard-faq.svg';
import IconBlog from '@/assets/dashboard-blog.svg';
import IconTeam from '@/assets/dashboard-team.svg';
import IconPrices from '@/assets/dashboard-prices.svg';

export default {
    name: 'QuickLinks',

    data: () => ({
        IconOrder,
        IconOffer,
        IconProfile,
        IconFaq,
        IconBlog,
        IconTeam,
        IconPrices,
    }),
};
</script>
