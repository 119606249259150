import * as moment from 'moment';

function pad(value, length) {
    return (value.toString().length < length) ? pad(`0${value}`, length) : value;
}

export function dateFormat(input) {
    let date = input;

    if (!(date instanceof Date)) {
        date = moment.default(date).toDate();
    }

    const { format } = new Intl.DateTimeFormat('de-CH', {
        timeZone: 'Europe/Zurich',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });

    return format(date);
}

export function dateTimeFormat(input) {
    let date = input;

    if (!(date instanceof Date)) {
        date = moment.default(date).toDate();
    }

    const { format } = new Intl.DateTimeFormat('de-CH', {
        timeZone: 'Europe/Zurich',
        hour: '2-digit',
        minute: '2-digit',
    });

    return format(date);
}

export function shortFormat(input) {
    const { format } = new Intl.DateTimeFormat('de-CH', {
        timeZone: 'Europe/Zurich',
        month: '2-digit',
        day: '2-digit',
    });

    return format(input);
}

export function longFormat(input) {
    const { format } = new Intl.DateTimeFormat('de-CH', {
        timeZone: 'Europe/Zurich',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });

    return format(input);
}

export function apiFormat(input) {
    const date = moment.default(input).toDate();

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${pad(month, 2)}-${pad(day, 2)}`;
}

export function apiTimeFormat(input) {
    const fields = input.split(/(\d{2})(\d{2})/).filter((field) => field !== '');

    return `${fields.join(':')}:00`;
}

export function timeFormat(input) {
    const fields = input.split(/(\d{2})(\d{2})/).filter((field) => field !== '');

    return fields.join(':');
}
