import { extend, localize, setInteractionMode } from 'vee-validate';
import {
    required, required_if as requiredIf, regex, email, min_value as minValue, max_value as maxValue, confirmed,
} from 'vee-validate/dist/rules';
import $ from 'jquery';
import * as moment from 'moment-timezone';

import { locale, messages } from '@/config/i18n';
import axios from '@/config/axios';
import { validatePickupTime } from '@/services/pickup-time-validator';

setInteractionMode('lazy');

const dictionary = {};
const languages = Object.keys(messages);

languages.forEach((language) => {
    dictionary[language] = {
        messages: messages[language].validation,
    };
});

localize(dictionary);
localize(locale);

// Configuration
extend('required', {
    ...required,
});

extend('required_if', {
    ...requiredIf,
});

extend('regex', {
    ...regex,
});

extend('email', {
    ...email,
});

extend('min_value', {
    ...minValue,
});

extend('max_value', {
    ...maxValue,
});

extend('confirmed', {
    ...confirmed,
});

// Custom Validators
extend('validPickupTime', {
    params: ['target'],
    validate(value, { target }) {
        const dateVal = $(`:input[name="${target}"]`);

        if (!dateVal.length) {
            return true;
        }

        const date = moment.default(dateVal.val(), 'D.M.YYYY');
        const pickup = moment.default(`${date.get('year')} ${date.get('month') + 1} ${date.get('date')} ${value}`, 'YYYY M D HHmm');

        return validatePickupTime(pickup);
    },

    message: 'validation.time',
});

extend('uniqueUsername', {
    validate(value) {
        return new Promise((resolve) => {
            axios({
                url: '/api/v2/validation/unique-username',
                method: 'POST',
                data: {
                    username: value,
                },
            }).then((response) => {
                const { data } = response;
                const { valid } = data;

                resolve({
                    valid,
                });
            });
        });
    },

    message() {
        return dictionary[locale].messages.uniqueUsername;
    },
});

extend('uniqueEmail', {
    validate(value) {
        return new Promise((resolve) => {
            axios({
                url: '/api/v2/validation/unique-email',
                method: 'POST',
                data: {
                    email: value,
                },
            }).then((response) => {
                const { data } = response;
                const { valid } = data;

                resolve({
                    valid,
                });
            });
        });
    },

    message() {
        return dictionary[locale].messages.uniqueEmail;
    },
});
