import { transform as addressTransform } from '@/services/address-transformer';
import { getSupportedLocale } from '@/config/i18n';

export function transform(user) {
    const {
        id,
        username,
        email,
    } = user;

    return {
        // User
        id,
        username,
        email,

        // Profile
        gender: user.anrede,
        company: user.firma,
        contact: user.kontakt,
        firstname: user.vorname,
        lastname: user.nachname,
        street: user.addresse,
        zip: user.postleitzahl,
        location: user.ort,
        newsletter: user.newsletter,
        phone: user.phone,

        // Settings
        invoiceType: ['single', 'monthly'].indexOf(user.invoice_type) > -1 ? user.invoice_type : 'monthly',
        invoiceDispatchType: ['email', 'post'].indexOf(user.invoice_dispatch_type) > -1 ? user.invoice_dispatch_type : 'email',
        invoiceEmail: user.invoice_email ? user.invoice_email : email,
        invoiceLanguage: getSupportedLocale(user.invoice_language),

        // Addresses
        defaultSenderAddress: user.default_absender ? addressTransform(user.default_absender) : null,
        defaultReceiverAddress: user.default_empfaenger ? addressTransform(user.default_empfaenger) : null,
        defaultInvoiceAddress: user.default_rechnungsadresse ? addressTransform(user.default_rechnungsadresse) : null,

        // Permissions
        canSwitchUser: user.roles.indexOf('ROLE_ALLOWED_TO_SWITCH') > -1,
        shouldChangePassword: user.should_change_password,
        organization: user.organization,
    };
}

export function reverseTransform(form) {
    return {
        id: form.id,
        username: form.username,
        email: form.email,

        // Profile
        anrede: form.gender,
        firma: form.company,
        kontakt: form.contact,
        vorname: form.firstname,
        nachname: form.lastname,
        addresse: form.street,
        postleitzahl: form.zip,
        ort: form.location,
        newsletter: form.newsletter,
        phone: form.phone,

        invoice_language: form.invoiceLanguage,
    };
}
